export const FilterTypes = Object.freeze({
    DROPDOWN_SINGLE: 'dropdownSingle',
    DROPDOWN_MULTI: 'dropdownMulti',
    INPUT: 'input',
    COLOR_INPUT: 'colorInput',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    DATERANGE_PICKER: 'daterangePicker',
    NUMERIC_RANGE_PICKER: 'numericRangePicker'
})

