import { useState } from "react";

export const DropdownSingleSelectNative = ({
  className,
  style,
  label,
  placeholder,
  options,
  displayOptions,
  selectedValue,
  setSelectedValue,
  onChange,
  disposeAfterSelection
}) => {
  if (!displayOptions) displayOptions = options;
  const [internalValue, setInternalValue] = useState(selectedValue);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValue(value);
    if (onChange) onChange(event);

    if (disposeAfterSelection)
      setInternalValue("");

  };

  return (
    <div className={`w-full ${className}`} style={style}>
      <label for="a">{label}</label>

      <select className="cursor-pointer w-full border-none bg-transparent px-2"
        onChange={handleChange}
        value={disposeAfterSelection ? internalValue : selectedValue}
      >
        {placeholder && (
          <option value="" selected hidden>
            {placeholder}
          </option>
        )}
        {selectedValue && (
          <option value={selectedValue} disabled hidden>
            {selectedValue}
          </option>
        )}
        {options.map((_, index) => {
          return (
            <option value={options[index]}
            > {displayOptions[index]}
            </option>
          );
        })}
      </select>
    </div>
  );
};
