import styled from "styled-components";
import { Icon } from "./Icon";
import { LinearProgressWithLabel } from "./ProgressBar";
import { Box } from "@mui/system";
import { ButtonNew } from "./ButtonNew";
import { useTranslation } from "react-i18next";
export function Dropzone({
  className,
  uploadState,
  selectedFileName,
  progress,
  open,
  getRootProps,
  getInputProps,
  cancelUpload,
  upload,
}) {
  const { t } = useTranslation();
  return (
    <Wrapper className={className} {...getRootProps({ className: "dropzone" })}>
      <div className="icon-and-button">
        <input {...getInputProps()} id="input" />
        {uploadState === "FileNotSelected" && (
          <>
            <Icon iconName="Upload" size="md" />
            <ButtonNew
              className="browse-btn capitalize"
              variant="primary"
              onClick={open}
              size="md"
            >
              {t("general.browse")}
            </ButtonNew>
          </>
        )}
        {uploadState === "FileSelected" && (
          <>
            <p className="t-body-l text-gray-800">{selectedFileName}</p>
            <ButtonNew
              className="upload-btn capitalize"
              variant="primary"
              onClick={upload}
              size="md"
            >
              {t("general.upload")}
            </ButtonNew>
          </>
        )}

        {uploadState === "UploadStarted" && (
          <>
            <Box sx={{ width: "80%" }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
            <p className="upload-status t-body-xs text-gray-800 sentence-case">
              {t("general.uploadingFile")} ... {`${Math.round(progress)}%`}
            </p>
            <ButtonNew
              className="cancel-btn capitalize"
              variant="secondary"
              onClick={cancelUpload}
              size="md"
            >
              {t("general.cancel")}
            </ButtonNew>
          </>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled("div")({
  borderRadius: "4px",
  border: "1px dashed var(--clr-gray-200)",

  ".icon-and-button": {
    marginTop: "8.4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  ".cancel-btn, .browse-btn, .upload-btn": {
    marginTop: "1.6rem",
  },
  ".upload-status": {
    marginTop: "0.8rem",
  },
});
