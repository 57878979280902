const loginTexts = {
  title: "Digital Twin-Plattform",
  subtitle: "Für die Dekarbonisierung Ihres Gebäudebestands.",
  logoText: "powered by",
  welcome: "willkommen",
  login: "login",
  form: {
    username: {
      placeholder: "Nutzername",
    },
    password: {
      placeholder: "Passwort",
    },
    forgotText: "Passwort vergessen?",
    consent: "Ich stimme den ",
    toTermsAndConditions: "allgemeinen Geschäftsbedingungen zu",
    noAccount: "Sie haben noch kein Konto?",
    help: "Kontaktieren Sie uns hier.",
    signInText: "Anmelden",
    fieldsMustBeFilled: "Alle Felder müssen ausgefüllt sein",
  },
  resetPassword: {
    title: "reset your password",
    text:
      "don't worry, we'll email you the instructions to reset your password shortly.",
    placeholder: "insert your email",
    label: "email",
    cancel: "back to login",
    submit: "reset password",
    successTitle: "we've sent you an email",
    successText: "check your email to reset your password",
    successfulButtonText: "okay",
    errorTitle: "sorry!",
    errorText: "We could not reset your password, please write us an email.",
    errorButtonText: "okay",
  },
};
export default loginTexts;
