export const sortFunction = (a, b) => {
  if ((typeof a === "number") & (typeof b === "number")) {
    return a - b;
  } else {
    if (a < b) return -1;
    else if (a > b) return 1;
    else return 0;
  }
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
export const toDateString = (date) => {
  return new Date(date)
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replaceAll("/", ".");
};
export const toDate = (dateString) => {
  const year = dateString.substr(6, 4);
  const month = dateString.substr(3, 2);
  const day = dateString.substr(0, 2);
  const wellFormatDateString = `${year}-${month}-${day}`;
  const date = new Date(wellFormatDateString);
  return date;
};

export const toLeaftechDateString = (dateString) => {
  // Split the input string into two date strings
  const [startDate, endDate] = dateString.split(" - ");
  let formattedStartDate;
  let formattedEndDate;
  // Convert each date string to the desired format "DD.MM.YY"
  if (startDate) {
    const [startMonth, startDay, startYear] = startDate.split("/");
    formattedStartDate = `${startDay}.${startMonth}.${startYear.slice(2)}`;
  } else {
    formattedStartDate = "";
  }
  if (endDate) {
    const [endMonth, endDay, endYear] = endDate.split("/");
    formattedEndDate = `${endDay}.${endMonth}.${endYear.slice(2)}`;
  } else {
    formattedEndDate = "";
  }

  // Concatenate the two formatted date strings using the hyphen separator
  const formattedDateString = `${formattedStartDate} - ${formattedEndDate}`;
  return formattedDateString;
};
