import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import allBuildingsReducer from "./features/allBuildings/allBuildingsSlice";
import routeNameReducer from "./features/routeName/routeNameSlice";
import techDataReducer from "./features/techData/techDataSlice";
import energyPerformanceReducer from "./features/energyPerformance/energyPerformanceSlice";
import energyPerformanceReducerNew from "./features/energyPerformance/energyPerformanceSliceNew";
import dynamicDashboardReducer from './features/dynamicDashboard/dynamicDashboardSlice'
import { combineReducers } from "redux";

const combinedReducer = combineReducers({
  user: userReducer,
  allBuildings: allBuildingsReducer,
  routeName: routeNameReducer,
  techData: techDataReducer,
  energyPerformance: energyPerformanceReducer,
  energyPerformanceNew: energyPerformanceReducerNew,
  dynamicDashboard: dynamicDashboardReducer
});

const rootReducer = (state, action) => {
  if (action.type === "unauthorizedLogout") {
    return { user: { user: undefined } };
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});
