import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";

export const DateRangePickerNew = (props) => {
  let { minDate, maxDate, className } = props;
  minDate = new Date(minDate);
  maxDate = new Date(maxDate);

  const predefinedRanges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
      placement: "left",
      disabled: new Date() < minDate || new Date() > maxDate, //To be consistent with other items.
    },
    {
      label: "Yesterday",
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: "left",
      disabled:
        addDays(new Date(), -1) < minDate || addDays(new Date(), -1) > maxDate, //To be consistent with other items.
    },
    {
      label: "This week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
      disabled:
        endOfWeek(new Date()) < minDate || startOfWeek(new Date()) > maxDate,
    },
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
      disabled: new Date() < minDate || subDays(new Date(), 6) > maxDate,
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
      disabled: new Date() < minDate || subDays(new Date(), 29) > maxDate,
    },
    {
      label: "This month",
      value: [startOfMonth(new Date()), new Date()],
      placement: "left",
      disabled: new Date() < minDate || startOfMonth(new Date()) > maxDate,
    },
    {
      label: "Last month",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
      placement: "left",
      disabled:
        endOfMonth(addMonths(new Date(), -1)) < minDate ||
        startOfMonth(addMonths(new Date(), -1)) > maxDate,
    },
    {
      label: "This year",
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: "left",
      disabled:
        new Date() < minDate ||
        new Date(new Date().getFullYear(), 0, 1) > maxDate,
    },
    {
      label: "Last year",
      value: [
        new Date(new Date().getFullYear() - 1, 0, 1),
        new Date(new Date().getFullYear(), 0, 0),
      ],
      placement: "left",
      disabled:
        new Date(new Date().getFullYear(), 0, 0) < minDate ||
        new Date(new Date().getFullYear() - 1, 0, 1) > maxDate,
    },
    {
      label: "All time",
      value: [
        new Date(minDate || "2000-01-01"),
        new Date(maxDate || new Date()),
      ],
      placement: "left",
    },
  ];

  return (
    <DateRangePicker
      editable={true}
      className={className}
      ranges={predefinedRanges}
      {...props}
    />
  );
};
