import { Paper, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IsAllowed } from "../../acl";
import apiUrls from "../../api/urls";
import { getAletrnativeImage } from "../../utils/buildingImages";
import {
  formatNumberBasedOnUser,
  valueOrAlternative
} from "../../utils/namesManipulation";
import CustomizedDialogs from "../Dialog";
import { IconButton } from "../IconButton";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const AssetDetailedCard = ({
  building,
  config,
  className,
  imageClassName,
  upload = true,
  imgPath,
  name,
  buildingType,
  isLoading,
  onClick
}) => {
  const { t } = useTranslation();
  const [imagePath, setImagePath] = useState(imgPath);
  if (!building) building = {};
  const { user } = useSelector((store) => store.user);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const token = user?.access_token;
  const url = apiUrls.buildingImage.post;
  const queryParams = [{ name: "project", value: building?.id }];
  const onSuccess = (resp) => {
    setHasError(false);
    setErrorMessage("");
    setImagePath(resp.image_file);
  };
  const onError = (xhr) => {
    setHasError(true);
    setErrorMessage(xhr.statusText);
  };

  useEffect(() => setImagePath(imgPath), [imgPath]);

  if (!config?.length) return

  const [leftCol, rightCol] = config

  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="100%">
      <Paper className={className} onClick={onClick}>
        <div className="relative">
          {/*  h-full flex justify-center */}
          {upload && IsAllowed("BUILDING_PROFILE.UPLOAD_IMAGE") && (
            <CustomizedDialogs
              Component={
                <IconButton
                  iconProps={{
                    iconName: "Camera",
                    color: "white",
                  }}
                  buttonProps={{
                    className: "absolute right-3 bottom-3 z-10",
                    size: "sm",
                    variant: "primary",
                  }}
                ></IconButton>
              }
              token={token}
              url={url}
              formDataFileKeyName="image_file"
              onSuccess={onSuccess}
              onError={onError}
              queryParams={queryParams}
              errorMessage={errorMessage}
              successMessage={t("general.successfulImgaeUpload")}
              headerTitle={t("general.uploadImage")}
              headerText={t("general.dragToUpload")}
              hasError={hasError}
            />
          )}
          <img
            src={imagePath}
            className={`max-h-[420px] min-h-[180px] w-full rounded drop-shadow object-contain object-center ${imageClassName}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = getAletrnativeImage(buildingType);
            }}
          />
        </div>

        <h2 className="mt-8 sentence-case t-heading-l ">{name}</h2>
        <Stack className="justify-between items-start mt-6">
          {[leftCol, rightCol].map(col => {
            return (
              <ul className="w-[47%]">
                {col?.map((mapping, index) => {
                  return (
                    <li
                      key={index}
                      className="mb-4 pb-2 border-solid border-0 border-b border-gray-100"
                    >
                      <h3 className="mb-1 uppercase t-label-m text-gray-400 ">
                        {t(`BuildingProfile.card.${mapping.label}`)}
                      </h3>
                      <p className="t-body-xl sentence-case">
                        {mapping.isNumeric
                          ? formatNumberBasedOnUser(
                            valueOrAlternative(building[mapping.dataKey])
                          )
                          : valueOrAlternative(building[mapping.dataKey])}
                        {mapping.renderUnit && (
                          <span>{mapping.renderUnit(building[mapping.dataKey])}</span>
                        )}
                      </p>
                    </li>
                  );
                })}
              </ul>

            )
          })}
        </Stack>
      </Paper>
    </LoadingOrEmptyWrapper>
  );
};



