import React from "react";
import { calculateLeft } from "../../utils/position";
import { Icon } from "../Icon";

export const BulletChartZones = ({ zones, axis, className }) => {
  if (zones && zones.breakPoint) {
    const min = axis[0];
    const max = axis[axis.length - 1];

    return (
      <div
        className={className}
        style={{
          left: `${calculateLeft(zones.breakPoint, min, max)}%`,
          position: "absolute",
          transform: `translateX(-${50}%)`,
          display: "flex",
          gap: "19px",
        }}
      >
        <Icon
          iconName="ArrowThinLeft"
          size="md"
          color="var(--clr-mystic-red-500)"
        ></Icon>
        <Icon
          size="md"
          iconName="ArrowThinRight"
          color="var(--clr-vivid-orchid-500)"
        ></Icon>
      </div>
    );
  }
};
