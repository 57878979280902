export default {
  card: {
    title: "Gebäude Informationen",
    "ASSET NAME": "Name des Gebäudes",
    TYPE: "Kategorie",
    "ASSET ID": "Gebäude ID",
    ADDRESS: "Adresse",
    "YEAR OF CONSTRUCTION": "Baujahr",
    "MODERNIZATION YEAR": "Modernisierung",
    "GROSS ASSET SIZE": "Bruttogrundfläche",
    "GROSS ASSET VALUE": "Geschätzter Immobilienwert",
  },
  table: {
    title: "Gebäudedokumente",
    downloadAllFiles: "Alle Dateien herunterladen",
    columns: {
      name: "Name",
      type: "Typ",
      "date modified": "Datum geändert",
      files: "Datei",
    },
  },
  kpis: {
    title: "KPIs",
    totalEnergyConsumption: "Energieverbrauch",
    totalCarbonEmission: "Gesamter CO₂-Ausstoß",
    kpisNotListed: "KPIs sind nicht aufgeführt.",
  },
  certificateCard: {
    certificateTitle: "Zertifikate",
    energyRatingTitle: "EPC Bewertungszertifikate",
    "Allotment date": "Zuteilungsdatum:",
    Area: "Fläche:",
    "Valid until": "Gültig bis",
  },
};
