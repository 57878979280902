import user from "./user";
import assets from "./assets";
import certificates from "./certificates";
import kpis from "./kpis";
import buildingImage from "./buildingImage";
import files from "./files";
import layerFiles from "./layerFiles";
import threeDViewer from "./threeDViewer";
import userFiles from "./userFiles";
import energyPerformance from "./energyPerformance";
import performanceIndicator from "./performanceIndicator";
import energyMix from "./energyMix";
import modernization from "./modernization";
import translation from "./translation";
import technicalData from "./technicalData";
import buildingUsage from "./buildingUsage";
import energyPerformanceNew from "./energyPerformanceNew";

export default {
  user,
  assets,
  certificates,
  kpis,
  buildingImage,
  files,
  layerFiles,
  threeDViewer,
  userFiles,
  energyPerformance,
  energyPerformanceNew,
  performanceIndicator,
  energyMix,
  modernization,
  translation,
  technicalData,
  buildingUsage,
};
