import { exportComponentAsJPEG } from "react-component-export-image";
import { useTranslation } from "react-i18next";
import "rsuite/dist/rsuite-no-reset.min.css";
import { IsAllowed } from "../../acl";
import { ComponentsCommonHeader } from "../ComponentsCommonHeader";

export const DashboardHeader = ({
  componentRef,
  isDownloadDisabled,
  displayLabel,
}) => {
  const canDownload = IsAllowed("ENERGY_PERFORMANCE.DOWNLOAD_FILES");
  const { t } = useTranslation();

  return (
    <ComponentsCommonHeader
      className="pt-6 pb-4 px-2"
      action={() => exportComponentAsJPEG(componentRef)}
      actionIsDisabled={isDownloadDisabled}
      title={`${t("EnergyPerformance.dashboard.title")} / ${displayLabel}`}
      hasActionAccess={canDownload}
      actionText={t("EnergyPerformance.dashboard.downloadText")}
    />
  );
};
