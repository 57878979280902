import React from "react";
import { calculateLeft } from "../../utils/position";
import Bullet from "./Bullet";

export const BulletChartColorBar = ({ bullets, axis, className, gradientClassName = 'barchart-bg-gradient', bulletProps }) => {
  const min = axis[0];
  const max = axis[axis.length - 1];

  return (
    <div className={`relative rounded-md ${gradientClassName} ${className}`}>
      {bullets?.map((bullet, index) => {
        const left = calculateLeft(bullet.value, min, max);
        const reverse = bullet.reverse !== undefined ? bullet.reverse :
          index === 0 &&
          (bullets[1]?.value - bullet?.value < 60 ||
            bullets[2]?.value - bullet?.value < 60);
        if (left)
          return (
            <Bullet
              key={`bullet ${index}`}
              className="absolute"
              left={`${left}%`}
              label={bullet.label}
              width="1.6rem"
              height="1.6rem"
              handleHeight={bullet?.handleHeight}
              bottom="0px"
              top="0px"
              type="circle"
              color={bullet.color}
              reverse={reverse}
              transform={`translateX(-50%)`}
              {...bulletProps}
            />
          );
      })}
    </div>
  );
};
