export default {
  buildingPassport: "GebäudePass",
  energyConsultant: "Energieberater",
  "building profile": "Gebäudeprofil",
  "technical Data": "Technische Daten",
  "Energy & CO₂": "Energie & CO₂ ",
  certificates: "Zertifikate",
  modernization: "Modernisierungsszenarien",
  downloadAllFiles: "Alle Dateien herunterladen",
  threeDViewer: "3D viewer",
  uploadFiles: "Dateien hochladen",
  downloadScenarios: "Download scenarios",
};
