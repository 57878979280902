import React from "react";

const Bullet = ({
  left,
  label,
  width,
  height,
  handleHeight = "14px",
  transform,
  bottom,
  top,
  type,
  color,
  textColor,
  reverse,
  style
}) => {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 99,
        left: left,
        bottom: reverse ? null : bottom,
        top: reverse ? top : null,
        display: "flex",
        flexDirection: reverse ? "column-reverse" : "column",
        alignItems: "center",
        transform: transform,
        textTransform: "capitalize",
        ...style
      }}
    >
      <div
        className="t-numbers-xxs"
        style={{
          backgroundColor: color,
          border: "1px solid var(--clr-secondary-blue-200)",
          padding: "4px 8px 4px 8px",
          color: textColor || "white",
          borderRadius: "80px",
          boxShadow: "var(--box-shadow-elevation-200)",
          width: 'max-content',
        }}
      >
        {label}
      </div>
      <div
        style={{
          width: "1px",
          height: handleHeight,
          backgroundColor: "var(--clr-secondary-blue-100)",
        }}
      ></div>
      {type &&
        <div
          style={{
            backgroundColor: color,
            width: width,
            height: height,
            borderRadius: type === "circle" ? "50%" : "0",
            border: "2px solid var(--clr-secondary-blue-100)",
          }}
        ></div>
      }
    </div>
  );
};

export default Bullet;
