import { Stack } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrPowerReset } from "react-icons/gr";
import "rsuite/dist/rsuite-no-reset.min.css";

import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { TfiSave } from "react-icons/tfi";
import "react-resizable/css/styles.css";
import "../../styles/dashboard.css";
import { ButtonNew } from "../ButtonNew";
import { DynamicChart, DynamicChartFullScreenHandler } from "../chart/DynamicChart";
import { DropdownSingleSelect } from "../DropdownSingleSelect";
import { setSingleConfig } from "../../features/dynamicDashboard/dynamicDashboardSlice";
import { useDispatch } from "react-redux";
// import { GeneralErrorBoundary } from "../ErrorBoundary";

const ResponsiveGridLayout = WidthProvider(Responsive);


export const removeZeroFields = (data) => {
    if (!data?.length) return;
    let keys = Object.keys(data[0]);
    for (let key of keys) {
        let allZero = data.every((item) => item[key] === 0);
        if (allZero) {
            for (let obj of data) {
                delete obj[key];
            }
        }
    }
    return data;
};


export const DynamicDashboard = ({
    dynamicChartsRowHeight,
    allChartsConfigs,
    setAllChartsConfigs,
    generalDashboardData,
    allFilters,
    allSettings,
    chartTypes,
    specificDataGetter,
    dataTransformator,
    getDataTransformatorMemoDependencyArray,
    eventHandlers,
    removeChart,
    updateChart,
    scrollRef,
    getUnit,
    getColor,
    onLegendSelect,
    onHover,
    onMouseOut,
    isItemHovered,
    somethingIsHovered,
    getColumnDisplayName,
    getColumnSortFunction,
    hoverEffect
}) => {

    // const dispatch = useDispatch()
    // useEffect(() => {
    //     allChartsConfigs?.forEach(config => {
    //         dispatch(setSingleConfig({ id: config.id, appName: 'Portfolio', config }))
    //     });
    // }, [allChartsConfigs])

    const layout = useMemo(
        () =>
            allChartsConfigs?.map((chart) => ({
                ...chart,
                i: `${chart.id}`,
            })),
        [allChartsConfigs]
    );
    const handleLayoutChange = (newLayout) => {

        setAllChartsConfigs(allChartsConfigs.map((chart) => {
            const newChart = newLayout.find(
                (newLayoutItem) => newLayoutItem.i === `${chart.id}`
            );
            if (!newChart) return chart;
            else {
                const { x, y, w, h } = newChart;
                return { ...chart, x, y, w, h };
            }
        })
        );
    };
    const fullScreenChart = (id) => {
        setFullScreenChartId(id)
    }
    const [fullScreenChartId, setFullScreenChartId] = useState(null)

    return (
        <>
            <ResponsiveGridLayout
                draggableCancel=".cancelDrag"
                layouts={{ lg: layout }}
                onLayoutChange={handleLayoutChange}
                breakpoints={{ lg: 280, md: 0, sm: 0, xs: 0, xxs: 0 }}
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                rowHeight={dynamicChartsRowHeight}
                resizeHandles={["sw", "se"]}
                className="rounded-xl bg-gray-50"
            >
                {allChartsConfigs.map((item, i) => {
                    const isFullScreen = fullScreenChartId === item.id
                    return (
                        <div key={item.id} className='grid-item'>
                            <DynamicChartFullScreenHandler isFullScreen={isFullScreen}>
                                <DynamicChart
                                    key={item.id}
                                    id={item.id}
                                    generalData={generalDashboardData}
                                    height={dynamicChartsRowHeight * item.h}
                                    updateChart={(key, value) => updateChart(item.id, key, value)}
                                    removeChart={() => removeChart(item.id)}
                                    xCoord={item.x}
                                    isFullScreen={isFullScreen}
                                    config={item}
                                    fullScreenChart={fullScreenChart}
                                    allFilters={allFilters}
                                    allSettings={allSettings}
                                    chartTypes={chartTypes}
                                    specificDataGetter={specificDataGetter}
                                    eventHandlers={eventHandlers}
                                    dataTransformator={dataTransformator}
                                    getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
                                    getUnit={getUnit}
                                    getColor={getColor}
                                    onLegendSelect={onLegendSelect}
                                    onHover={onHover}
                                    onMouseOut={onMouseOut}
                                    isItemHovered={isItemHovered}
                                    somethingIsHovered={somethingIsHovered}
                                    getColumnDisplayName={getColumnDisplayName}
                                    getColumnSortFunction={getColumnSortFunction}
                                    hoverEffect={hoverEffect}

                                />
                            </DynamicChartFullScreenHandler>
                        </div>
                    );
                })}
            </ResponsiveGridLayout>
            <span ref={scrollRef} />
        </>
    );
};
