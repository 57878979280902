import React from "react";
import styled from "styled-components";

const Chip = ({ children, isOn, onClick, width, className }) => {
  return (
    <Wrapper
      tabIndex={0}
      isOn={isOn}
      width={width}
      onClick={onClick}
      className={`t-body-l ${className}`}
    >
      {children}
    </Wrapper>
  );
};

export default Chip;
const Wrapper = styled("div")(({ isOn, width }) => ({
  padding: "0.65rem 1.2rem",
  borderRadius: "4px",
  height: "3.2rem",
  width: width,
  cursor: "pointer",
  // transition: "all 0.003s ease-out",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: `${isOn ? "var(--clr-secondary-blue-500)" : "var(--clr-gray-900)"}`,
  background: `${isOn ? "var(--clr-secondary-blue-50)" : ""}`,
  border: `1px solid ${
    isOn ? "var(--clr-secondary-blue-500)" : "var(--clr-gray-700)"
  }`,
  // ":focus": {
  //   outline: "none",
  //   color: "var(--clr-gray-900)",
  //   background: "var(--clr-white)",
  //   border: `${isOn ? 2 : 1}px solid var(--clr-secondary-blue-200)`,
  // },
  ":active": {
    color: `${
      isOn ? "var(--clr-secondary-blue-700)" : "var(--clr-secondary-blue-500)"
    }`,
    background: `${
      isOn ? "var(--clr-secondary-blue-100)" : "var(--clr-secondary-blue-50)"
    }`,
    border: `1px solid ${
      isOn ? "var(--clr-secondary-blue-700)" : "var(--clr-secondary-blue-300)"
    }`,
  },
}));
