import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { English } from "../components/PrivacyPolicy/English";
import { German } from "../components/PrivacyPolicy/German";

export const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  if (i18n.language === "de") return <German />;
  else return <English />;
};
