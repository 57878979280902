import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useGetTechData } from "../api/hooks/allHooks";
import { Loading } from "../components/Loading";
import { NavMenu } from "../components/TechnicalData/NavMenu";
import { TechTable } from "../components/TechnicalData/TechTable";
import { Helmet } from "react-helmet-async";
import { tabNames } from "../configs/TechnicalData";
import {
  extractTabData,
  getDefaultActiveTab,
  getEnabledTabs,
} from "../functions/TechnicalData";
import { LoadingOrEmptyComponent } from "../components/LoadingAndEmptyHandler";
import { eventNames, trackGAEvent, withGAEventTracking } from "../functions/googleAnalytics";

export const TechnicalData = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState("windows");

  const { data, isLoading } = useGetTechData({ tabNames, projectId });
  const enabledTabs = getEnabledTabs(data);
  const tabData = extractTabData({ data, activeTab });

  useEffect(() => {
    setActiveTab(getDefaultActiveTab(enabledTabs));
  }, [isLoading]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ["techDataAllParents", activeTab, projectId],
    });
  }, [activeTab]);

  useEffect(() => {
    trackGAEvent(eventNames.TD_VIEW)
  }, []);

  return (
    <>
      <Helmet>
        <title>Technical data | Leaftech</title>
      </Helmet>
      <Stack className="items-start mt-4" spacing={4}>
        <NavMenu
          enabledTabs={enabledTabs}
          activeTab={activeTab}
          setActiveTab={(tabName) => withGAEventTracking(eventNames.TD_TAB_VIEW, { tabName },
            () => setActiveTab(tabName)
          )}
          className="w-60 p-0 pt-2"
        />
        <TechTable
          data={tabData}
          projectId={projectId}
          enabledTabs={enabledTabs}
          activeTab={activeTab || "windows"}
          parentsAreLoading={isLoading}
        ></TechTable>
      </Stack>
    </>
  );
};
