const loginTexts = {
  title: "Digital Twin platform",
  subtitle: "For the decarbonization of your building stock.",
  logoText: "powered by",
  welcome: "welcome",
  login: "login",
  form: {
    username: {
      placeholder: "username",
    },
    password: {
      placeholder: "password",
    },
    forgotText: "Forgot password?",
    consent: "I agree to ",
    toTermsAndConditions: "terms & conditions",
    noAccount: "Don't have an account?",
    help: "Reach out to us here.",
    signInText: "Sign in",
    fieldsMustBeFilled: "All fields must be filled",
  },
  resetPassword: {
    title: "reset your password",
    text:
      "don't worry, we'll email you the instructions to reset your password shortly.",
    placeholder: "insert your email",
    label: "email",
    cancel: "back to login",
    submit: "reset password",
    successTitle: "we've sent you an email",
    successText: "check your email to reset your password",
    successfulButtonText: "okay",
    errorTitle: "sorry!",
    errorText: "We could not reset your password, please write us an email.",
    errorButtonText: "okay",
  },
};
export default loginTexts;
