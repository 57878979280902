import { Grid, Paper } from "@mui/material";
import { Loading } from "../Loading";
import { DataCard, EmptyDataCard } from "../DataCard";
import { useTranslation } from "react-i18next";
import {
  LoadingOrEmptyComponent,
  LoadingOrEmptyWrapper,
} from "../LoadingAndEmptyHandler";
import { EnergyEfficiencyClass } from "../EnergyEfficiencyClass";
import { isNumber } from "lodash";

const EmptyState = ({ className }) => {
  return (
    <div className={`h-22 w-1/3 mt-4 ${className}`}>
      <EmptyDataCard></EmptyDataCard>
    </div>
  );
};
export const KPIs = ({
  className,
  kpis,
  energyConsumptionPerM2,
  isLoading,
  xs = 12,
  xl2 = 6,
  title,
  paperClassName,
  kpisLabelsAndKeysMapping
}) => {
  const { t } = useTranslation()
  const noData = !(kpis?.length > 0) && !(isNumber(energyConsumptionPerM2));
  return (
    <article className={`relative min-h-[16rem] ${className}`}>

      <Paper className={`bg-transparent p-0 border-[1.6rem] border-white border-solid ${paperClassName}`}>

        {title && <h3 className="t-heading-l bg-white pb-2">{title}</h3>}
        <LoadingOrEmptyComponent
          showLoading={isLoading}
          showEmpty={noData}
          emptyComponent={() => <EmptyState className={'ml-3'} />}
          height="11rem"
        ></LoadingOrEmptyComponent>
        <Grid container columnSpacing={4} rowSpacing={4} className="p-3">
          {kpis?.length > 0 &&
            kpisLabelsAndKeysMapping?.map((mapping, index) => {
              if (kpis[0][mapping.dataKey] !== undefined) {
                const kpi = {
                  name: mapping.label,
                  value: !mapping.round ? kpis[0][mapping.dataKey] : parseFloat(kpis[0][mapping.dataKey])?.toFixed(2),
                  unit: mapping.unit,
                };
                return (
                  <Grid item key={index} xs={xs} xl2={xl2}>
                    <DataCard kpi={kpi} />
                  </Grid>
                );
              }
            })}
          <Grid item key={kpisLabelsAndKeysMapping?.length + 1} xs={xs} xl2={xl2}>
            <EnergyEfficiencyClass label={t("BuildingProfile.kpis.energyEfficiencyClass")} energyConsumption={energyConsumptionPerM2} className={'min-h-[10rem]'} />
          </Grid>
        </Grid>
      </Paper>
    </article>
  );
};
