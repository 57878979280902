export const getFlattenData = ({ data, columnsToPivot, valueName, newColumnName }) => {
    const flattenData = [];
    for (const row of data) {
        const rowCopy = { ...row };
        for (const col of columnsToPivot) delete rowCopy[col];
        for (const key of Object.keys(row)) {
            if (columnsToPivot?.includes(key)) {
                const flatItem = {
                    ...rowCopy,
                    [valueName]: row[key],
                    [newColumnName]: key,
                };
                flattenData.push(flatItem);
            }
        }
    }
    return flattenData;
};

