export default {
  noBuilding: "Kein Gebäude kann angezeigt werden.",

  title: "Ihre Liegenschaften",
  filters: {
    "energy class": "Energieklasse",
    location: "Standort",
    type: "Kategorie",
    certification: "Zertifizierung",
    "construction year": "Baujahr",
    search: "Suche",
    buildings: "Immobilien",
    resetFilters: "Filter zurücksetzen",
  },
  assetCard: {
    emission: "EMISSIONEN",
    pending: "In bearbeitung",
    epc: "epc",
  },
};
