const projectMapping = {
    10: 188, 12: 183, 13: 185, 14: 238, 15: 223, 16: 260, 17: 256, 18: 259,
    19: 248, 20: 268, 21: 288, 26: 309, 50: 294, 52: 72, 55: 317, 56: 325,
    57: 327, 78: 79, 84: 79, 85: 44, 86: 355, 8: 171, 9: 169, 3: 168, 29: 382
}

export const getOldProjectId = (newProjectId) => {
    const oldProjectId = Object.keys(projectMapping)
        ?.find(key => Number(projectMapping[key]) === Number(newProjectId))
    return oldProjectId
}