import { formatNumberBasedOnUser } from "../../utils/namesManipulation";

export const ColorBar = ({ min, max, colorMin, colorMax, className }) => {
    return (
        <div className={`flex flex-col items-center fixed right-5 top-1/2 -translate-y-1/2 w-8 h-3/4 ${className}`}>
            <div className="mb-1 t-subheading-m">{formatNumberBasedOnUser(max)}</div>
            <div
                className="w-5 h-full rounded-md"
                style={{
                    background: `linear-gradient(#f00, #ff0, #0f0)`
                }}
            ></div>
            <div className="t-subheading-m mt-1">{formatNumberBasedOnUser(min)}</div>
        </div>
    );
};