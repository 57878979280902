import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { exportComponentAsJPEG } from "react-component-export-image";
import { GiPhotoCamera } from "react-icons/gi";
import { toJpeg } from 'html-to-image';
import * as htmlToImage from 'html-to-image';

const removeNoscriptTags = () => {
    document.querySelectorAll('noscript')?.forEach((noscript) => {
        noscript.style.display = 'none';
    });
};

const restoreNoscriptTags = () => {
    document.querySelectorAll('noscript')?.forEach((noscript) => {
        noscript.style.display = '';
    });
};

export const captureElement = (targetElement, filename = 'screenshot') => {
    removeNoscriptTags()

    html2canvas(targetElement, {
        useCORS: true,
    }).then((canvasSnapshot) => {
        const screenshot = canvasSnapshot.toDataURL('image/png');
        saveAs(screenshot, `${filename}.png`);
    });
    restoreNoscriptTags()
};

export const CaptureComponent1 = ({ targetElement, filename, style, ...props }) => {
    return <GiPhotoCamera style={{
        width: "24px",
        height: "24px",
        cursor: 'pointer',
        ...style
    }} onClick={(e) => {
        exportComponentAsJPEG(targetElement())
    }}
        {...props}
    />

};


export const CaptureComponent = ({ targetElement, filename, style, ...props }) => {

    const captureScreenshot = () => {
        const options = {
            quality: 1,
            backgroundColor: '#ffffff',
            style: { width: 'auto', height: 'auto' },
            cacheBust: false

        };
        // htmlToImage.toPng(targetElement())
        //     .then(function (dataUrl) {
        //         var img = new Image();
        //         img.src = dataUrl;
        //         document.body.appendChild(img);
        //     }).catch(e => {
        //         console.log(e)
        //     })

        // const blob = await htmlToImage.toBlob(targetElement(), { quality: 1, backgroundColor: '#ffffff' });
        // const url = URL.createObjectURL(blob);

        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'component-screenshot.png';
        // link.click();

        // // Release the object URL after download
        // URL.revokeObjectURL(url);
        htmlToImage.toPng(targetElement(), options)
            .then((dataUrl) => {
                console.log('datauel')
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'component-screenshot.jpeg';
                link.click();
            })
            .catch((err) => console.log('Error capturing screenshot:', err));
    };
    return <GiPhotoCamera style={{
        width: "24px",
        height: "24px",
        cursor: 'pointer',
        ...style
    }} onClick={(e) => {
        captureScreenshot()
    }}
        {...props}
    />
}
