import { Stack } from "@mui/material";
import React from "react";
import LegendBullet from "./LegendBullet";
const LegendItem = ({
  className,
  bulletType,
  bulletWidth,
  bulletHeight,
  color,
  label,
}) => {
  return (
    <Stack className={className}>
      <LegendBullet
        width={bulletWidth}
        height={bulletHeight}
        type={bulletType}
        color={color}
      />
      <p className="text t-body-n ml-2">{label}</p>
    </Stack>
  );
};

export default LegendItem;
