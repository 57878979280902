import React, { useState } from "react";
import { calculateLeft } from "../../utils/position";
import Bullet from "./Bullet";
import { Stack } from "@mui/material";
import { sumByKeys } from "../../utils/dataManipulation";

export const VerticalStackedBarChartColorBar = ({
  data,
  config,
  className,
  bottom = '-30px'
}) => {
  const [hoverBulletIndex, setHoverBulletIndex] = useState(null);
  const aggregatedPercentages = sumByKeys({ data, keys: ["percentage"] });
  const totalSum = aggregatedPercentages?.percentage || 1;

  return (
    <Stack
      className={`${className}  [&>*:first-child]:rounded-t-lg [&>*:last-child]:rounded-b-lg`}
      flexDirection={'column'}
    >
      {data?.map((row, index) => {
        return (
          <div
            className="relative w-full"
            key={index}
            onMouseEnter={() => setHoverBulletIndex(index)}
            onMouseLeave={() => setHoverBulletIndex(null)}
            style={{
              backgroundColor: config[row.label]?.color,
              height: `${(row.percentage * 100) / totalSum}%`,
            }}
          >
            {index === hoverBulletIndex && (
              <Bullet
                className="absolute"
                left="50%"
                label={`${parseFloat((row.percentage).toFixed(1))}%`}
                width="2.6rem"
                height="2.6rem"
                bottom={bottom}
                // top="0px"
                type="circle"
                color={config[row.label]?.color}
                textColor={config[row.label]?.textColor}
                reverse={false}
                transform={`translate(-50%, -50%)`}
              />
            )}
          </div>
        );
      })}
    </Stack>
  );
};
