import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { setIsInactivelyLoggedOut } from "../features/user/userSlice";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../api/functions/all";
import ReactGA from "react-ga4";
import { IsAllowed } from "../acl";
import { resources } from "../acl/resources";

const inactivityTimeout = Number(process.env.REACT_APP_INACTIVITY_TIMEOUT);

export const ProtectedRoute = ({ children }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store?.user);

  let lastActive = new Date();
  function resetLastActiveTimeListener() {
    lastActive = new Date();
  }
  window.onmousemove = resetLastActiveTimeListener;
  window.onmousedown = resetLastActiveTimeListener;
  window.onclick = resetLastActiveTimeListener;
  window.onscroll = resetLastActiveTimeListener;
  window.onkeydown = resetLastActiveTimeListener;
  window.onkeyup = resetLastActiveTimeListener;

  useEffect(() => {
    const logoutIfInactive = setInterval(async () => {
      if (new Date() - lastActive > 1000 * inactivityTimeout) {
        if (user) {
          await logoutUser();
          dispatch(setIsInactivelyLoggedOut(true));
        }
      }
    }, 10 * 1000);
    return () => clearInterval(logoutIfInactive);
  }, [lastActive]);

  useEffect(() => {
    if (user) {
      const languageInDB = user?.languages;
      const languageInLocalstorage = localStorage.getItem("language");
      i18n.changeLanguage(languageInLocalstorage || languageInDB || "en");
    }
  }, [user]);

  // if (IsAllowed(resources.GOOGLE_ANALYTICS))
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

  if (!user) return <Navigate to={"/login"} />;
  else {
    return children;
  }
};
