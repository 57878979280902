import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export function LinearProgressWithLabel(props) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box sx={{ width: "148px" }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: "1.2rem",
            border: "1px solid var(--clr-gray-200)",
            boxShadow: "var(--box-shadow-elevation-100)",
            backgroundColor: "var(--clr-white)",
            borderRadius: "80px",
          }}
        />
      </Box>
      {/* <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
}

export default function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 1 : prevProgress + 1
      );
    }, 80);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "40%" }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
