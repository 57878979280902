export default {
  goals: {
    title: "Modernization goals",
    noGoals: "There is no information available!.",
  },
  scenarios: {
    noScenarios: "There is no information available!.",
    title: "Modernization scenarios",
    sectionButton: "Download all scenarios",
    scenario: "scenario",
    financingButton: "Green financing",
    financingModalText:
      "Subsidized green financing can offset a portion of the capital cost.",
    upgrades: "Upgrades",
    costBenefit: {
      title: "Cost-benefit analysis",
      saving: "Enegy cost savings",
      period: "Payback period",
      years: "years",
    },
    impact: {
      sectionTitle: "Impact",
      energyTitle: "Average energy consumption",
      carbonTitle: "Average CO₂ emission",
      statusQuo: "Status quo",
      postModernization: "Post modernization",
      saving: "Savings",
    },
    actionButton: "Download report",
  },
  customization: {
    title: "Configurate your own modernization scenario",
    selectSentence: "Select the different measures you'd like to execute",
    feedback: "Is there anything you'd like to add or share?",
    formIsBlank: "The form is blank!",

    windows: "Windows",
    windowExchange: "Window exchange",
    wall: "Wall",
    wallInsulation: "Wall insulation",
    shading: "Shading",
    smartShading: "Smart shading",
    energy: "Energy",
    PVSystem: "PV system",
    actionButton: "Submit",
  },
};
