import DatePicker from "react-datepicker";
import styled from "styled-components";
import { ButtonNew } from "./ButtonNew";
import {
  addDays,
  toDateString,
  toLeaftechDateString,
} from "../functions/general";
import { Icon } from "./Icon";
import { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const CustomInput = React.forwardRef(
  ({ value, onClick, forwardDays, width }, ref) => (
    <InputWrapper
      tabIndex={0}
      value={value}
      style={{
        width: width,
        position: "relative",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <input
        style={{
          width: "100%",
          padding: "1.05rem 1.4rem 1.05rem 1.2rem",
          height: "4rem",
          cursor: "pointer",
          /* grey/900 */
          // border: "1px solid #1C1D20",
          borderRadius: "7px",
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "19px",
          /* identical to box height */
        }}
        onClick={onClick}
        ref={ref}
        // value={`${toDateString(value)} - ${toDateString(
        //   addDays(value, forwardDays)
        // )}`}
        value={toLeaftechDateString(value)}
        onChange={() => null}
        // value={value}
      />
      <Icon
        iconName={"Calendar"}
        className="calendar-input"
        onClick={onClick}
      ></Icon>
    </InputWrapper>
  )
);

const InputWrapper = styled("div")(({ value }) => ({
  input: {
    color: "var(--clr-grey-600)",
    border: `1px solid ${value ? "var(--clr-leaftech-blue-500)" : "#1C1D20"}`,
    ":hover": {
      border: "1px solid var(--clr-secondary-blue-200)",
    },
    ":focus": {
      border: "1px solid var(--clr-secondary-blue-500)",
    },
  },
}));

export const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setIsCalendarOpen,
  onConfirm,
  onOpen,
  width,
  height,
}) => {
  const { t } = useTranslation();
  const [closeModal, setCloseModal] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const [currentMonth, setCurrentMonth] = useState(startDate.getMonth());
  let forwardDays = 7;

  const isInCurrentMonth = (date) => {
    return date.getMonth() == currentMonth;
  };

  const MyContainer = ({ className, children }) => {
    return (
      <>
        <CalendarContainer className={className}>
          <div className="main-container" style={{ fontSize: "1rem" }}>
            {children}
          </div>
        </CalendarContainer>
      </>
    );
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleClose = () => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };
  const renderDayContents = (day, date) => {
    return <span>{date.getDate().toString().padStart(2, "0")}</span>;
  };

  return (
    <Wrapper style={{ width: width, height: height }}>
      <DatePicker
        shouldCloseOnSelect={false}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        renderDayContents={renderDayContents}
        selectsRange
        onChange={onChange}
        calendarContainer={MyContainer}
        calendarClassName={`test ${closeModal ? "hidden" : "show"}`}
        calendarStartDay={1}
        onMonthChange={(date) => {
          setCurrentMonth(date.getMonth());
        }}
        onCalendarOpen={() => {
          setIsCalendarOpen(true);
          setCurrentMonth(startDate.getMonth());
          setCloseModal(false);
          if (onOpen) onOpen();
        }}
        customInput={<CustomInput forwardDays={forwardDays} />}
        filterDate={isInCurrentMonth}
        onCalendarClose={() => {
          setIsCalendarOpen(false);
          handleClose();
          if (onclose) onclose();
        }}
      >
        <ButtonNew
          variant="secondary"
          size="md"
          onClick={() => {
            setCloseModal(true);
            setStartDate(selectedStartDate);
            setEndDate(selectedEndDate);
            setIsCalendarOpen(false);
            // setStartDate(selectedDate);
            // setDateInterval(selectedDateInterval);
          }}
        >
          {t("EnergyPerformance.dashboard.cancel")}
        </ButtonNew>
        <ButtonNew
          variant="primary"
          size="md"
          onClick={() => {
            if (onConfirm) onConfirm();
            setCloseModal(true);
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
            setIsCalendarOpen(false);
            // setSelectedDate(startDate);
            // setSelectedDateInterval(dateInterval);
          }}
        >
          {t("EnergyPerformance.dashboard.confirmSelection")}
        </ButtonNew>
      </DatePicker>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .main-container {
    position: relative;
  }
  .calendar-input {
    position: absolute;
    right: 5%;
  }
  .test {
    background-color: white;
    color: black;
    border: none;
    width: 36rem;
    padding: 2.4rem;
    border-radius: 1.5rem;
  }
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }

  div.react-datepicker__header {
    background-color: white;
    border: none;
  }
  .react-datepicker__current-month {
    color: black;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .react-datepicker__month {
    margin: 0;
    margin-top: 0.5rem;
  }
  .react-datepicker__week {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    color: gray;
    text-transform: uppercase;
    font-size: 1.2rem;
  }
  .react-datepicker__children-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0;
    margin-top: 4rem;
    padding: 0;
  }
  .react-datepicker__month-container {
    display: block;
    margin: auto;
    float: none;
  }
  .react-datepicker__current-month {
    //styleName: Headings/Heading L;
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    color: var(--clr-gray-900);
  }
  .react-datepicker__navigation {
    color: black;
  }
  .react-datepicker__day {
    font-family: "Exo 2";
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8rem;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    color: var(--clr-gray-900);
  }
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    /* secondary blue/50 */
    background: #e9eefc;
    /* secondary blue/500 */
    color: var(--clr-secondary-blue-500);
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    /* secondary blue/50 */
    background: #e9eefc;
    /* secondary blue/500 */
    border: 1px solid #2459ea;
    border-radius: 50%;
    color: var(--clr-secondary-blue-500);
  }
  .react-datepicker__day--disabled {
    color: var(--clr-gray-300);
  }
  /* .react-datepicker__day--in-range:hover, */
  /* .react-datepicker__day--in-selecting-range:hover */
`;
