import { createSlice } from "@reduxjs/toolkit";

const initialFiltersState = {
  energyLabel: {
    values: [],
    options: ["+A", "A", "B", "C", "D", "E", "F", "G", "Pending"],
    label: "energy class",
    lookUpField: "energy_label",
  },
  location: {
    values: [],
    options: ["Mannheim", "Blomberg", "Minden", "Berlin"],
    label: "location",
    lookUpField: "location",
  },
  type: {
    values: [],
    options: ["Commercial", "Public", "Office", "Pending"],

    label: "type",
    lookUpField: "type",
  },
  certificate: {
    values: [],
    options: ["DGNB", "Pending"],
    label: "certification",
    lookUpField: "certificate",
    nestedLookUpField: "type",
  },
  year: {
    values: [],
    options: [1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, 2030, null],
    displayOptions: [
      "< 1951",
      "1951-1960",
      "1961-1970",
      "1971-1980",
      "1981-1990",
      "1991-2000",
      "2001-2010",
      "2011-2020",
      "2021-2030",
      "Pending",
    ],
    label: "construction year",
    lookUpField: "construction_year",
  },
};

const initialState = {
  allBuildings: [],
  isLoading: false,
  filters: initialFiltersState,
  searchTerm: "",
};

const allBuildingsSlice = createSlice({
  name: "allBuildings",
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      Object.keys(payload).forEach((filterName) => {
        state.filters[filterName].values = payload[filterName];
      });
    },
    clearFilters: (state) => {
      state.filters = initialFiltersState;
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
  },
});

export const {
  setFilters,
  setSearchTerm,
  clearFilters,
} = allBuildingsSlice.actions;
export default allBuildingsSlice.reducer;
