import Login from "./Login";
import SharedLayout from "./SharedLayout";
import AssetOverview from "./AssetOverview";
import BuildingPassportSharedLayout from "./BuildingPassportSharedLayout";
import BuildingProfile from "./BuildingProfile";
import TechnicalData from "./TechnicalData";
import EnergyPerformance from "./EnergyPerformance";
import Certificates from "./Certificates";
import Modernization from "./Modernization";
import general from "./general";

import dynamicValues from "./dynamicValues";

export const de = {
  ...dynamicValues,
  general,
  Login,
  SharedLayout,
  AssetOverview,
  BuildingPassportSharedLayout,
  BuildingProfile,
  TechnicalData,
  EnergyPerformance,
  Certificates,
  Modernization,
};
