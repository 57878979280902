import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Icon } from "./Icon";
import { ButtonNew } from "./ButtonNew";
export const IconButton = ({ iconProps, buttonProps }) => {
  let { color, fill, stroke } = iconProps;
  const { disabled, isActive } = buttonProps;

  const disabledClr = "var(--clr-gray-500)";
  const activeClr = "var(--clr-secondary-blue-500)";

  if (fill) fill = disabled ? disabledClr : isActive ? activeClr : fill;
  if (stroke) stroke = disabled ? disabledClr : isActive ? activeClr : stroke;
  color = disabled ? disabledClr : isActive ? activeClr : color;

  return (
    <ButtonNew {...buttonProps} disabled={disabled} isActive={isActive}>
      <Icon {...iconProps} color={color} fill={fill} stroke={stroke} />
    </ButtonNew>
  );
};
