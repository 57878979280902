import { Paper, Stack } from "@mui/material";
import React from "react";
import { replaceDecimalPointWithComma } from "../utils/namesManipulation";
import { useTranslation } from "react-i18next";
export const DataCard = ({ kpi, className }) => {
  return (
    <Paper className={`${className} h-full p-4`}>
      <Stack
        direction="column"
        spacing={6}
        className="justify-between items-start h-full"
      >
        <h2 className="t-body-xl capitalize">{kpi?.name}</h2>
        <p className="t-numbers-xl text-blue-500 ">
          {replaceDecimalPointWithComma(kpi?.value) + " "}
          <span className="t-numbers-m-reg ">{kpi?.unit}</span>
        </p>
      </Stack>
    </Paper>
  );
};

export const EmptyDataCard = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Paper className={`${className} h-full p-4`}>
      <span className="t-body-xl">
        {t("BuildingProfile.kpis.kpisNotListed")}
      </span>
    </Paper>
  );
};
