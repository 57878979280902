export const getUnitBasedValue = (value, unit, buildingArea) => {
  const divideFactor = unit?.endsWith("/m²") ? buildingArea : 1;
  return parseFloat((value / (divideFactor || 1))?.toFixed(2));
};
export const removeResourcesWithNoData = (data, resources) =>
  resources?.filter((resource) => {
    const doesDataContainLegend = data.some((row) => {
      return row[resource.dataKey];
    });
    return doesDataContainLegend;
  });

export const addUnitBasedValuetoData = (
  resources,
  aggregatedData,
  unit,
  buildingArea
) =>
  resources?.map((resource) => {
    return {
      ...resource,
      value: getUnitBasedValue(
        aggregatedData[resource.dataKey],
        unit,
        buildingArea
      ),
    };
  });
