import { Paper, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { addCommaToNumber } from "../utils/namesManipulation";
import { CertificateLabel } from "./CertificateLabel";
import { EnergyLabel } from "./EnergyLabel";
import { LabelValueInline } from "./LabelValueInline";

const dataLabelsAndKeysMapping = [
  { label: "Allotment date", dataKey: "allotment_date" },
  { label: "Area", dataKey: "area" },
  { label: "Valid until", dataKey: "valid_until" },
];

export const CertificateCard = ({ certificate, type }) => {
  const { t } = useTranslation();
  if (!certificate) return;
  return (
    <Paper className="h-22 p-4">
      <Stack className="justify-between items-center">
        <ul className="t-body-s  ">
          {dataLabelsAndKeysMapping?.map((mapping, index) => {
            return (
              <li key={index} className="mb-1">
                <LabelValueInline
                  label={t(`BuildingProfile.certificateCard.${mapping?.label}`)}
                  value={mapping?.label === "Area"
                    ? `${addCommaToNumber(certificate[mapping?.dataKey])} m²`
                    : certificate[mapping?.dataKey]}
                />
              </li>
            );
          })}
        </ul>
        {type === "epc" && (
          <EnergyLabel energyClass={certificate?.label} className="w-14 h-14" />
        )}
        {type !== "epc" && (
          <CertificateLabel
            certificateClass={certificate?.label}
            type={certificate?.type}
            className="w-14 h-14"
          />
        )}
      </Stack>
    </Paper>
  );
};

export const EmptyCertificateCard = ({ label }) => {
  return (
    <Paper className="h-22 p-4">
      <p className="t-body-xl  ">{label}</p>
    </Paper>
  );
};
