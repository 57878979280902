import { Stack } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IsAllowed } from "../../acl";
import { resources } from "../../acl/resources";
import { postSelectedColumns } from "../../api/functions/all";
import { useCheckTechDataDownloadFilesStatus } from "../../api/hooks/allHooks";
import apiUrls from "../../api/urls";
import { getTechTableConfig } from "../../configs/TechnicalData";
import {
  getSelectedColumns,
  setSelectedColumns,
} from "../../features/techData/techDataSlice";
import { customApiCall } from "../../utils/axios";
import { sentenceCase } from "../../utils/namesManipulation";
import { ButtonNew } from "../ButtonNew";
import { Dropdown } from "../Dropdown";
import { Icon } from "../Icon";
import { eventNames, withGAEventTracking } from "../../functions/googleAnalytics";
const downloadEnvelopFiles = ({ projectId, activeTab }) => {
  const url = apiUrls.layerFiles.techData[activeTab].downloadAll(projectId);
  const fileName = `${activeTab} files.zip`;
  const errorMessage = i18next.t("general.downloadFileProblem");
  customApiCall.download({ url, fileName, errorMessage });
};

export const TableContainerHeader = ({
  activeTab,
  projectId,
  selectedColumns,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((store) => store.user);

  const columnSelectable = IsAllowed(resources.TECHNICAL_DATA.SELECT_COLUMNS);

  const dispatch = useDispatch();

  const config = getTechTableConfig({ projectId, activeTab });
  const cols = config[activeTab].columns;
  const colNames = cols?.map((col) => col.dataKey);
  const colDispNames = cols?.map((col) => col.label);

  const handleColumnSelect = (selected) => {
    const data = {
      project: Number(projectId),
      user: user?.user_id,
      ...selected,
    };
    postSelectedColumns({ data });
  };

  const { data } = useCheckTechDataDownloadFilesStatus(projectId, activeTab);

  return (
    <Stack className="p-3 pl-0 justify-between">
      <span className="t-heading-l  ">
        {activeTab === "PV system"
          ? "PV system"
          : sentenceCase(t(`TechnicalData.nav.${activeTab}Details`))}
      </span>
      {columnSelectable && (
        <Dropdown
          displayOptions={colDispNames}
          label="select columns"
          options={colNames}
          values={selectedColumns || []}
          onBlur={() =>
            handleColumnSelect({ [config[activeTab].dataKey]: selectedColumns })
          }
          setValues={(values) =>
            dispatch(
              setSelectedColumns({ [config[activeTab].dataKey]: values })
            )
          }
          width={"200px"}
        />
      )}
      {IsAllowed("TECHNICAL_DATA.DOWNLOAD_FILES") && (
        <ButtonNew
          variant="tertiary"
          onClick={() => {
            withGAEventTracking(eventNames.TD_DOWNLOAD_ALL_TAB, { tabName: activeTab },
              () => downloadEnvelopFiles({ projectId, activeTab }));
          }}
          size="sm"
          disabled={data?.status !== 200}
        >
          <Stack className=" justify-between">
            <Icon
              color="var(--clr-secondary-blue-500)"
              iconName="Download"
              className="mr-1.5"
              disabled={data?.status !== 200}
            ></Icon>
            <p className="t-body-m ">
              {t(`TechnicalData.nav.${activeTab}FilesDownload`)}
            </p>
          </Stack>
        </ButtonNew>
      )}{" "}
    </Stack>
  );
};
