import { sumByKeys } from "../../utils/dataManipulation";
import { PieAndDoughnutChart, SunburstChart } from "../chart/PieAndDoughnutChart"
import { HorizontalStackedBarChartColorBar } from "../chart/HorizontalStackedBarChartColorBar";
import { buildingUsageConfig, buildingUseTypesConfig } from "../../configs/BuildingProfile";
import { Paper, Stack } from "@mui/material";
import { VerticalStackedBarChartColorBar } from "../chart/VerticalStackedBarChartColorBar";
import { GeneralChartLegend } from "../chart/GeneralChartLegend";
import { CaptureComponent } from "../CaptureComponent";
import { useRef } from "react";
import { sum } from "lodash";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const UseTypes = ({ data, className }) => {
    // const aggregated = sumByKeys({ data, keys: ['area', 'rented_area_covered'] })
    // const { area, rented_area_covered } = aggregated

    const allUseTypes = Object.keys(buildingUseTypesConfig)

    const activeUseTypes = Object.keys(data ?? {}).filter(useType => allUseTypes.includes(useType) && data[useType])
    const dataForUseTypeChart = activeUseTypes
        .map(useType => {
            return {
                value: data[useType],
                color: buildingUseTypesConfig[useType]?.color,
                label: buildingUseTypesConfig[useType]?.label
            }
        })

    const useTypesChartLegends = dataForUseTypeChart?.map(useType => {
        return { label: useType.label, color: useType?.color }
    })

    const containerHeightClass = 'h-[360px]'

    const totalArea = sum(activeUseTypes?.map(useType => data[useType]))
    const vacantArea = data?.['Total Vacant Area']
    const vacancyChartData = [
        {
            percentage: 100 * vacantArea / totalArea,
            label: 'Vacant'
        },
        {
            percentage: 100 * (1 - (vacantArea / totalArea)),
            label: 'Rented'
        },
    ]
    const vacancyDiagramHeightClass = 'h-70'

    const vacancyChartLegends = vacancyChartData?.map(row => {
        return { ...row, value: row.percentage?.toFixed(1), color: buildingUsageConfig[row.label]?.color }
    })

    const containerRef = useRef()
    return (
        <div className={className}>
            <div ref={containerRef} >
                <Paper>
                    <Stack className="justify-between">
                        <h3 className="t-heading-l">Building Usage</h3>
                        <CaptureComponent targetElement={() => containerRef.current} />
                    </Stack>
                    <LoadingOrEmptyWrapper showEmpty={!Object.keys(data ?? {})?.length}>
                        <Stack className={`justify-between mt-8 ${containerHeightClass}`}>
                            <div className="w-1/2 ">
                                <PieAndDoughnutChart
                                    containerProps={{ height: 250, width: '100%' }}
                                    chartProps={{ height: 200 }}
                                    pieProps={{ innerRadius: 0, outerRadius: 100, dataKey: 'value', nameKey: 'label', }}
                                    data={dataForUseTypeChart}
                                />
                                <GeneralChartLegend className="mt-8 w-full max-h-[120px] overflow-auto" legends={useTypesChartLegends} />
                            </div>
                            <div className={`w-2/5  ${containerHeightClass}`}>
                                <div className={`w-full relative ${vacancyDiagramHeightClass}`}>
                                    <Stack className={`absolute bottom-0 w-full h-full flex-col ${vacancyDiagramHeightClass}`}>
                                        <VerticalStackedBarChartColorBar
                                            className={`w-35 ${vacancyDiagramHeightClass}`}
                                            data={vacancyChartData}
                                            config={buildingUsageConfig}
                                            bottom='15px'
                                        // isLoading={false}
                                        // isError={false}
                                        />
                                    </Stack>
                                    <Stack className="absolute bottom-0 w-full h-full justify-between flex-col-reverse">
                                        {[...Array(11).keys()].map(number => {
                                            // const flexSelfClass = number===0?'fle'
                                            return <Stack className="w-[80%] relative">
                                                <span className="absolute -left-8 t-numbers-xxs">{number * 10}%</span>
                                                <div className="h-[1px] bg-gray-200 flex-1"></div>
                                            </Stack>
                                        })}
                                    </Stack>
                                </div>
                                <GeneralChartLegend className="mt-8" legends={vacancyChartLegends} />
                            </div>

                        </Stack>
                    </LoadingOrEmptyWrapper>
                </Paper>
            </div>
        </div>
    )

}
