import { Card, Grid, Stack } from "@mui/material";
import { json, NavLink, useParams } from "react-router-dom";
import {
  useCheckCertificatesDownloadFilesStatus,
  useGetBuilding,
  useGetBuildingCertificates,
  useGetBuildingEPCInfo,
  useGetBuildingFiles,
  useGetBuildingKpis,
  useGetBulidingUsage,
  useGetCertificateStatus,
  useGetCrrem,
  useGetEnergyRatingStatus,
  useGetKPIs,
} from "../api/hooks/allHooks";
import { AssetDetailedCard } from "../components/BuildingProfile/AssetDetailedCard";
import { BuildingProfileTable } from "../components/BuildingProfile/BuildingProfileTable";
import { KPIs } from "../components/BuildingProfile/KPIs";
import { CertificatesCardsArticle } from "../components/CertificatesCards";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Paper from "@mui/material/Paper";
import { ComponentsCommonHeader } from "../components/ComponentsCommonHeader";
import { BulletChart, ButtonNew, Chip, CustomModal, DataCard } from "../components";
import { useEffect, useRef, useState } from "react";
import LegendBullet from "../components/chart/LegendBullet";
import {
  addCommaToNumber,
  replaceDecimalPointWithComma,
  valueOrAlternative,
} from "../utils/namesManipulation";
import { HorizontalStackedBarChart } from "../components/chart/HorizontalStackedBarChart";
import { HorizontalStackedBarChartColorBar } from "../components/chart/HorizontalStackedBarChartColorBar";
import { Input, LabelLessInput } from "../components/Input";
import apiUrls from "../api/urls";
import axios from "axios";
import { customApiCall } from "../utils/axios";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { IsAllowed } from "../acl";
import ScrollToTop from "../components/ScrollToTop";
import { eventNames, trackGAEvent, withGAEventTracking } from "../functions/googleAnalytics";
import { buildingUsageConfig, buildingUseTypesConfig } from "../configs/BuildingProfile";
import { theme } from "../styles/theme";
import { PieAndDoughnutChart } from "../components/chart/PieAndDoughnutChart";
import { UseTypes } from "../components/BuildingProfile/UseTypes";
import { DynamicDashboardHourlyDataGetter } from "../components/EnergyPerformance/DynamicDashboardHourlyDataGetter";
import { isNumber } from "lodash";
import { StrandingChart } from "../components/Modernization/StrandingChart";
import { CaptureComponent } from "../components/CaptureComponent";
import { BulletChartDoubleWithIntervals } from "../components/chart/BulletChart";
import { calculateRelativeWidth } from "../utils/position";
import { LabelValueInline } from "../components/LabelValueInline";
import { EnergyEfficiencyClass } from "../components/EnergyEfficiencyClass";
import { generateAxisValues } from "../utils/dynamicDashboard";
import { downloadCertificateFiles } from "../api/functions/all";

const UsageItem = ({
  className,
  title,
  area,
  coveredArea,
  percentage,
  ...props
}) => {
  return (
    // <Paper className={`py-2 px-3 ${className}`} {...props}>
    <Card className={`py-2 px-3 ${className}`} {...props}>
      <Stack justifyContent="space-between">
        <div>
          <h4 className="t-body-xl sentence-case">{title}</h4>
          <p className="t-body-s mt-1">
            <span className="text-gray-600">Area: </span>
            <span>{addCommaToNumber(Number(area))} m²</span>
          </p>
          <p className="t-body-s mt-1">
            <span className=" text-gray-600">Rented area covered:</span>
            <span>{coveredArea}%</span>
          </p>
        </div>
        <span className="t-numbers-m-reg text-blue-500">{percentage} %</span>
      </Stack>
    </Card>
    // </Paper>
  );
};


export const BuildingProfileOldNew = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const queryClient = useQueryClient();
  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};

  const { data: kpis, isLoading: kpiIsLoading } = useGetBuildingKpis(projectId);
  const { data: usages0, isLoading: usagesIsLoading } = useGetBulidingUsage(
    projectId
  );

  const usages = [{
    "id": 1,
    "category": 'office',
    "area": 1000.0,
    "rented_area_covered": 120.0,
    "percentage": 0.8,
    "project": 1
  }, {
    "id": 2,
    "category": 'retail_high_street',
    "area": 3000.0,
    "rented_area_covered": 1200.0,
    "percentage": 0.8,
    "project": 1
  },
  {
    "id": 3,
    "category": 'retail_shopping_center',
    "area": 5000.0,
    "rented_area_covered": 1000.0,
    "percentage": 0.8,
    "project": 1
  }
  ]
  const { data: buildingFiles1, isLoading: bFIsLoading } = useGetBuildingFiles(
    projectId
  );
  const buildingFiles = buildingFiles1 || [];
  const { data, isLoading: ceIsLoading } = useGetBuildingCertificates(
    projectId
  );
  const { energyCertificates, nonEnergyCertificates } = data;
  const [category, setCategory] = useState("usage");


  const buildingUsageTypes = Object.keys(buildingUsageConfig)
  const roomTypes = Object.keys(buildingUseTypesConfig)
    ?.filter(roomType => usages?.some(usage => roomType === usage?.category))

  const roomTypesLegends = roomTypes?.map(roomType => buildingUseTypesConfig[roomType])
  const buildingUsageLegends = buildingUsageTypes?.map(type => buildingUsageConfig[type])
  const legends = [...roomTypesLegends, buildingUsageLegends]


  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const BuildingUsageUpdateBox = ({
    className,
    data,
    setShowModal,
    ...props
  }) => {
    const [updatedData, setUpdatedData] = useState(data || {});
    const handleChange = (key, e) => {
      setUpdatedData({ ...updatedData, [key]: e.target.value });
    };

    const handleSubmit = async () => {
      const url = apiUrls.buildingUsage.get(updatedData.id);
      const data = {
        area: updatedData.area,
        rented_area_covered: updatedData.rented_area_covered,
        percentage: updatedData.percentage,
      };
      try {
        await customApiCall.patch({ url, data });
        toast.success("Updated successfully!");
        queryClient.invalidateQueries(["bulidingUsage", projectId]);
      } catch {
        toast.error("There was a problem updating information!");
      }
    };

    return (
      <Paper className={`border-0 ${className}`} {...props}>
        <h3 className="t-heading-m sentence-case">{updatedData.category}</h3>
        <h4 className="t-body-s text-gray-600 mt-4">
          Update the information using the boxes below.
        </h4>
        <Input
          label="Area m²"
          className="mt-4"
          placeholder="Area m²"
          value={updatedData.area}
          onChange={(e) => handleChange("area", e)}
        />
        <Input
          label="Rented area covered %"
          className="mt-4"
          placeholder="Rented area covered %"
          value={updatedData.rented_area_covered}
          onChange={(e) => handleChange("rented_area_covered", e)}
        />

        <Input
          label="Percentage %"
          className="mt-4"
          placeholder="Percentage %"
          value={updatedData.percentage}
          onChange={(e) => handleChange("percentage", e)}
        />
        <Stack justifyContent="space-around" className="mt-4">
          <ButtonNew
            variant="secondary"
            size="md"
            onClick={() => setShowModal(false)}
            width="150px"
          >
            Cancel
          </ButtonNew>
          <ButtonNew
            variant="primary"
            size="md"
            onClick={() => {
              handleSubmit();
              setShowModal(false);
            }}
            width="150px"
          >
            Done
          </ButtonNew>
        </Stack>
      </Paper>
    );
  };
  const certificateRatingConfig = {
    platinum: {
      color: "#737481", ///* gray/600 */
      label: t("Certificates.chart.platinum"),
      order: 1,
    },
    gold: {
      color: "#C5B100", ///* star/700 */
      label: t("Certificates.chart.gold"),
      order: 2,
    },
    silver: {
      color: "#C0C0C0", ///* */
      label: t("Certificates.chart.silver"),
      order: 3,
    },
    bronze: {
      color: "#A15C00", ///* tangerine/800*/
      label: t("Certificates.chart.bronze"),
      order: 4,
    },
    notCertified: {
      color: "#BCBDC7", ///* grey/300 */
      label: t("Certificates.chart.notCertified"),
      order: 5,
    },
  };

  const energyRatingConfig = {
    A: {
      color: "#00722C", //cathode-green/900
      label: "EPC-A",
      order: 1,
    },
    B: {
      color: "#00A13F", //cathode-green/800
      label: "EPC-B",
      order: 2,
    },
    C: {
      color: "#00C54D", //cathode-green/700
      label: "EPC-C",
      order: 3,
    },
    D: {
      color: "#E4CC00", //   star/600
      label: "EPC-D",
      order: 4,
    },
    E: {
      color: "#FF9200", //   tangerine/500
      label: "EPC-E",
      order: 5,
    },
    F: {
      color: "#FF5300", //   mystic-red/500
      label: "EPC-F",
      order: 6,
    },
    G: {
      color: "#C54000", //   mystic-red/700
      label: "EPC-G",
      order: 7,
    },
    notCertified: {
      color: "#BCBDC7", ///* grey/300 */
      label: t("Certificates.chart.notCertified"),
      order: 8,
    },
  };

  const addNotCertifiedToData = (data) => {
    if (!data) return data;
    let newData = [...data];
    let sumPercentage = 0;
    for (let i = 0; i < data?.length; i++) {
      sumPercentage += data[i]?.percentage;
    }
    let newObj = {
      type: null,
      label: "notCertified",
      percentage: 1 - sumPercentage,
    };
    newData.push(newObj);
    return newData;
  };

  const {
    data: dataCSI,
    isLoading: cSIsLoading,
    isLoadingError: isErrorCSI,
  } = useGetCertificateStatus(projectId);
  const certStat = isErrorCSI ? dataCSI : addNotCertifiedToData(dataCSI);

  const {
    data: dataRSI,
    isLoading: eRSIsLoading,
    isLoadingError: isErrorRSI,
  } = useGetEnergyRatingStatus(projectId);
  const energyStat = isErrorRSI ? dataRSI : addNotCertifiedToData(dataRSI);

  const isUsageSection = category === "usage"
  const isEPCSection = category === "epc"
  const isCertificateSection = category === "certificates"

  return (
    <>
      <Helmet>
        <title>Building profile | Leaftech</title>
      </Helmet>
      <CustomModal
        modalOpen={showModal}
        setModalOpen={setShowModal}
        width={500}
        height={500}
        className="overflow-scroll max-h-[90rem]"
      >
        <BuildingUsageUpdateBox
          data={selectedCategory}
          setShowModal={setShowModal}
        />
      </CustomModal>

      <Stack spacing={6} className="justify-between items-stretch">
        <section class="min-w-[47%] max-w-[55%] overflow-hidden">
          <AssetDetailedCard
            building={building}
            imgPath={building?.image_url_value}
            name={t("BuildingProfile.card.title")}
            isLoading={isLoading}
          ></AssetDetailedCard>
        </section>
        <section class="flex flex-col w-[49%]">
          <KPIs
            kpis={kpis || []}
            isLoading={kpiIsLoading}
            title={t("BuildingProfile.kpis.title")}
            xs={kpis?.length > 2 ? 12 : 6}
          />
          <BuildingProfileTable
            className="mt-8 p-6 flex-1"
            isLoading={bFIsLoading}
            buildingFiles={buildingFiles || []}
            projectId={projectId}
            tableClassName="max-h-85"
          />
        </section>
      </Stack>
      <Paper className="mt-6">
        <ComponentsCommonHeader title="Building Use Percentage"></ComponentsCommonHeader>
        <Stack justifyContent="space-between" className="mt-6">
          <Stack spacing={4} className="w-90">
            <Chip
              isOn={isUsageSection}
              onClick={() => setCategory("usage")}
            >
              Usage Types
            </Chip>
            <Chip isOn={isEPCSection} onClick={() => setCategory("epc")}>
              EPC Rating
            </Chip>
            <Chip
              isOn={isCertificateSection}
              onClick={() => setCategory("certificates")}
            >
              Certificates
            </Chip>
          </Stack>
          {isUsageSection &&
            <div className="">
              <Stack spacing={4} className="w-full" flexWrap='wrap'>
                {roomTypesLegends?.map((legend, index) => {
                  const roomType = roomTypes[index]
                  return (
                    <Stack spacing={2} key={index} className={` cursor-pointer hover:bg-blue-100`}
                      sx={{
                        border: '1px solid #ddd',
                        borderRadius: '7px',
                        padding: '4px 8px'
                      }}
                      onClick={() => {
                        setShowModal(true);
                        const categoryData = usages?.find(usage => usage.category === roomType)
                        setSelectedCategory(categoryData);
                      }}>
                      <LegendBullet
                        width="0.8rem"
                        height="0.8rem"
                        type="circle"
                        color={legend.color}
                      />
                      <span className="t-body-xl sentence-case">
                        {legend.label}
                      </span>
                    </Stack>
                  );
                })}
              </Stack>
              <Stack spacing={4} className="w-full mt-4" flexWrap='wrap'>

                {buildingUsageLegends?.map((legend, index) => {
                  const type = roomTypes[index]
                  return (
                    <Stack spacing={2} key={index}

                    >
                      <LegendBullet
                        width="0.8rem"
                        height="0.8rem"
                        type="circle"
                        color={legend.color}
                      />
                      <span className="t-body-xl sentence-case">
                        {legend.label}
                      </span>
                    </Stack>
                  );
                })}
              </Stack>
            </div>
          }
        </Stack>
        <Stack spacing={12} className="mt-6">
          <div>
            <h5 className="t-label-m-caps text-gray-400 ">Total Area</h5>
            <p className="t-numbers-m mt-1">
              {addCommaToNumber(23890)}
              {" m²"}
            </p>
          </div>
          <div>
            <h5 className="t-label-m-caps text-gray-400 ">
              Rented Area covered
            </h5>
            <p className="t-numbers-m mt-1">
              {replaceDecimalPointWithComma("80")}
              {" %"}
            </p>
          </div>
        </Stack>
        <Stack spacing={8} alignItems="end" className="h-80 mt-4">
          {isEPCSection && (
            <HorizontalStackedBarChart
              className="w-3/5 h-full border-0  flex flex-col justify-center"
              height="8rem"
              data={energyStat}
              config={energyRatingConfig}
              bottom='15px'
            />
          )}
          {isCertificateSection && (
            <HorizontalStackedBarChart
              className="w-3/5 h-full border-0 flex flex-col justify-center"
              height="8rem"
              data={certStat}
              config={certificateRatingConfig}
              bottom='15px'
            />
          )}

          {isUsageSection && (
            // <HorizontalStackedBarChartColorBar
            //   className="w-3/5 h-full"
            //   data={dataForChart}
            //   config={config}
            //   height="8rem"
            // // isLoading={false}
            // // isError={false}
            // />
            <UseTypes
              data={usages}
            />
          )}
          {!isUsageSection &&
            <div className={"w-2/5"}>
              {/* {isUsageSection &&
              usages?.map((usage) => {
                return (
                  <UsageItem
                    className="w-full  mt-4"
                    title={usage.category}
                    coveredArea={usage.rented_area_covered}
                    percentage={usage.percentage}
                    area={usage.area}
                    onClick={() => {
                      setShowModal(true);
                      setSelectedCategory(usage);
                    }}
                  />
                );
              })} */}
              {isEPCSection && (
                <CertificatesCardsArticle
                  className="min-h-[13rem] mt-10"
                  certificates={energyCertificates}
                  // isLoading={ceIsLoading}
                  // title={t("BuildingProfile.certificateCard.energyRatingTitle")}
                  hasButton={false}
                  certificateType="epc"
                  xl2={12}
                />
              )}
              {isCertificateSection && (
                <CertificatesCardsArticle
                  className="min-h-[13rem] mt-10"
                  certificates={nonEnergyCertificates}
                  // isLoading={ceIsLoading}
                  // title={t("BuildingProfile.certificateCard.certificateTitle")}
                  hasButton={false}
                  certificateType="certificate"
                  xl2={12}
                />
              )}
            </div>
          }
        </Stack>
      </Paper>
    </>
  );
};


export const BuildingProfile = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};

  const crremKPIs = useGetKPIs(projectId)
  const { data: oldKpis, isLoading: kpiIsLoading } = useGetBuildingKpis(projectId);

  const kpis = (crremKPIs.total_carbon_emission !== undefined && crremKPIs.total_energy_consumption !== undefined) ?
    [crremKPIs] : oldKpis


  const kpisLabelsAndKeysMapping = [
    {
      label: t("BuildingProfile.kpis.totalEnergyConsumption"),
      dataKey: "total_energy_consumption",
      unit: "kWh/m²/a",
      round: true
    },
    {
      label: t("BuildingProfile.kpis.totalCarbonEmission"),
      dataKey: "total_carbon_emission",
      unit: "kg/m²/a",
      round: true
    },
    {
      label: t("BuildingProfile.kpis.anticipatedStrandingYear"),
      dataKey: "stranding_year",
      unit: "",
      round: false
    },
  ];


  const heatEnergyConsumption = building?.heat_energy_consumption_epc_kwh_per_square_meter_annum
  const GEGKpis = (isNumber(heatEnergyConsumption)) ? [{ heat_energy_consumption_epc_kwh_per_square_meter_annum: heatEnergyConsumption }] : []

  const GEGKpisLabelsAndKeysMapping = [
    {
      label: t("BuildingProfile.kpis.epcConsumption"),
      dataKey: "heat_energy_consumption_epc_kwh_per_square_meter_annum",
      unit: "kWh/m²/a",
      round: true
    },
  ];


  const { data: buildingFiles, isLoading: bFIsLoading } = useGetBuildingFiles(
    projectId
  );
  const { data, isLoading: ceIsLoading } = useGetBuildingCertificates(
    projectId
  );
  const { energyCertificates, nonEnergyCertificates } = data;

  useEffect(() => {
    trackGAEvent(eventNames.BP_VIEW)
  }, []);

  const assetCardConfig = [
    [
      { label: "ASSET NAME", dataKey: "descriptive_name" },
      { label: "TYPE", dataKey: "type" },
      { label: "ASSET ID", dataKey: "asset_id" },
      { label: "ADDRESS", dataKey: "address" },],
    [
      { label: "YEAR OF CONSTRUCTION", dataKey: "construction_year" },
      { label: "MODERNIZATION YEAR", dataKey: "last_modernized" },
      { label: "GROSS AREA (INCL. BASEMENT)", dataKey: "gross_asset_basement_included", isNumeric: true, renderUnit: (value) => value && ' m²' },
      { label: "Net LETTABLE AREA (WITHOUT BASEMENT)", dataKey: "net_lettable_area_without_basement", isNumeric: true, renderUnit: (value) => value && ' m²' }]
  ];
  const containerRef = useRef()

  const [epcCrremSelectedSection, setEpcCrremSelectedSection] = useState('epc')


  let { data: epcInfoAll } = useGetBuildingEPCInfo(projectId)
  epcInfoAll = epcInfoAll?.length ? epcInfoAll[0] : {}
  const bullets = [
    {
      label: `Primary Energy Demand: ${epcInfoAll?.primary_energy_kwh_per_m2}`,
      value: epcInfoAll?.primary_energy_kwh_per_m2,
      color: "var(--clr-secondary-blue-700)",
      reverse: true,
      handleHeight: 0
    },
    {
      label: `Primary Energy Demand Comparable Building: ${epcInfoAll?.primary_energy_reference_kwh_per_m2}`,
      value: epcInfoAll?.primary_energy_reference_kwh_per_m2,
      color: "var(--clr-vivid-orchid-600)",
      reverse: true,
      handleHeight: 30
    },
    {
      label: `End Energy Demand: ${epcInfoAll?.end_energy_kwh_per_m2}`,
      value: epcInfoAll?.end_energy_kwh_per_m2,
      color: "var(--clr-secondary-blue-700)",
      reverse: false,
      handleHeight: 0
    },
    {
      label: `End Energy Demand Comparable Building: ${epcInfoAll?.end_energy_reference_kwh_per_m2}`,
      value: epcInfoAll?.end_energy_reference_kwh_per_m2,
      color: "var(--clr-vivid-orchid-600)",
      reverse: false,
      handleHeight: 30
    },

  ]

  const defaultEnergyAxis = [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550];

  const classification = epcInfoAll?.classification
  let energyAxis = defaultEnergyAxis
  let maxEnergyValue = 600


  let thresholds = []
  let labels = [];
  let minEnergyValue = undefined
  if (classification) {
    maxEnergyValue = Math.max(...Object.values(classification)) * 1.2
    energyAxis = !isNumber(maxEnergyValue) ? defaultEnergyAxis : generateAxisValues(0, maxEnergyValue, 50)

    const labelsMapping = {
      a_plus: 'A+',
      a: 'A',
      b: 'B',
      c: 'C',
      d: 'D',
      e: 'E',
      f: 'F',
      g: 'G',
      h: 'H',
    }
    for (const className in labelsMapping) {
      const classMin = classification[`class_${className}_min`]
      const classMax = classification[`class_${className}_max`]
      if (!classMin && !classMax) continue
      if (minEnergyValue === undefined) {
        minEnergyValue = classMin
        thresholds.push(classMin)
      }
      if (classMax) {
        labels.push(labelsMapping[className])
        thresholds.push(classMax)
      }
      if (classMin && !classMax) {
        labels.push(labelsMapping[className])
      }
    }
    thresholds.push(maxEnergyValue)
  }

  const epcInfoTextConfig = [
    { label: 'EPC Number', dataKey: 'epc_number' },
    { label: 'EPC Issued On', dataKey: 'issued_on' },
    { label: 'EPC Type', dataKey: 'type' },
    { label: 'EPC Valid Until', dataKey: 'valid_until' },
    { label: 'EPC According To', dataKey: 'according_to' },
    { label: 'Class Defined With', dataKey: 'class_defined_via' },
    { label: 'EPC Issued By', dataKey: 'issued_by' },
  ]
  const epcInfoTextAndValues = epcInfoTextConfig.map(item => ({ ...item, value: epcInfoAll?.[item.dataKey] }))


  const containerRef2 = useRef()
  let usages = {}
  if (building?.building_used_type) {
    try {
      usages = JSON.parse(building?.building_used_type)
    } catch { }
  }
  const { data: downloadFileExistenceStatus } = useCheckCertificatesDownloadFilesStatus(
    projectId,
    'epc'
  );
  return (
    <>
      <Helmet>
        <title>Building profile | Leaftech</title>
      </Helmet>
      <ScrollToTop />
      {/* <CaptureComponent targetElement={() => containerRef2.current}
        style={{
          border: "1px solid #ddd",
          borderRadius: "4px",
          width: "40px",
          height: "32px",
          padding: 3
        }}
      /> */}

      <Stack ref={containerRef2} spacing={6} className="justify-between items-start">
        {/* Left column */}
        <section className="min-w-[47%] max-w-[50%] overflow-hidden">
          <AssetDetailedCard
            building={building}
            config={assetCardConfig}
            buildingType={building?.type}
            imgPath={building?.image_url_value}
            name={t("BuildingProfile.card.title")}
            isLoading={isLoading}
          ></AssetDetailedCard>
          <UseTypes className={'mt-8'}
            data={usages}
          />
        </section>

        {/* Right column */}
        <section className="w-[49%]" >
          <div ref={containerRef} >
            <Paper className="relative">
              <CaptureComponent targetElement={() => containerRef.current} className={'absolute right-6 top-7'} />

              <Stack
                spacing={theme.spacing(8)}
                className={`t-subheading-m ${''} border-solid border-0 border-b-2 border-gray-100  mb-2`}
              >
                {['epc', 'crrem'].map((navLink, index) => {
                  const isActive = navLink === epcCrremSelectedSection
                  const isDisabled = false
                  return (
                    <span
                      key={index}
                      className={`uppercase border-solid border-0 border-b-2 cursor-pointer  py-2 px-4 mb-[-2px]   
                    ${isActive ? "text-blue-500 border-blue-500" : "text-gray-900 border-gray-100 hover:border-blue-500"
                        }`
                      }
                      onClick={(e) => setEpcCrremSelectedSection(navLink)}
                    >
                      {navLink}
                    </span>
                  );
                })}
              </Stack>
              {epcCrremSelectedSection === 'crrem' && <>
                <StrandingChart
                  className="w-full h-[45.5rem] "
                  chartHeight={280}
                  legendsWidth="450px"
                  projectId={projectId}
                  selectedMeaures={[]}
                  title={t("BuildingProfile.kpis.crremM2Gross")}
                />
                <KPIs
                  kpis={kpis || []}
                  className={'mt-4 -mx-3'}
                  isLoading={kpiIsLoading}
                  paperClassName='!border-0 -p-6'
                  // title={t("BuildingProfile.kpis.crremM2Gross")}
                  kpisLabelsAndKeysMapping={kpisLabelsAndKeysMapping}
                  xs={4}
                  xl2={4}
                />
                {/* <KPIs
                className='mt-4'
                kpis={GEGKpis || []}
                energyConsumptionPerM2={building?.energy_consumption ?? kpis?.[0]?.total_energy_consumption}
                isLoading={kpiIsLoading}
                title={t("BuildingProfile.kpis.geg2024LettableArea")}
                kpisLabelsAndKeysMapping={GEGKpisLabelsAndKeysMapping}
                xs={4}
                xl2={4}
              /> */}
              </>
              }
              {epcCrremSelectedSection === 'epc' &&
                <div>
                  <ComponentsCommonHeader
                    className={'!pt-0 !pb-4'}
                    action={() => withGAEventTracking(eventNames.BP_DOWNLOAD_CERTIFICATE, { certificateType: 'epc' },
                      () => downloadCertificateFiles({ projectId, certificateType: 'epc' }))}
                    actionIsDisabled={downloadFileExistenceStatus?.status !== 200}
                    actionText={t("Certificates.cards.buttonShort")}
                    hasActionAccess={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
                    title={t("BuildingProfile.certificateCard.epcTitle")}
                  />
                  <Stack className="flex-col items-start pb-15">
                    <Grid container columnSpacing={4} rowSpacing={6} className="justify-between items-start">
                      {epcInfoTextAndValues?.map(item => {
                        return (
                          <Grid item xs={6}>
                            <LabelValueInline
                              label={item.label}
                              value={valueOrAlternative(item.value, t('general.noData'))}
                              valueProps={{ className: 't-body-l' }}
                              labelProps={{ className: 't-heading-s text-gray-400' }}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                    <Grid className="mt-8 w-full" container columnSpacing={4} rowSpacing={6}>
                      {isNumber(epcInfoAll?.epc_carbon_dioxide_emission) &&
                        <Grid item xs={4}>
                          <DataCard kpi={{
                            name: t('BuildingProfile.card.CO2 EMISSIONS'), value: epcInfoAll?.epc_carbon_dioxide_emission, unit: 'KgCO₂'
                          }} />
                        </Grid>
                      }
                      {isNumber(epcInfoAll?.primary_energy_kwh_per_m2) &&
                        <Grid item xs={4}>
                          <DataCard kpi={{
                            name: t('BuildingProfile.kpis.primaryEnergyConsumption'), value: epcInfoAll?.primary_energy_kwh_per_m2, unit: 'kWh'
                          }} />
                        </Grid>
                      }
                      <Grid item xs={4}>
                        <EnergyEfficiencyClass label={t("BuildingProfile.kpis.energyEfficiencyClass")}
                          energyClass={epcInfoAll?.class} className={'min-h-[10rem] w-50 h-full'}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  <BulletChartDoubleWithIntervals
                    className={'my-16 mx-8'}
                    // header={'Title'}
                    // pill="kWh/m²/a"
                    bullets={bullets}
                    thresholds={thresholds}
                    labels={labels}
                    axis={energyAxis}
                    isLoading={false}
                    bulletColorBarClassName='barchart-new-bg-gradient-energy-performance'
                  ></BulletChartDoubleWithIntervals>
                </div>

              }

            </Paper>
          </div>

          <BuildingProfileTable
            className="mt-8 p-6"
            tableClassName={'max-h-[30rem]'}
            isLoading={bFIsLoading}
            buildingFiles={buildingFiles || []}
            projectId={projectId}
          />
          {/* <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={nonEnergyCertificates}
            projectId={projectId}
            isLoading={ceIsLoading}
            title={t("BuildingProfile.certificateCard.certificateTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.carbonButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="certificate"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          />
          <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={energyCertificates}
            isLoading={ceIsLoading}
            projectId={projectId}
            title={t("BuildingProfile.certificateCard.energyRatingTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.energyButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="epc"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          /> */}

        </section>
      </Stack>
      <DynamicDashboardHourlyDataGetter projectId={projectId} enabled={true} />

    </>
  );
};

export const BuildingProfileOld = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { data: dataBuilding, isLoading } = useGetBuilding(projectId);
  const building = dataBuilding || {};


  const crremKPIs = useGetKPIs(projectId)
  const { data: oldKpis, isLoading: kpiIsLoading } = useGetBuildingKpis(projectId);

  const kpis = (crremKPIs.total_carbon_emission !== undefined && crremKPIs.total_energy_consumption !== undefined) ?
    [crremKPIs] : oldKpis

  const kpisLabelsAndKeysMapping = [
    {
      label: t("BuildingProfile.kpis.totalEnergyConsumption"),
      dataKey: "total_energy_consumption",
      unit: "kWh/m²/a",
      round: true
    },
    {
      label: t("BuildingProfile.kpis.totalCarbonEmission"),
      dataKey: "total_carbon_emission",
      unit: "kg/m²/a",
      round: true
    },
    {
      label: t("BuildingProfile.kpis.anticipatedStrandingYear"),
      dataKey: "stranding_year",
      unit: "",
      round: false
    },
  ];

  const heatEnergyConsumption = building?.heat_energy_consumption_epc_kwh_per_square_meter_annum
  const GEGKpis = (isNumber(heatEnergyConsumption)) ? [{ heat_energy_consumption_epc_kwh_per_square_meter_annum: heatEnergyConsumption }] : []

  const GEGKpisLabelsAndKeysMapping = [
    {
      label: t("BuildingProfile.kpis.epcConsumption"),
      dataKey: "heat_energy_consumption_epc_kwh_per_square_meter_annum",
      unit: "kWh/m²/a",
      round: true
    },
  ];


  const { data: buildingFiles, isLoading: bFIsLoading } = useGetBuildingFiles(
    projectId
  );
  const { data, isLoading: ceIsLoading } = useGetBuildingCertificates(
    projectId
  );
  const { energyCertificates, nonEnergyCertificates } = data;

  useEffect(() => {
    trackGAEvent(eventNames.BP_VIEW)
  }, []);

  const assetCardConfig = [
    [
      { label: "ASSET NAME", dataKey: "descriptive_name" },
      { label: "TYPE", dataKey: "type" },
      { label: "ASSET ID", dataKey: "asset_id" },
      { label: "ADDRESS", dataKey: "address" },],
    [
      { label: "YEAR OF CONSTRUCTION", dataKey: "construction_year" },
      { label: "MODERNIZATION YEAR", dataKey: "last_modernized" },
      { label: "GROSS AREA (INCL. BASEMENT)", dataKey: "gross_asset_basement_included", isNumeric: true, renderUnit: (value) => value && ' m²' },
      { label: "Net LETTABLE AREA (WITHOUT BASEMENT)", dataKey: "net_lettable_area_without_basement", isNumeric: true, renderUnit: (value) => value && ' m²' }]
  ];

  return (
    <>
      <Helmet>
        <title>Building profile | Leaftech</title>
      </Helmet>
      <ScrollToTop />
      <Stack spacing={6} className="justify-between items-start">
        {/* Left column */}
        <section className="min-w-[47%] max-w-[55%] overflow-hidden">
          <AssetDetailedCard
            building={building}
            config={assetCardConfig}
            buildingType={building?.type}
            imgPath={building?.image_url_value}
            name={t("BuildingProfile.card.title")}
            isLoading={isLoading}
          ></AssetDetailedCard>
          <BuildingProfileTable
            className="mt-8 p-6"
            isLoading={bFIsLoading}
            buildingFiles={buildingFiles || []}
            projectId={projectId}
          />
        </section>

        {/* Right column */}
        <section className="w-[49%]" >
          <StrandingChart
            className="w-full h-[45.5rem] "
            chartHeight={280}
            legendsWidth="450px"
            projectId={projectId}
            selectedMeaures={[]}
            title={t("BuildingProfile.kpis.crremM2Gross")}
          />
          <KPIs
            kpis={kpis || []}
            className={'mt-4 -mx-3'}
            isLoading={kpiIsLoading}
            paperClassName='!border-0 -p-6'
            // title={t("BuildingProfile.kpis.crremM2Gross")}
            kpisLabelsAndKeysMapping={kpisLabelsAndKeysMapping}
          />
          <KPIs
            className='mt-4'
            kpis={GEGKpis || []}
            energyConsumptionPerM2={building?.energy_consumption ?? kpis?.[0]?.total_energy_consumption}
            isLoading={kpiIsLoading}
            title={t("BuildingProfile.kpis.geg2024LettableArea")}
            kpisLabelsAndKeysMapping={GEGKpisLabelsAndKeysMapping}
          />

          <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={nonEnergyCertificates}
            projectId={projectId}
            isLoading={ceIsLoading}
            title={t("BuildingProfile.certificateCard.certificateTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.carbonButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="certificate"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          />
          <CertificatesCardsArticle
            className="min-h-[13rem] mt-10"
            certificates={energyCertificates}
            isLoading={ceIsLoading}
            projectId={projectId}
            title={t("BuildingProfile.certificateCard.energyRatingTitle")}
            hasButton={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
            buttonLongText={t("Certificates.cards.energyButtonLong")}
            buttonShortText={t("Certificates.cards.buttonShort")}
            certificateType="epc"
            canDownload={IsAllowed("CERTIFICATE.DOWNLOAD_FILES")}
          />

          <DynamicDashboardHourlyDataGetter projectId={projectId} enabled={true} />
        </section>
      </Stack>
    </>
  );
};