export default {
  noInfoAvailable: "There is no information available!",
  nav: {
    "building envelope": "building envelope",
    "energy supply system": "energy supply system",
    other: "other",
    windows: "windows",
    wall: "wall",
    rooftop: "rooftop",
    "shading system": "shading system",
    "heating system": "heating system",
    "hot water": "hot water",
    "cooling system": "cooling system",
    "PV system": "PV system",
    "building automation": "building automation",

    windowsDetails: "windows details",
    wallDetails: "wall details",
    rooftopDetails: "rooftop details",
    "shading systemDetails": "shading system details",
    "heating systemDetails": "heating system details",
    "hot waterDetails": "hot water details",
    "cooling systemDetails": "cooling system details",
    "PV systemDetails": "PV system details",
    "building automationDetails": "building automation details",

    windowsFilesDownload: "Download windows files",
    wallFilesDownload: "Download wall files",
    rooftopFilesDownload: "Download rooftop files",
    "shading systemFilesDownload": "Download shading system files",
    "heating systemFilesDownload": "Download heating system files",
    "hot waterFilesDownload": "Download hot water files",
    "cooling systemFilesDownload": "Download cooling system files",
    "PV systemFilesDownload": "Download PV system files",
    "building automationFilesDownload": "Download building automation files",
  },
  table: {
    title: {},
    columns: {
      Description: "Description",
      Area: "Area",
      Colour: "Colour",
      numberOfWindows: "Number of windows",
      uValue: "U-Value",
      gValue: "G-Value",
      lastChecked: "Last checked",
      windowType: "Window type",
      files: "files",
      Files: "Files",
      Layer: "Layer",
      Thickness: "Thickness",
      Density: "Density",
      "Thermal conductivity": "Thermal conductivity",
      "Heat capacity": "Heat capacity",

      "Created at": "Created at",
      "Updated at": "Updated at",
      Installed: "Installed",
      Id: "Id",
      Modernization: "Modernization",
    },
  },
};
