import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPageName: null,
  currentSubPageName: null,
};

const routeNameSlice = createSlice({
  name: "routeName",
  initialState,
  reducers: {
    setCurrentPageName: (state, { payload }) => {
      state.currentPageName = payload;
    },
    setCurrentSubPageName: (state, { payload }) => {
      state.currentSubPageName = payload;
    },
  },
});

export const {
  setCurrentPageName,
  setCurrentSubPageName,
} = routeNameSlice.actions;
export default routeNameSlice.reducer;
