export const certificateRatingsConf = [
  {
    rating: "bronze",
    bgColor: "var(--clr-tangerine-800)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "gold",
    bgColor: "var(--clr-star-700)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "platinum",
    bgColor: "var(--clr-gray-600)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "a",
    bgColor: "var(--clr-cathode-green-900)",
    textColor: "var(--clr-white)",
  },
];

export const CertificateLabel = ({ certificateClass, type, className }) => {
  const indexInConf = certificateRatingsConf?.findIndex(
    (element) => element.rating === certificateClass?.toLowerCase()
  );
  const bgColor = certificateRatingsConf[indexInConf]?.bgColor;
  const textColor = certificateRatingsConf[indexInConf]?.textColor;
  return (
    <div
      className={className}
      style={{
        color: textColor,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: bgColor,
        borderRadius: "5px",
      }}
    >
      <h4 className="t-label-xs">{type}</h4>
      <p className="t-body-xs sentence-case">{certificateClass}</p>
    </div>
  );
};
