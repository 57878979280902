import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

export const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            className="t-body-xl"
            style={{
                fontSize: "",
            }}
        >
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};


export const PieAndDoughnutChart = ({ containerProps, chartProps, pieProps, data }) => {
    return (
        <ResponsiveContainer width={"100%"} height={225} {...containerProps}>
            <PieChart height={200} margin={0} {...chartProps}>
                <Pie
                    data={data}
                    label={renderCustomizedLabel}
                    labelLine={false}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={110}
                    fill="#82ca9d"
                    {...pieProps}
                >
                    {data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    );
};

export const SunburstChart = ({ containerProps, chartProps, pieProps, doughnutProps }) => {
    return (
        <ResponsiveContainer width={"100%"} height={225} {...containerProps}>
            <PieChart height={200} margin={0} {...chartProps}>
                <Pie
                    label={renderCustomizedLabel}
                    labelLine={false}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={110}
                    fill="#82ca9d"
                    {...pieProps}
                >
                    {pieProps.data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                <Pie
                    label={renderCustomizedLabel}
                    labelLine={false}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={110}
                    fill="#82ca9d"
                    {...doughnutProps}
                >
                    {doughnutProps.data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};