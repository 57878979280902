import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSelectedColumnsThunk } from "./techDataThunk";

export const getSelectedColumns = createAsyncThunk(
  "techData/getSelectedColumns",
  getSelectedColumnsThunk
);

const initialState = {
  parentFiles: [],
  parentIsLoading: true,
  childFiles: [],
  childIsLoading: true,
  activeTab: "",
  colNames: {
    windows_features: [
      "window_type",
      "number_of_windows",
      "area",
      "u_value",
      "g_value",
      "last_checked",
    ],
    rooftop_features: ["description", "area", "last_checked", "files"],
    facade_features: ["description", "area", "last_checked", "files"],
  },
  columnsIsLoading: false,
};

const techDataSlice = createSlice({
  name: "techData",
  initialState,
  reducers: {
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setSelectedColumns: (state, { payload }) => {
      state.colNames = { ...state.colNames, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSelectedColumns.pending, (state) => {
        state.columnsIsLoading = true;
      })
      .addCase(getSelectedColumns.fulfilled, (state, { payload }) => {
        if (payload?.length > 0) state.colNames = payload[0];
        state.columnsIsLoading = false;
      })
      .addCase(getSelectedColumns.rejected, (state, { payload }) => {
        state.columnsIsLoading = false;
      });
  },
});

export const { setActiveTab, setSelectedColumns } = techDataSlice.actions;
export default techDataSlice.reducer;
