export default {
  goals: {
    title: "Modernisierungs-Ziele",
    noGoals: "Es liegen keine Informationen vor!",
  },
  scenarios: {
    noScenarios: "Es liegen keine Informationen vor!",
    title: "Modernisierungsszenarien",
    sectionButton: "Alle Modernisierungsszenarien herunterladen",
    scenario: "Szenario",
    financingButton: "Finanzierungsmöglichkeiten",
    upgrades: "Aufrüstung",
    costBenefit: {
      title: "Kosten-Nutzen-Analyse",
      saving: "Energiekosteneinsparungen",
      period: "Amortisationszeit",
      years: "Jahre",
    },
    impact: {
      sectionTitle: "Auswirkung",
      energyTitle: "Durchschnittlicher Energieverbrauch",
      carbonTitle: "Durchschnittlicher CO₂-Ausstoß",
      statusQuo: "Status Quo",
      postModernization: "Nach Modernisierung",
      saving: "Ersparnisse",
    },
    actionButton: "Bericht herunterladen",
  },
  customization: {
    title: "Konfigurieren Sie Ihr eigenes Modernisierungsszenario",
    selectSentence:
      "Wählen Sie die verschiedenen Maßnahmen aus, die Sie durchführen möchten",
    feedback: "Gibt es etwas, das Sie hinzufügen oder mit uns teilen möchten?",
    formIsBlank: "Das Formular ist leer!",

    windows: "Fenster",
    windowExchange: "Fensterwechsel",
    wall: "Wand",
    wallInsulation: "Wanddämmung",
    shading: "Beschattung",
    smartShading: "Intelligente Beschattung",
    energy: "Strom",
    PVSystem: "PV Anlage",
    actionButton: "Fertig",
  },
};
