import { distinctFilter } from "../utils/dataManipulation";

export const getCrossYear = (data, dataKey) => {
    if (!data?.length > 0) return [];
    const allYears = data?.map((e) => e?.year)?.filter(distinctFilter);
    allYears.sort((a, b) => a - b);
    let crossYears = [];
    for (let index in allYears) {
        index = Number(index);
        const year = allYears[index];
        const nextYear = allYears[index + 1];
        const yearDataItems = data?.filter(
            (e) =>
                e?.year === year && e?.crrem !== undefined && e[dataKey] !== undefined
        );
        const nextYearDataItems = data?.filter(
            (e) =>
                e?.year === nextYear &&
                e?.crrem !== undefined &&
                e[dataKey] !== undefined
        );

        if (!nextYearDataItems.length > 0 || !yearDataItems.length > 0) continue;
        const yearData = yearDataItems[yearDataItems.length - 1];
        const nextYearData = nextYearDataItems[0];
        const diff = yearData.crrem - yearData[dataKey];
        const nextDiff = nextYearData.crrem - nextYearData[dataKey];
        if (diff >= 0 && nextDiff < 0) {
            crossYears.push([year, nextYear]);
        }
    }

    return crossYears;
};
