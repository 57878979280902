import React from "react";
import { useTranslation } from "react-i18next";
import { ResponsiveContainer } from "recharts";
import {
  addUnitBasedValuetoData,
  removeResourcesWithNoData,
} from "../../functions/EnergyPerformance";
import { sumByKeys } from "../../utils/dataManipulation";
import { Loading } from "../Loading";
import {
  LoadingOrEmptyComponent,
  LoadingOrEmptyWrapper,
} from "../LoadingAndEmptyHandler";

const EmptyState = ({ width }) => {
  const { t } = useTranslation();
  return (
    <div className="stack-barchart">
      <ResponsiveContainer width={width} height={225}>
        <div className="h-full flex justify-center items-center">
          <p className="text t-body-xl  ">
            {t("EnergyPerformance.dashboard.noData")}
          </p>
        </div>
      </ResponsiveContainer>
    </div>
  );
};

export const withDashboardChartsPrecalculator = (Component) => {
  const WithDashboardChartsPrecalculator = (props) => {
    const {
      resourcesFirst,
      data,
      resourcesSecond,
      width,
      unit,
      isLoading,
      buildingArea,
    } = props;
    const { t } = useTranslation();

    const allResources = [
      ...(resourcesFirst || []),
      ...(resourcesSecond || []),
    ];
    const isSingleChart = !(resourcesFirst && resourcesSecond);

    const aggregatedData = sumByKeys({
      data,
      keys: allResources?.map((resource) => resource.dataKey),
    });

    const nonNullResourcesFirst = removeResourcesWithNoData(
      data,
      resourcesFirst
    );
    const pieChartDataFirst = addUnitBasedValuetoData(
      nonNullResourcesFirst,
      aggregatedData,
      unit,
      buildingArea
    );

    const nonNullResourcesSecond = removeResourcesWithNoData(
      data,
      resourcesSecond
    );
    const pieChartDataSecond = addUnitBasedValuetoData(
      nonNullResourcesSecond,
      aggregatedData,
      unit,
      buildingArea
    );

    const noData = !(
      nonNullResourcesFirst?.length > 0 || nonNullResourcesSecond?.length > 0
    );
    return (
      <LoadingOrEmptyWrapper
        showLoading={isLoading}
        height="100%"
        showEmpty={noData}
        emptyComponent={() => <EmptyState width={width} />}
      >
        <Component
          props={props}
          isSingleChart={isSingleChart}
          pieChartDataFirst={pieChartDataFirst}
          pieChartDataSecond={pieChartDataSecond}
        />
      </LoadingOrEmptyWrapper>
    );
  };
  return WithDashboardChartsPrecalculator;
};
