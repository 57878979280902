export const chartTypesEnums = Object.freeze({
    BAR: "bar",
    LINE: "line",
    AREA: "area",
    SCATTER: "scatter",
    COMPOSED: "composed",
    HISTOGRAM: "histogram",
    DOUGHNUT: "doughnut",
    PIE: "pie",
    KPI: "kpi",
    HEATMAP: "heatmap",
    MINI_MAP: 'miniMap'
});



export const colorCategoriesEnums = Object.freeze({
    COLUMNS: 'Columns',
    VALUES: 'Values',
    MIXED: 'Mixed',
})


export const defaultColors = Object.freeze({
    [colorCategoriesEnums.COLUMNS]: {
    },
    [colorCategoriesEnums.VALUES]: {

    }
})


export const valuesEnums = Object.freeze({
    NO_DATA: 'No Data',
    INDEX: 'Index',
    COUNT: 'Count',
    DIAGRAM_FILTER_PREFIX: 'diagramFilter_',
})


export const diagramTypes = Object.freeze({
    BAR: "bar",
    LINE: "line",
    SCATTER: "scatter",
});


export const diagramTypesDisplayOptions = Object.freeze({
    BAR: "Bar",
    LINE: "Line",
    SCATTER: "Scatter",
});

export const getSortOptions = ({ chartType }) => {
    let options = [], displayOptions = []
    options = ['x', 'y']
    displayOptions = ['X Axis', 'Y Axis']
    if (chartType === chartTypesEnums.LINE) {
        options = ['x', 'y', "All values"]
        displayOptions = ['X Axis', 'Y Axis', "All values"]
    }
    return { options, displayOptions }
}