import React from "react";

const LegendBullet = ({ width, height, type, color }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        width: width,
        height: height,
        borderRadius: type === "circle" ? "50%" : "0",
      }}
    ></div>
  );
};

export default LegendBullet;
