import {
  useGetTranslations,
  useModifyTranslation,
} from "../api/hooks/allHooks";
import {
  ButtonNew,
  Checkbox,
  CustomModal,
  Icon,
  Input,
  Loading,
  Table,
  TableWithHeader,
} from "../components";
import { LabelLessInput } from "../components/Input";
import { useEffect, useRef, useState } from "react";
import { Paper, Stack } from "@mui/material";
import { postTranslation } from "../api/functions/all";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";

const headers = [
  {
    label: "word",
    dataKey: "word",
    cellType: null,
    valueTransformation: (row) => row["word"],
    subValueTransformation: (row) => null,
    downloadZipFile: (row) => null,
    href: (row) => null,
    isReadOnly: true,
  },
  {
    label: "translation",
    dataKey: "translation",
    cellType: null,
    valueTransformation: (row) => row["translation"],
    subValueTransformation: (row) => null,
    downloadZipFile: (row) => null,
    href: (row) => null,
  },
];

export const Administration = () => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const word = useRef(null);
  const translation = useRef(null);

  const { data, isLoading } = useGetTranslations();

  const translations = Object.keys(data || {}).map((word, index) => {
    return { word, translation: data[word] };
  });

  const { mutate } = useModifyTranslation();

  const handleBlur = (parentRowId, rowId, fieldName, fieldValue) => {
    mutate({ method: "patch", data: { [rowId]: fieldValue } });
  };

  const hanleSubmit = (e) => {
    e.preventDefault();
    const method = "post";
    const data = { [word.current?.value]: translation.current?.value };
    const resp = postTranslation({ method, data });
    resp
      .then(() => {
        queryClient.invalidateQueries("getTranslations");
        toast.success("Successfully added.");
        if (word.current?.value) word.current.value = "";
        if (translation.current?.value) translation.current.value = "";
      })
      .catch((error) => {
        toast.error(JSON.stringify(error?.response?.data));
      });
  };

  const filterTranslations = () => {
    const filtered = translations?.filter((row) => {
      if (filter === "all") return true;
      return filter === "translated" ? !!row.translation : !row.translation;
    });
    return filtered;
  };
  useEffect(() => {
    setModalOpen(i18n.language === "de");
  }, [i18n.language]);

  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="90vh">
      <div>
        <form>
          <Paper>
            <h2 className="t-heading-l mb-4">Add new translation</h2>
            <Stack className="justify-between">
              <ButtonNew
                onClick={hanleSubmit}
                size="sm"
                variant="primary"
                width="6%"
              >
                <Icon iconName="Add" stroke="white" fill="white" />
                <p> Add </p>
              </ButtonNew>
              <Input
                inputRef={word}
                placeholder="Expression"
                label="English"
                className=""
                type="text"
                height="4rem"
                width="46%"
              />
              <Input
                inputRef={translation}
                placeholder="Translation"
                label="German"
                className=""
                type="text"
                height="4rem"
                width="46%"
              />
            </Stack>
          </Paper>
        </form>
        <Paper className="mt-8">
          <Stack className="justify-between items-start">
            <h2 className="t-heading-l mb-4">All translations</h2>
            <Stack gap="3.2rem" className="items-center">
              <Checkbox
                checked={filter === "all"}
                onChange={(e) => setFilter("all")}
              >
                <span className="ml-2 inline-block">All</span>
              </Checkbox>
              <Checkbox
                checked={filter === "translated"}
                onChange={(e) => setFilter("translated")}
              >
                <span className="ml-2 inline-block">Translated</span>
              </Checkbox>
              <Checkbox
                checked={filter === "not translated"}
                onChange={(e) => setFilter("not translated")}
              >
                <span className="ml-2 inline-block">Not Translated</span>
              </Checkbox>
            </Stack>
          </Stack>
          <Table
            data={filterTranslations(translations)}
            headers={headers}
            editable={true}
            rowRemoveable={false}
            idColumnName="word"
            onBlur={handleBlur}
            className="mt-8  h-100"
          />
        </Paper>
        <CustomModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          height="21.6rem"
          className="relative"
        >
          <p className="t-heading-m text-gray-800">
            Please change language to EN to see the words correctly.
          </p>
          <ButtonNew
            variant="primary"
            onClick={() => i18next.changeLanguage("en")}
            size="md"
            type="button"
            className="mt-8 capitalize absolute left-1/2"
            style={{ transform: "translateX(-50%)" }}
          >
            Change language
          </ButtonNew>
        </CustomModal>
      </div>
    </LoadingOrEmptyWrapper>
  );
};
