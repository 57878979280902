import React from "react";


export const LabelValueInline = ({ label, value, className, labelProps, valueProps }) => {
  return (
    <span className={`mb-1 t-body-s ${className}`}>
      <span className="text-gray-600" {...labelProps}>
        {label}:
      </span>
      <span className="inline-block ml-1" {...valueProps}>
        {` ${value}`}
      </span>
    </span>

  );
};
