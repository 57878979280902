import { Paper, Stack } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";

function GeneralFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <Paper className="w-full h-full">
      <Stack className="h-full justify-center items-center">
        <p>Something went wrong!</p>
      </Stack>
      {/* <pre>{error.message}</pre> */}
      {/* <button onClick={resetErrorBoundary}>Try again</button> */}
    </Paper>
  );
}

function TextFallbackComponent() {
  return (
    <Stack className="h-full justify-center items-center">
      <p>Something went wrong!</p>
    </Stack>
  );
}


export const GeneralErrorBoundary = ({ children, resetKeys }) => {
  return (
    <ErrorBoundary
      FallbackComponent={GeneralFallbackComponent}
      // onReset={() => {
      // reset the state of your app here
      // }}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
};

export const EmptyErrorBoundary = ({ children, resetKeys }) => {
  return (
    <ErrorBoundary
      // onReset={() => {
      // reset the state of your app here
      // }}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  );
};


export const TextErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={TextFallbackComponent}
    // onReset={() => {
    // reset the state of your app here
    // }}
    // resetKeys={['someKey']}
    >
      {children}
    </ErrorBoundary>
  );
};
