// TODO:
// 1-make breadcrumbs dynamic

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { useGetBuilding } from "../api/hooks/allHooks";
import { Footer } from "../components/Footer";
import Header from "../components/Header";
import { Navbar } from "../components/Navbar";
export const SharedLayout = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();

  const { currentPageName } = useSelector((store) => store.routeName);
  const { data: building } = useGetBuilding(projectId);
  const showBuildingName = currentPageName !== "asset overview";
  const breadcrumbs = [
    { name: "level1", url: "/", text: t("SharedLayout.header.assets") },
    {
      name: "level2",
      url: showBuildingName ? `details/${projectId}/buildingProfile` : null,
      text: showBuildingName ? building?.name : null,
    },
  ];

  return (
    <main className="pb-2">
      <Navbar className="fixed w-16 h-full  "></Navbar>
      <div className=" min-h-99vh flex flex-col justify-between  ">
        <Header className="px-8 ml-16 " breadcrumbs={breadcrumbs} />
        <section className="px-8 ml-16">
          <main className="pb-12 m-auto max-w-screen-2xl">
            <Outlet></Outlet>
          </main>
        </section>
        <Footer className="mt-auto ml-24 mr-8"></Footer>
      </div>
    </main>
  );
};
