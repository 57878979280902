import { useMemo } from "react";
import { useGetDashboardNew, useGetProjectInfo } from "../../api/hooks/allHooks";

export const DynamicDashboardHourlyDataGetter = ({ projectId, enabled }) => {
  const {
    data: projectInfo,
    isLoading: pIIsLoading,
    isSuccess,
  } = useGetProjectInfo(projectId, {});

  let scenarios = [],
    dataPoints = [];

  if (isSuccess) {
    try {
      scenarios = JSON.parse(projectInfo[0].room_level_scenarios);
      dataPoints = JSON.parse(projectInfo[0].metrics);
    } catch { }
  }

  const hourlyInputs = {
    quantities: dataPoints?.filter((e) => e.level === "building")
      ?.map((e) => e.dataKey),
    scenarios,
    projectId,
    startDate: new Date("2000-01-01"),
    endDate: new Date("2100-01-01"),
    dataLevel: 'building',
    interval: 'hourly',
    enabled: enabled,
  };
  useGetDashboardNew(hourlyInputs);

}
