import { FormLabel, Paper } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "rsuite/dist/rsuite-no-reset.min.css";
import { setConfig } from "../../features/energyPerformance/energyPerformanceSlice";
import { CheckboxesGroup } from "../CheckboxGroup";
import { DateRangePickerNew } from "../DateRangePickerNew";
import { ConfigFilter } from "./ConfigFilter";
import { useParams } from "react-router-dom";
import { getOldProjectId } from "../../utils/hardcoding";

const unitsOptions = {
  consumption: ["kWh", "kWh/m²"],
  price: ["€", "€/m²"],
  emission: ["KgCO₂", "KgCO₂/m²"],
};

export const DashboardFilters = ({
  className,
  minDate,
  maxDate,
  quantitiesConfig,
  resources,
}) => {
  const dispatch = useDispatch();
  const { config } = useSelector((store) => store.energyPerformance);

  const resourcesDisplayOptions = resources.map(
    (resource) => resource.legendLabel
  );
  const { projectId: newProjectId } = useParams();
  const projectId = getOldProjectId(newProjectId)

  const newProject =
    Number(projectId) == 13 ||
    Number(projectId) == 16 ||
    Number(projectId) == 19 ||
    Number(projectId) == 20 ||
    Number(projectId) == 21;
  const bobHannover = Number(projectId) == 17;
  const bobHam = Number(projectId) == 18;
  const vahingen = Number(projectId) == 21;
  const helvesanger10 = Number(projectId) == 26;
  const springenhof = Number(projectId) == 50;
  const klinik = Number(projectId) == 55;

  const handleConfigChange = (key, value) => {
    dispatch(setConfig({ key, value }));
  };

  const quantitiesOptions = quantitiesConfig?.map((quantity) => quantity.name);
  const quantitiesDisplayOptions = quantitiesConfig?.map((quantity) => {
    return !newProject
      ? i18next.t(`EnergyPerformance.dashboard.${quantity.displayLabel}`)
      : quantity.displayLabel;
  });

  useEffect(() => {
    const newDates = [new Date(minDate), new Date(maxDate)];
    const newDatesHourly = [new Date(minDate), new Date(minDate)];
    handleConfigChange("dates", newDates);
    handleConfigChange("hourlyDates", newDatesHourly);
  }, [minDate, maxDate]);

  return (
    <Paper className={`p-2 pr-3 pt-4 ${className}`}>
      <FormLabel className="t-heading-s-700 ml-2">Date interval</FormLabel>
      <DateRangePickerNew
        className="w-full mt-1 ml-1 "
        appearance="default"
        value={
          config.timeframe === "hourly" ? config.hourlyDates : config.dates
        }
        onChange={(value) =>
          handleConfigChange(
            config.timeframe === "hourly" ? "hourlyDates" : "dates",
            value
          )
        }
        cleanable={false}
        oneTap={config.timeframe === "hourly"}
        showOneCalendar={config.timeframe === "hourly"}
        minDate={config.dates[0]}
        maxDate={config.dates[1]}
      />
      <ul className="w-full mt-3 ">
        <li className="mb-1 border-b-gray-100 border-t-gray-100">
          <ConfigFilter
            label="data point"
            displayOptions={quantitiesDisplayOptions}
            options={quantitiesOptions}
            value={config.quantity}
            setValue={(value) => handleConfigChange("quantity", value)}
          />
        </li>
        <li className="mb-1  border-b-gray-100">
          <ConfigFilter
            label="timeframe"
            displayOptions={["Monthly", "Daily", "Hourly"]}
            options={["monthly", "daily", "hourly"]}
            value={config.timeframe}
            setValue={(value) => handleConfigChange("timeframe", value)}
          />
        </li>
        <li className="mb-1 border-b-gray-100">
          <CheckboxesGroup
            label="Quantity"
            displayOptions={resourcesDisplayOptions}
            options={
              bobHam || helvesanger10 || bobHannover
                ? config.bobResourcesOptions
                : springenhof
                  ? config.springhofResourcesOptions
                  : config.resourcesOptions
            }
            values={config.selectedResourcesOptions}
            setValues={(value) =>
              handleConfigChange("selectedResourcesOptions", value)
            }
          />
        </li>
        <li className="mb-1 border-b-gray-100">
          <CheckboxesGroup
            label={
              helvesanger10 || springenhof || bobHannover
                ? "Scenarios"
                : !newProject
                  ? "measurement type"
                  : "Simulated Data Sets"
            }
            displayOptions={
              helvesanger10 || springenhof
                ? ["Always down 0%", "No shading 100%"]
                : bobHannover
                  ? ["60% occupancy", "60% occupancy 3h avg"]
                  : bobHam
                    ? ["Occupancy 0%", "Occupancy 100%"]
                    : klinik
                      ? ['Status quo', 'GEG',]
                      : !newProject
                        ? ["Simulated", "Measured"]
                        : vahingen
                          ? ["Energy Demand (Simulated)", "Energy Consumption (Measured)"]
                          : ["Energy Consumption", "Energy Demand"]
            }
            options={["simulated", "measured"]}
            values={config.measureType}
            setValues={(value) => handleConfigChange("measureType", value)}
          />
        </li>
        <li>
          <ConfigFilter
            label="unit"
            displayOptions={unitsOptions[config.quantity]}
            options={unitsOptions[config.quantity]}
            value={config.allUnits[config.quantity]}
            setValue={(newValue) =>
              handleConfigChange("allUnits", {
                ...config.allUnits,
                [config.quantity]: newValue,
              })
            }
          />
        </li>
      </ul>
    </Paper>
  );
};
