import { Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getUserRoleId } from "../../acl";
import {
  useAddMeasure,
  useAddSelectedMeasure,
  useDeleteMeasure,
  useDeleteSelectedMeasure,
  useGetAllMeasures,
  useGetAllSelectedMeasures,
  useGetBuildingKpis,
  useModifyMeasure,
  useModifySelectedMeasure,
} from "../../api/hooks/allHooks";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { addCommaToNumber } from "../../utils/namesManipulation";
import { CustomModal } from "../CustomModal";
import { DropdownSingleSelectNative } from "../DropdownSingleSelectNative";
import { Icon } from "../Icon";
import { Table } from "../Table";
import { StrandingChart } from "./StrandingChart";
import { eventNames, trackGAEvent, withGAEventTracking } from "../../functions/googleAnalytics";
import { Dropdown } from "../Dropdown";
import { DropdownSingleSelect } from "../DropdownSingleSelect";
import { isNumber } from "lodash";
// TODOs:
// 1-Better choice of dropdown and oneTime dropdown
// 2-modularize
// 3-new row a part of main table
// 4-new row isloading state
// 5-selected retrofits hover state vice versa
// 7-empty new row onBlur
// 8-selected retrofits row another color
// 9-invalidate selected retro by change in all retro
// 10-userbased remove/modify all retros
// 11-Dropdown full width

const TimelineItemOld = ({ title, desc, budget, year, icon }) => {
  return (
    <Paper className={` h-35 w-60 p-4`}>
      <p className="t-body-xl short-text">{title}</p>
      <p className="t-body-s short-text mt-1">{desc}</p>
      <p className="t-numbers-xl text-plunge-800 mt-4">
        {year} <span className="t-numbers-m-reg">Year</span>
      </p>
      <p className="t-body-xs mt-3">€{addCommaToNumber(Number(budget))} </p>
    </Paper>
  );
};

const TimelineItem = ({
  title,
  desc,
  budget,
  onDelete,
  options,
  displayOptions,
  onChange,
  retrofit,
  retrofitOptions,
  retrofitDisplayOptions,
  DetailsButton,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <Paper
      className={` h-37 w-60 p-4  pt-5 relative`}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      {showDelete && (
        <div
          style={{
            borderRadius: "50%",
            padding: "5px",
            backgroundColor: "red",
          }}
          className="absolute block top-1 right-1/2 translate-x-1/2  cursor-pointer"
          onClick={onDelete}
        >
          <Icon iconName="Close" color="white" />
        </div>
      )}
      <DropdownSingleSelectNative
        className="t-body-xl mt-4"
        placeholder="Select Retrofit measure"
        options={retrofitOptions}
        displayOptions={retrofitDisplayOptions}
        selectedValue={retrofit?.name}
        setSelectedValue={() => { }}
        onChange={(e) => onChange({ fieldName: "name", value: e.target.value })}
        width="60px"
      />
      <DropdownSingleSelectNative
        className="t-numbers-xl text-plunge-800 mt-2"
        placeholder="Select year"
        options={options}
        displayOptions={displayOptions}
        selectedValue={retrofit?.year}
        setSelectedValue={() => { }}
        onChange={(e) => onChange({ fieldName: "year", value: e.target.value })}
        width="60px"
      />
      <p className="t-body-xs mt-3">€{addCommaToNumber(Number(budget))} </p>
      <DetailsButton
        className="absolute right-3 bottom-3 t-heading-m"
        row={retrofit}
      />
    </Paper>
  );
};

const NewTimelineItem = ({
  onChange,
  retrofitOptions,
  retrofitDisplayOptions,
}) => {
  return (
    <Paper className={` h-35 w-60 p-4  relative flex items-center`}>
      <DropdownSingleSelectNative
        className="t-body-xl"
        placeholder="Select Retrofit measure"
        options={retrofitOptions}
        displayOptions={retrofitDisplayOptions}
        selectedValue={""}
        setSelectedValue={() => { }}
        onChange={(e) => onChange({ value: e.target.value })}
        width="60px"
      />
    </Paper>
  );
};

const AddNewTimeLineItem = ({ className, onClick }) => {
  return (
    <Paper className="h-35 w-60 bg-gray-100 cursor-pointer" onClick={onClick}>
      <Stack className={`h-full justify-center items-center  ${className}`}>
        <Icon
          iconName="Add"
          size="lg"
          style={{
            transform: "scale(2)",
          }}
        />
      </Stack>
    </Paper>
  );
};

export const Wizard = ({ projectId }) => {
  const { t } = useTranslation();
  const user = getUserFromLocalStorage();

  const { data: measures } = useGetAllMeasures(projectId);
  const { data: selectedMeaures } = useGetAllSelectedMeasures(projectId);
  const [dataYears, setDataYears] = useState([]);

  ///////////// All retrofit measures table
  const [scrollDown, setScrollDown] = useState(false);

  const isInputInData = (input, data, dataKey) => {
    if (!input || !data?.length > 0) return false;
    for (const row of data) {
      if (row[dataKey] === input) return true;
    }
    return false;
  };

  const DetailsButton = ({ row, className }) => {
    const correspondingRetrofit = measures?.find(
      (measure) => measure?.name === row?.name
    );
    const pdfUrl = correspondingRetrofit?.pdf_url;
    const styleClass = pdfUrl
      ? "cursor-pointer text-[#00b0f0]"
      : "cursor-text text-[#ccc]";
    let content = {};
    if (correspondingRetrofit) {
      for (const key of Object.keys(mapping)) {
        content[key] = correspondingRetrofit[mapping[key]];
      }
    }
    return (
      <a
        className={` ${styleClass} ${className}`}
        onClick={() => {
          setModalContent(content);
          trackGAEvent(eventNames.MODERN_RETROFIT_DETAILS)
          // if (pdfUrl) setShowModal(true);
        }}
        target="_blank"
        rel="noreferrer"
        href={pdfUrl}
      >
        Details
      </a>
    );
  };

  // const [v, setV] = useState('')
  //   {
  //     "utility_cost_reduction": null,
  //     "carbon_dioxide_reduction": 10.0,
  //     "embodied_carbon": null,
  // }

  const componentAndSubcomponentOptions = {
    'Building Envelope':
      ['Window', 'Ceiling', 'Facade', 'Doors', 'Floor', 'Other']
    ,
    'Building Technology':
      ['Luminance', 'Ventilation', 'Heat Distribution System', 'Cooling System', 'Building Management System', 'Building Automation System', 'Other']
    ,
    'Energy System':
      ['Central Heating System', 'Photovoltaic', 'Renewable Energy', 'Central Cooling Systems', 'Other']
    ,
    'Services':
      ['Data Services', 'Consulting', 'Cleaning', 'Other']
    ,
    'Other':
      ['Other']
  }

  const componentOptions = Object.keys(componentAndSubcomponentOptions)
  const getSubCopmponentOptions = (component) => componentAndSubcomponentOptions[component] ?? []

  const retrofitTypeOptions = ['Optimization', 'Replacement', 'Modernization', 'Addition', 'Other']

  const headers = [
    {
      label: "Name",
      placeholder: "+ Add retrofit measure",
      dataKey: "name",
      cellType: null,
      valueTransformation: (row) => row["name"],
      subValueTransformation: (row) => null,
      errorTransformation: (row, data, input) => {
        const rowId = row.id;
        const correspondingRow = data?.find((row) => row.id === rowId);
        if (!input) return "Can not be blank!";
        if (correspondingRow) if (correspondingRow?.name === input) return "";
        return isInputInData(input, data, "name")
          ? "Select another name, it’s already selected."
          : "";
      },
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => row["user_created"],
    },
    {
      label: "Component",
      dataKey: "component",
      cellType: 'dropdown',
      valueTransformation: (row) => row["component"],
      subValueTransformation: (row) => null,
      options: (row) => componentOptions,
      placeholder: 'Component',
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => true,
    },
    {
      label: "Sub-Component",
      dataKey: "sub_component",
      cellType: 'dropdown',
      valueTransformation: (row) => row["sub_component"],
      subValueTransformation: (row) => null,
      options: (row) => getSubCopmponentOptions(row['component']),
      placeholder: 'Sub-Component',
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => true,
    },
    {
      label: "Type of Measure",
      dataKey: "type_of_measure",
      cellType: 'dropdown',
      valueTransformation: (row) => row["type_of_measure"],
      subValueTransformation: (row) => null,
      options: (row) => retrofitTypeOptions,
      placeholder: 'Type of Measure',
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => true,
    },
    {
      label: "Reduction in Electricity Demand    [kWh/m²]",
      dataKey: "electricity_reduction",
      cellType: null,
      contentType: 'number',
      headerClassName: '!normal-case	',
      valueTransformation: (row) => row["electricity_reduction"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => row["user_created"],
    },
    {
      label: "Reduction in Heat Rnergy Demand    [kWh/m²]",
      dataKey: "energy_reduction",
      cellType: null,
      contentType: 'number',
      headerClassName: '!normal-case	',
      valueTransformation: (row) => row["energy_reduction"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => row["user_created"],
    },
    {
      label: "Reduction in Energy Demand    [kWh/m²]",
      dataKey: null,
      cellType: null,
      headerClassName: '!normal-case	',
      valueTransformation: (row) => (!isNumber(row?.electricity_reduction) && !isNumber(row?.energy_reduction))
        ? row?.embodied_carbon
        : (Number(row?.electricity_reduction) + Number(row?.energy_reduction)),
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => false,
      isReadOnly: true,
    },
    {
      label: "Investment costs          [€/m²]",
      dataKey: "investments",
      cellType: null,
      headerClassName: '!normal-case	',
      valueTransformation: (row) => row["investments"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => row["user_created"],
    },
    {
      label: "Operating costs        [€/m²/a]",
      dataKey: "services_cost",
      cellType: null,
      headerClassName: '!normal-case	',
      valueTransformation: (row) => row["services_cost"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => row["user_created"],
    },
    {
      label: "Cost Savings [a]",
      dataKey: "cost_savings",
      cellType: null,
      headerClassName: '!normal-case	',
      valueTransformation: (row) => row["cost_savings"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => false,
      isReadOnly: true,
    },
    {
      label: "CO₂ emission reduction    [kg/m²/a]",
      dataKey: "carbon_dioxide_savings",
      cellType: null,
      headerClassName: '!normal-case	',
      valueTransformation: (row) => row["carbon_dioxide_savings"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => false,
      isReadOnly: true,
    },
    {
      label: "ROI [a]",
      dataKey: "roi",
      cellType: null,
      headerClassName: '!normal-case	',
      valueTransformation: (row) => row["roi"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      editable: (row) => false,
      isReadOnly: true,
    },


    // {
    //   label: "CO₂ emission reduction    [kgCO₂/m²/year]",
    //   dataKey: "carbon_dioxide_reduction",
    //   cellType: null,
    //   valueTransformation: (row) => row["carbon_dioxide_reduction"],
    //   subValueTransformation: (row) => null,
    //   downloadZipFile: (row) => null,
    //   href: (row) => null,
    //   isInDefault: true,
    //   editable: (row) => row["user_created"],
    // },
    // {
    //   label: "Energy demand reduction       [kWh/m²/year]",
    //   dataKey: "embodied_carbon",
    //   cellType: null,
    //   valueTransformation: (row) => row["embodied_carbon"],
    //   subValueTransformation: (row) => null,
    //   downloadZipFile: (row) => null,
    //   href: (row) => null,
    //   isInDefault: true,
    //   editable: (row) => row["user_created"],
    // },
    // {
    //   label: "Cost reduction   [€/m²/year]",
    //   dataKey: "utility_cost_reduction",
    //   cellType: null,
    //   valueTransformation: (row) => row["utility_cost_reduction"],
    //   subValueTransformation: (row) => null,
    //   downloadZipFile: (row) => null,
    //   href: (row) => null,
    //   isInDefault: true,
    //   editable: (row) => row["user_created"],
    // },

    {
      label: "Additional Information",
      dataKey: "",
      cellType: "downloadZipFile",
      headerClassName: '!normal-case	',
      valueTransformation: (row) => <DetailsButton row={row} />,
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
      isReadOnly: true,
    },
  ];

  const queryClient = useQueryClient();
  const [measuresTable, setMeasuresTable] = useState([]);
  useEffect(() => {
    setMeasuresTable(measures);
  }, [measures]);

  const { mutate: addMeasure } = useAddMeasure();

  const handleNewRowBlur = ({ fieldName, value }) => {
    if (!value) return;
    addMeasure({
      projectId,
      data: { [fieldName]: value, user: user?.user_id, project: projectId },
      onSuccess: () => {
        setScrollDown(true);
      },
      onSettled: () => {
        setTimeout(() => {
          setScrollDown(false);
        }, 500);
      },
    });
  };

  const { mutate: deleteMeasure } = useDeleteMeasure();
  const handleDelete = ({ rowId }) => {
    deleteMeasure({ projectId, rowId });
  };

  const { mutate: modifyMeasure } = useModifyMeasure();
  const handleBlur = ({ rowId, fieldName, value }) => {
    modifyMeasure({
      rowId,
      projectId,
      data: { [fieldName]: value },
    });
  };

  // ==================================
  // Selected Retrofit measures
  // ==================================
  const [showModal, setShowModal] = useState(false);
  const mapping = {
    upgrades: null,
    pdf_url_value: "pdf_url",
    scenario_financing_label: null,
    capex: "investments",
    energy_cost_saving: "services_cost",
    payback_period: "roi",
    avg_energy_consumption_status_quo: null,
    avg_energy_consumption_post_modernization: null,
    avg_energy_consumption_saving: "utility_cost_reduction",
    avg_carbon_dioxide_emission_status_qo: null,
    avg_carbon_dioxide_emission_saving: "carbon_dioxide_reduction",
    avg_carbon_dioxide_emission_post_modernization: null,
    scenario_number: "name",
  };

  const [modalContent, setModalContent] = useState({});

  const measureOptions = () => {
    if (!measures?.length > 0 && !selectedMeaures?.length > 0) return [];
    const allMeasuresNames = measures?.map((row) => row.name);
    const selectedMeasuresNames = selectedMeaures?.map((row) => row.name);
    const availableOptions = allMeasuresNames?.filter(
      (m) => !selectedMeasuresNames?.includes(m)
    );
    return availableOptions;
  };

  // const selectedMeasuresHeaders = [
  //   {
  //     label: "Retrofit measure",
  //     placeholder: "Select retrofit measure",
  //     dataKey: "name",
  //     cellType: "dropdown",
  //     options: measureOptions() || [],
  //     displayOptions: measureOptions() || [],
  //     valueTransformation: (row) => row["name"],
  //     subValueTransformation: (row) => null,
  //     downloadZipFile: (row) => null,
  //     href: (row) => null,
  //     isInDefault: true,
  //   },
  //   {
  //     label: "Year",
  //     placeholder: "Select year",
  //     dataKey: "year",
  //     cellType: "dropdown",
  //     options: dataYears,
  //     displayOptions: dataYears,
  //     valueTransformation: (row) => row["year"],
  //     subValueTransformation: (row) => null,
  //     downloadZipFile: (row) => null,
  //     href: (row) => null,
  //     isInDefault: true,
  //   },
  //   {
  //     label: "KPI",
  //     dataKey: "",
  //     cellType: "downloadZipFile",
  //     valueTransformation: (row) => {
  //       const correspondingRetrofit = measures?.find(
  //         (measure) => measure.name === row.name
  //       );
  //       let content = {};
  //       if (correspondingRetrofit)
  //         for (const key of Object.keys(mapping)) {
  //           content[key] = correspondingRetrofit[mapping[key]];
  //         }
  //       return (
  //         <p
  //           className="text-blue-500 cursor-pointer"
  //           onClick={() => {
  //             setModalContent(content);
  //             setShowModal(true);
  //           }}
  //         >
  //           Show KPI
  //         </p>
  //       );
  //     },
  //     subValueTransformation: (row) => null,
  //     downloadZipFile: (row) => null,
  //     href: (row) => null,
  //     isInDefault: true,
  //     isReadOnly: true,
  //   },
  // ];

  // const selectedMeasuresHeadersNewRow = [
  //   {
  //     label: "Retrofit measure",
  //     placeholder: "Select retrofit measure",
  //     dataKey: "name",
  //     cellType: "dropdownOneTime",
  //     options: measureOptions() || [],
  //     displayOptions: measureOptions() || [],
  //     valueTransformation: (row) => row["name"],
  //     subValueTransformation: (row) => null,
  //     downloadZipFile: (row) => null,
  //     href: (row) => null,
  //     isInDefault: true,
  //   },
  // ];

  const [isAddingNewRow, setIsAddingNewRow] = useState(false);
  const [selectedMeauresTable, setSelectedMeauresTable] = useState([]);
  useEffect(() => {
    setSelectedMeauresTable(selectedMeaures);
  }, [selectedMeaures]);

  const { mutate: addSelectedMeasure } = useAddSelectedMeasure();
  const handleNewSelectedRowBlur = ({ fieldName, value }) => {
    addSelectedMeasure({
      projectId,
      data: { [fieldName]: value, project: projectId, user: user?.user_id },
    });
  };

  const { mutate: deleteSelectedMeasure } = useDeleteSelectedMeasure();
  const handleDeleteSelectedMeasure = ({ rowId }) => {
    deleteSelectedMeasure({ projectId, rowId });
  };

  const { mutate: modifySelectedMeasure } = useModifySelectedMeasure();
  const handleSelectedMeasureBlur = ({ rowId, fieldName, value }) => {
    modifySelectedMeasure({
      rowId,
      projectId,
      data: { [fieldName]: value },
    });
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "white" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, color: "black" }}
        onClick={onClick}
      // style={style}
      >
        <p style={{ color: "black", display: "block", height: "20px" }}>pre</p>
      </div>
    );
  }

  const settings = {
    className: "center",
    infinite: false,
    draggable: true,
    centerMode: true,
    initialSlide: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const sortTimeline = (data) => {
    if (!data?.length > 0) return data;
    const dataCopy = [...data];
    dataCopy.sort((a, b) => {
      if (a.year === null || a.year === undefined) return Infinity;
      if (b.year === null || b.year === undefined) return -Infinity;
      return a.year - b.year;
    });
    return dataCopy;
  };
  const currentUserRoleId = getUserRoleId();
  return (
    <section className=" my-8 w-full ">
      <CustomModal
        modalOpen={showModal}
        setModalOpen={setShowModal}
        width="80vw"
        height="95vh"
        className="overflow-scroll max-h-[90rem]"
      >
        {/* <SingleScenario scenario={modalContent} className="text-left" /> */}
        <div className="w-full h-full">
          {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={s} />;
          </Worker> */}
          {/* <iframe src={modalContent.pdf_url_value} width="50%" height="500px" /> */}
          {/* <iframe src={pd} width="50%" height="500px" /> */}
          {/* <embed
            type="application/pdf"
            // src="http://africau.edu/images/default/sample.pdf"
            src={modalContent.pdf_url_value}
          /> */}
          {/* <object
            data={modalContent.pdf_url_value}
            type="application/pdf"
            width="100%"
            height="500px"
          ></object> */}
          <object
            // data="2024_Ventilation_Optimization.pdf"
            // data={modalContent.pdf_url_value}
            data={modalContent.pdf_url_value}
            type="application/pdf"
            className="w-full h-full"
          // width="100%"
          // height="100%"
          ></object>
          {/* <Document file={src}>
            <Page pageNumber={1} />
          </Document> */}
        </div>
      </CustomModal>

      <StrandingChart
        className="w-full"
        projectId={projectId}
        selectedMeaures={selectedMeaures}
        title={'Building Energy Performance'}
        // kpis={kpis}
        // kpiIsLoading={kpiIsLoading}
        onCrremChange={(crremData) => {
          setDataYears(crremData?.map((e) => e.year) || []);
        }}
      />

      {/* TIMELINE */}
      <section className="mt-4 ">
        <Paper style={{ backgroundColor: "transparent", border: "none" }}>
          <h2 className="capitalize t-heading-l ">
            Configurate your Retrofit Measures
          </h2>
          <div className="mx-16">
            <Slider {...settings}>
              {sortTimeline(selectedMeauresTable)?.map((retrofit, index) => {
                const correspondingRetrofit = measures?.find(
                  (measure) => measure.name === retrofit.name
                );
                if (correspondingRetrofit)
                  return (
                    <div className="p-2">
                      <TimelineItem
                        title={"Implement Energy Efficiency Measures"}
                        desc={"Upgrade insulation, lighting, etc."}
                        budget={correspondingRetrofit.investments}
                        retrofit={retrofit}
                        options={dataYears}
                        displayOptions={dataYears}
                        retrofitOptions={measureOptions() || []}
                        retrofitDisplayOptions={measureOptions() || []}
                        key={index}
                        onDelete={() => {
                          withGAEventTracking(eventNames.MODERN_CARD_DELETE_RETROFIT, {},
                            () => handleDeleteSelectedMeasure({ rowId: retrofit.id })
                          )
                        }}
                        onChange={({ fieldName, value }) => {
                          withGAEventTracking(eventNames.MODERN_CARD_UPDATE_RETROFIT, {},
                            () => handleSelectedMeasureBlur({
                              rowId: retrofit?.id,
                              fieldName,
                              value,
                            }))

                        }
                        }
                        DetailsButton={DetailsButton}
                      />
                    </div>
                  );
              })}
              {!isAddingNewRow && (
                <div className="p-2">
                  <AddNewTimeLineItem onClick={() => {
                    setIsAddingNewRow(true)
                    trackGAEvent(eventNames.MODERN_CARD_ADD_RETROFIT)
                  }} />
                </div>
              )}
              {isAddingNewRow && (
                <div className="p-2">
                  <NewTimelineItem
                    retrofitOptions={measureOptions() || []}
                    retrofitDisplayOptions={measureOptions() || []}
                    onChange={({ value }) => {
                      handleNewSelectedRowBlur({
                        fieldName: "name",
                        value,
                      });
                      setIsAddingNewRow(false);
                    }}
                  />
                </div>
              )}
            </Slider>
          </div>
        </Paper>
      </section>

      {/* SELECTED MEASURES & PERFORMANCE INDICATOR */}
      {/* <Stack
        className="mb-4 pb-8 mt-14"
        alignItems="flex-start"
        gap={theme.spacing(8)}
      >
        <Paper className="w-1/2">
          <h2 className=" t-heading-l mb-6">
            Configurate your Retrofit Measures
          </h2>
          <Table
            className="max-h-100 "
            data={selectedMeauresTable}
            headers={selectedMeasuresHeaders}
            newRowHeaders={selectedMeasuresHeadersNewRow}
            columnsMinWidth={"10rem"}
            idColumnName={"id"}
            onAddNewRow={handleNewSelectedRowBlur}
            newRowDataKey="retrofit"
            onDelete={handleDeleteSelectedMeasure}
            onBlur={handleSelectedMeasureBlur}
            editable={(row) => true}
            addable
            rowRemoveable={(row) => true}
          />
        </Paper>

        <EnergyAndCarbonPerformance className="w-1/2" projectId={projectId} />
      </Stack> */}

      {/* ALL RETROFIT MEASURES */}
      <Paper>
        <h2 className="mb-6 t-heading-l">Retrofit Measures</h2>
        <Table
          className="max-h-100"
          data={measuresTable}
          headers={headers}
          columnsMinWidth={"180px"}
          idColumnName={"id"}
          onAddNewRow={({ fieldName, value }) => withGAEventTracking(eventNames.MODERN_RETROFIT_MEASURE_ADD, {},
            () => handleNewRowBlur({ fieldName, value }))}
          newRowDataKey="name"
          onDelete={({ rowId }) => withGAEventTracking(eventNames.MODERN_RETROFIT_MEASURE_DELETE, {},
            () => handleDelete({ rowId })
          )}
          onBlur={({ rowId, fieldName, value }) => withGAEventTracking(eventNames.MODERN_RETROFIT_MEASURE_UPDATE, {},
            () => handleBlur({ rowId, fieldName, value }))}
          editable={(row) => currentUserRoleId <= row["user_role"]}
          addable
          rowRemoveable={(row) => currentUserRoleId <= row["user_role"]}
          scrollDown={scrollDown}
        />
      </Paper>
    </section>
  );
};
