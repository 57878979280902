import { createContext } from "react";
import { Outlet } from "react-router-dom";
import { SecondaryNavbar } from "../components/BuildingPassport/SecondaryNavbar";

export const ScreenShotContext = createContext();

const navLinks = [
    {
        linkName: "Asset Overview",
        to: "/",
        dataKey: ''
    },
    {
        linkName: "Portfolio",
        to: "portfolio",
        dataKey: ''
    },

];

export const LandingPageSharedLayout = () => {
    return (
        <section>
            <SecondaryNavbar
                className="mt-4 mb-8"
                navLinks={navLinks}
            />
            <Outlet></Outlet>
        </section>
    );
};
