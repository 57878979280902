import * as React from "react";
import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";

export const CheckboxesGroup = ({
  values,
  setValues,
  label,
  options,
  displayOptions,
}) => {
  const handleChange = (event) => {
    const newValues = options.filter((option) => {
      if (event.target.name === option) {
        return event.target.checked;
      } else {
        return values.includes(option);
      }
    });
    setValues(newValues);
  };
  // sentence-case
  return (
    <Box sx={{ display: "flex", border: "0px solid var(--clr-gray-100)" }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel className="t-heading-s-700 " component="legend">
          {label}
        </FormLabel>
        <FormGroup>
          {options?.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={values?.includes(option)}
                    onChange={handleChange}
                    name={option}
                  />
                }
                label={displayOptions[index]}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Box>
  );
};
