import { getColumnDisplayName, settingsOptions } from "../../utils/dynamicDashboard";
import { chartTypesEnums, colorCategoriesEnums } from "../dynamicDashboard";



export const getPortfolioColumnDisplayName = ({ colName }) => {
    return getColumnDisplayName({ colName, dataMapping })
}

export const FiltersNames = Object.freeze({
    ID: 'ID',
    NAME: 'Name',
    ADDRESS: 'Addresse',
    PORTFOLIO: 'Portfolio',
    MAIN_USAGE: 'Main usage',
    PREMISE_AREA: 'Premise area',
    FOOTPRINT_AREA: 'Footprint area',
    GROSS_AREA: 'Gross area',
    FLOOR_AMOUMT: 'Floor amount',
    CONSTRUCTION_YEAR: 'Construction year',
    ENERGY_EFFICIENCY_CLASS: 'Energy Efficiency class',
    STRANDING_YEAR: 'Stranding year',
    ENERGY_CONSUMPTION: 'Energy consumption',
    CO2_EMISSION: 'CO2 Emissions',
    HEAT_ENERGY_TYPE: 'Heat energy type',
    HEAT_ENERGY_CONSUMPTION: 'Heat energy consumption',
    ELECTRICITY_CONSUMPTION: 'Electrictiy consumption',
    CASH_IN: 'cash_in',
    LATITUDE: 'lat',
    LONGITUDE: 'lon',
    GROSS_ASSET_VALUE: 'gross_asset_value',
    COUNT: 'Count',

    DIAGRAMS: 'diagrams',
    X_AXIS: 'xAxis',
    Y_AXIS: 'yAxis',
    DATAPOINT: 'dataPoint',
    TITLE: 'title',
    SORT_VALUE: 'sortValue',
    LEGEND_POSITION: 'legendsPosition',
    INVERT_X_AXIS: 'invertXAxis',
    INVERT_Y_AXIS: 'invertYAxis',

    DIAGRAMS_COLOR: 'color',
    DIAGRAMS_DIRECTION: 'direction',
    DIAGRAMS_STACKS: 'stacks',
    DIAGRAMS_PARTITIONS: 'partitions',
    DIAGRAMS_DIAGRAM_TYPE: 'diagramType',
    DIAGRAMS_DIAGRAM_DATA_KEY: 'dataKey',
    AGGREGATE_X_AXIS: 'aggregateXAxis',
    SHOW_PIE_LABELS: 'showPieLabels'

})


export const columnsDisplayNamesEnums = Object.freeze({
    // 'Accounting Area': '',
    NONE: '',
    INDEX: 'index',
    ID: 'ID',
    PORTFOLIO: 'Portfolio',
    BUILDING_ID: 'Building ID',
    BUILDING_NAME: 'Building Name',
    ZIP: 'Zip',
    CITY: 'City',
    MAIN_DISTRICT: 'Main District',
    SUB_DISTRICT: 'Sub District',
    FULL_ADDRESS: 'Full Address',
    STREET: 'Street',
    BOUGHT_ON: 'Bought on',
    SOLD_ON: 'Sold on',
    RENTAL_UNITS: 'Rental Units',
    YEAR_OF_CONSTRUCTION: 'Year of construction',
    FLOOR_AMOUMT: 'Floor amount',
    HERITAGE_PROTECTED: 'Heritage Protected',
    BUILDING_TYPE_ID: 'Building Type ID',
    BUILDING_TYPE: 'Building Type',
    PRE_FABRICATED: 'Pre Fabricated',
    LOGGIA: 'Loggia',
    BALCONY: 'Balkone',
    FOOTPRINT: 'Footprint',
    PREMISE: 'Premise',
    CEILING_AREA: 'Ceiling Area',
    FACADE_AREA: 'Facade Area',
    GROSS_AREA_WITH_BASEMENT: 'Gross area (incl. Basement)',
    NET_AREA_WITH_BASEMENT: 'Net Area (incl. Basement)',
    GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT: 'Gross Lettable Area (without Basement)',
    NET_LETTABEL_AREA_WITHOUT_BASEMENT: 'Net Lettable Area (without Basement)',
    USE_TYPE: 'Use type',
    ENERGY_EFFICIENCY_CLASS: 'EnA-Eff.kl',
    EPC_CONSUMPTION_KWH_M2A: 'EPC-Consumption kWh/m²/a',
    HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A: 'Heat Energy Consumption EPC kWh/m²/a',
    // / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
    HEAT_ENERGY_CONSUMPTION_EPC_KWH_A: 'Heat Energy Consumption kWh/a',
    ELECTRICITY_DEMAND_KWH_M2A: 'Electricity Demand kWh/m²/a',
    ELECTRICITY_DEMAND_KWH_A: 'Electricity Demand kWh/a',
    HEAT_ENERGY_TYPE: 'Heat Energy Type',
    DISTRICT_HEATING_AVAILABLE: 'District Heating available',
    NATURAL_GAS_AVAILABLE: 'Natural Gas available',
    TOTAL_ENERGY_CONSUMPTION: 'Total Energy consumption',
    CO2_EMISSION: 'CO2 Emissions',
    STRANDING_YEAR: 'Stranding year',
    LATITUDE: 'lat',
    LONGITUDE: 'lon',
    COUNT: 'Count',
    IMAGE_URL_VALUE: 'Imgae Url Value'

})



export const columnsAPINamesEnums = Object.freeze({
    // 'Accounting Area': '',
    ID: 'id',
    PORTFOLIO: 'portfolio',
    BUILDING_ID: 'asset_id',
    BUILDING_NAME: 'descriptive_name',
    ZIP: 'post_code',
    CITY: 'city',
    MAIN_DISTRICT: 'main_district',
    SUB_DISTRICT: 'sub_district',
    FULL_ADDRESS: 'address',
    STREET: 'street',
    BOUGHT_ON: 'bought_on',
    SOLD_ON: 'sold_on',
    RENTAL_UNITS: 'rental_units',
    YEAR_OF_CONSTRUCTION: 'construction_year',
    FLOOR_AMOUMT: 'floor_amount',
    HERITAGE_PROTECTED: 'heritage_protected',
    BUILDING_TYPE_ID: 'building_type_id',
    BUILDING_TYPE: 'building_type',
    PRE_FABRICATED: 'pre_fabricated',
    LOGGIA: 'loggia',
    BALCONY: 'balcony',
    FOOTPRINT: 'footprint_area',
    PREMISE: 'premise_area',
    CEILING_AREA: 'ceiling_area',
    FACADE_AREA: 'facade_area',
    GROSS_AREA_WITH_BASEMENT: 'gross_asset_basement_included',
    NET_AREA_WITH_BASEMENT: 'net_area_basement_included',
    GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT: 'gross_lettable_area_without_basement',
    NET_LETTABEL_AREA_WITHOUT_BASEMENT: 'net_lettable_area_without_basement',
    USE_TYPE: 'type',
    ENERGY_EFFICIENCY_CLASS: 'energy_label',
    EPC_CONSUMPTION_KWH_M2A: 'epc_consumption_kwh_per_square_meter_annum',
    HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A: 'heat_energy_consumption_epc_kwh_per_square_meter_annum',
    HEAT_ENERGY_CONSUMPTION_EPC_KWH_A: 'heat_energy_consumption_kwh_per_annum',
    ELECTRICITY_DEMAND_KWH_M2A: 'electricity_demand_kwh_per_square_meter_annum',
    ELECTRICITY_DEMAND_KWH_A: 'electricity_demand_kwh_per_annum',
    HEAT_ENERGY_TYPE: 'heat_energy_type',
    DISTRICT_HEATING_AVAILABLE: 'district_heating_available',
    NATURAL_GAS_AVAILABLE: 'natural_gas_available',
    TOTAL_ENERGY_CONSUMPTION: 'energy_consumption',
    CO2_EMISSION: 'carbon_dioxide_emission_value',
    STRANDING_YEAR: 'stranding_year',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    COUNT: 'Count',
    IMAGE_URL_VALUE: 'Imgae Url Value'

    // / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
})


export const dataMapping = {
    // 'Accounting Area': ''
    [columnsAPINamesEnums.ID]: columnsDisplayNamesEnums.ID,
    [columnsAPINamesEnums.PORTFOLIO]: columnsDisplayNamesEnums.PORTFOLIO,
    [columnsAPINamesEnums.BUILDING_ID]: columnsDisplayNamesEnums.BUILDING_ID,
    [columnsAPINamesEnums.BUILDING_NAME]: columnsDisplayNamesEnums.BUILDING_NAME,
    [columnsAPINamesEnums.ZIP]: columnsDisplayNamesEnums.ZIP,
    [columnsAPINamesEnums.CITY]: columnsDisplayNamesEnums.CITY,
    [columnsAPINamesEnums.MAIN_DISTRICT]: columnsDisplayNamesEnums.MAIN_DISTRICT,
    [columnsAPINamesEnums.SUB_DISTRICT]: columnsDisplayNamesEnums.SUB_DISTRICT,
    [columnsAPINamesEnums.FULL_ADDRESS]: columnsDisplayNamesEnums.FULL_ADDRESS,
    [columnsAPINamesEnums.STREET]: columnsDisplayNamesEnums.STREET,
    [columnsAPINamesEnums.BOUGHT_ON]: columnsDisplayNamesEnums.BOUGHT_ON,
    [columnsAPINamesEnums.SOLD_ON]: columnsDisplayNamesEnums.SOLD_ON,
    [columnsAPINamesEnums.RENTAL_UNITS]: columnsDisplayNamesEnums.RENTAL_UNITS,
    [columnsAPINamesEnums.YEAR_OF_CONSTRUCTION]: columnsDisplayNamesEnums.YEAR_OF_CONSTRUCTION,
    [columnsAPINamesEnums.FLOOR_AMOUMT]: columnsDisplayNamesEnums.FLOOR_AMOUMT,
    [columnsAPINamesEnums.HERITAGE_PROTECTED]: columnsDisplayNamesEnums.HERITAGE_PROTECTED,
    [columnsAPINamesEnums.BUILDING_TYPE_ID]: columnsDisplayNamesEnums.BUILDING_TYPE_ID,
    [columnsAPINamesEnums.BUILDING_TYPE]: columnsDisplayNamesEnums.BUILDING_TYPE,
    [columnsAPINamesEnums.PRE_FABRICATED]: columnsDisplayNamesEnums.PRE_FABRICATED,
    [columnsAPINamesEnums.LOGGIA]: columnsDisplayNamesEnums.LOGGIA,
    [columnsAPINamesEnums.BALCONY]: columnsDisplayNamesEnums.BALCONY,
    [columnsAPINamesEnums.FOOTPRINT]: columnsDisplayNamesEnums.FOOTPRINT,
    [columnsAPINamesEnums.PREMISE]: columnsDisplayNamesEnums.PREMISE,
    [columnsAPINamesEnums.CEILING_AREA]: columnsDisplayNamesEnums.CEILING_AREA,
    [columnsAPINamesEnums.FACADE_AREA]: columnsDisplayNamesEnums.FACADE_AREA,
    [columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT]: columnsDisplayNamesEnums.GROSS_AREA_WITH_BASEMENT,
    [columnsAPINamesEnums.NET_AREA_WITH_BASEMENT]: columnsDisplayNamesEnums.NET_AREA_WITH_BASEMENT,
    [columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT]: columnsDisplayNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
    [columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT]: columnsDisplayNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
    [columnsAPINamesEnums.USE_TYPE]: columnsDisplayNamesEnums.USE_TYPE,
    [columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS]: columnsDisplayNamesEnums.ENERGY_EFFICIENCY_CLASS,
    [columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A]: columnsDisplayNamesEnums.EPC_CONSUMPTION_KWH_M2A,
    [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A]: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
    [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A]: columnsDisplayNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
    [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A]: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
    [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A]: columnsDisplayNamesEnums.ELECTRICITY_DEMAND_KWH_A,
    [columnsAPINamesEnums.HEAT_ENERGY_TYPE]: columnsDisplayNamesEnums.HEAT_ENERGY_TYPE,
    [columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE]: columnsDisplayNamesEnums.DISTRICT_HEATING_AVAILABLE,
    [columnsAPINamesEnums.NATURAL_GAS_AVAILABLE]: columnsDisplayNamesEnums.NATURAL_GAS_AVAILABLE,
    [columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION]: columnsDisplayNamesEnums.TOTAL_ENERGY_CONSUMPTION,
    [columnsAPINamesEnums.CO2_EMISSION]: columnsDisplayNamesEnums.CO2_EMISSION,
    [columnsAPINamesEnums.STRANDING_YEAR]: columnsDisplayNamesEnums.STRANDING_YEAR,
    [columnsAPINamesEnums.LATITUDE]: columnsDisplayNamesEnums.LATITUDE,
    [columnsAPINamesEnums.LONGITUDE]: columnsDisplayNamesEnums.LONGITUDE,
    [columnsAPINamesEnums.IMAGE_URL_VALUE]: columnsDisplayNamesEnums.IMAGE_URL_VALUE,
    // a': '':// 'Electricity Consumption EPC kWh/m,
}

export const categoricalColumns = [
    // 'Accounting Area': ''
    // a': '':// 'Electricity Consumption EPC kWh/m,
    columnsAPINamesEnums.PORTFOLIO,
    columnsAPINamesEnums.BUILDING_NAME,
    columnsAPINamesEnums.ZIP,
    columnsAPINamesEnums.CITY,
    columnsAPINamesEnums.MAIN_DISTRICT,
    columnsAPINamesEnums.SUB_DISTRICT,
    columnsAPINamesEnums.FULL_ADDRESS,
    columnsAPINamesEnums.STREET,
    columnsAPINamesEnums.HERITAGE_PROTECTED,
    columnsAPINamesEnums.BUILDING_TYPE,
    columnsAPINamesEnums.PRE_FABRICATED,
    columnsAPINamesEnums.LOGGIA,
    columnsAPINamesEnums.BALCONY,
    columnsAPINamesEnums.USE_TYPE,
    columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,
    columnsAPINamesEnums.HEAT_ENERGY_TYPE,
    columnsAPINamesEnums.IMAGE_URL_VALUE,
]


export const dataNeededColumns = Object.values(columnsAPINamesEnums).filter(col => col !== columnsAPINamesEnums.IMAGE_URL_VALUE)
export const infoBoxAndTableNeededColumns = [
    // Info Box Specific
    columnsAPINamesEnums.ID,
    columnsAPINamesEnums.FULL_ADDRESS,
    columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT,
    columnsAPINamesEnums.YEAR_OF_CONSTRUCTION,
    columnsAPINamesEnums.STRANDING_YEAR,
    columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS,

    // Table Specific
    columnsAPINamesEnums.PORTFOLIO,
    columnsAPINamesEnums.HEAT_ENERGY_TYPE,

    // Common Columns
    columnsAPINamesEnums.USE_TYPE,
    columnsAPINamesEnums.IMAGE_URL_VALUE,
    columnsAPINamesEnums.BUILDING_NAME,
    columnsAPINamesEnums.CO2_EMISSION,
    columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION,
]


export const columnsUnits = Object.freeze({
    // 'Accounting Area': '',
    [columnsAPINamesEnums.PORTFOLIO]: '',
    [columnsAPINamesEnums.BUILDING_ID]: '',
    [columnsAPINamesEnums.BUILDING_NAME]: '',
    [columnsAPINamesEnums.ZIP]: '',
    [columnsAPINamesEnums.CITY]: '',
    [columnsAPINamesEnums.MAIN_DISTRICT]: '',
    [columnsAPINamesEnums.SUB_DISTRICT]: '',
    [columnsAPINamesEnums.FULL_ADDRESS]: '',
    [columnsAPINamesEnums.STREET]: '',
    [columnsAPINamesEnums.BOUGHT_ON]: 'Year',
    [columnsAPINamesEnums.SOLD_ON]: 'Year',
    [columnsAPINamesEnums.RENTAL_UNITS]: '',
    [columnsAPINamesEnums.YEAR_OF_CONSTRUCTION]: 'Year',
    [columnsAPINamesEnums.FLOOR_AMOUMT]: '',
    [columnsAPINamesEnums.HERITAGE_PROTECTED]: '',
    [columnsAPINamesEnums.BUILDING_TYPE_ID]: '',
    [columnsAPINamesEnums.BUILDING_TYPE]: '',
    [columnsAPINamesEnums.PRE_FABRICATED]: '',
    [columnsAPINamesEnums.LOGGIA]: '',
    [columnsAPINamesEnums.BALCONY]: '',
    [columnsAPINamesEnums.FOOTPRINT]: 'm²',
    [columnsAPINamesEnums.PREMISE]: 'm²',
    [columnsAPINamesEnums.CEILING_AREA]: 'm²',
    [columnsAPINamesEnums.FACADE_AREA]: 'm²',
    [columnsAPINamesEnums.GROSS_AREA_WITH_BASEMENT]: 'm²',
    [columnsAPINamesEnums.NET_AREA_WITH_BASEMENT]: 'm²',
    [columnsAPINamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT]: 'm²',
    [columnsAPINamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT]: 'm²',
    [columnsAPINamesEnums.USE_TYPE]: '',
    [columnsAPINamesEnums.ENERGY_EFFICIENCY_CLASS]: '',
    [columnsAPINamesEnums.EPC_CONSUMPTION_KWH_M2A]: 'kWh/m²/a',
    [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A]: 'kWh/m²/a',
    // [columnsNamesEnums.//] / 'Electricity Consumption EPC kWh/²/// 'Electricity Consumption EPC kWh/m,
    [columnsAPINamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A]: 'kWh/a',
    [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_M2A]: 'kWh/m²/a',
    [columnsAPINamesEnums.ELECTRICITY_DEMAND_KWH_A]: 'kWh/a',
    [columnsAPINamesEnums.HEAT_ENERGY_TYPE]: '',
    [columnsAPINamesEnums.DISTRICT_HEATING_AVAILABLE]: '',
    [columnsAPINamesEnums.NATURAL_GAS_AVAILABLE]: '',
    [columnsAPINamesEnums.TOTAL_ENERGY_CONSUMPTION]: 'kWh/m²/a',
    [columnsAPINamesEnums.CO2_EMISSION]: 'kg/m²/a',
    [columnsAPINamesEnums.STRANDING_YEAR]: 'Year',
    [columnsAPINamesEnums.LATITUDE]: 'Deg',
    [columnsAPINamesEnums.LONGITUDE]: 'Deg',
    [columnsAPINamesEnums.COUNT]: '#'
})


export const defaultColors = Object.freeze({
    [colorCategoriesEnums.COLUMNS]: {
    },
    [colorCategoriesEnums.VALUES]: {

    }
})


const relevantConfigOptions = {
    [chartTypesEnums.AREA]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.HEIGHT_100,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.SORT_BY,
        settingsOptions.DATA_POINT,
        settingsOptions.TITLE,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.BAR]: [
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.SORT_BY,
        settingsOptions.DATA_POINT,
        settingsOptions.TITLE,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.PIE]: [
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.TITLE,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.SHOW_PIE_LABELS
    ],
    [chartTypesEnums.DOUGHNUT]: [
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.TITLE,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.SHOW_PIE_LABELS
    ],
    [chartTypesEnums.LINE]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.SORT_BY,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.DATA_POINT,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.COMPOSED]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.QUANTITY,
        settingsOptions.SORT_BY,
        settingsOptions.DIAGRAM_TYPE,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        settingsOptions.AGGREGATE_X_AXIS
    ],
    [chartTypesEnums.KPI]: [
        settingsOptions.TITLE,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.DATA_POINT
    ],
    [chartTypesEnums.HISTOGRAM]: [
        settingsOptions.TITLE,
        settingsOptions.QUANTITY,
        settingsOptions.SORT_BY,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.HEATMAP]: [
        settingsOptions.TITLE,
        settingsOptions.X,
        settingsOptions.Y,
        settingsOptions.COLOR,
        settingsOptions.DATA_POINT,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.MINI_MAP]: [
        settingsOptions.DATA_POINT,
        settingsOptions.COLOR_BAR_RANGE
    ],
};

export const isRelevantOption = (chartType, option, index) => {
    if (
        index > 0 &&
        chartType !== chartTypesEnums.HISTOGRAM &&
        chartType !== chartTypesEnums.COMPOSED
    )
        return false;
    return relevantConfigOptions[chartType]?.includes(option);
};


export const areItemsSame = ({ item1, item2, chartType, xAxis, yAxis, diagrams }) => {
    // if (!diagrams?.[0]) return false
    const { stacks, partitions } = diagrams?.[0]
    const settingsMapping = {
        [settingsOptions.PARTITIONS]: partitions, [settingsOptions.STACKS]: stacks,
        [settingsOptions.X]: xAxis, [settingsOptions.Y]: yAxis
    }
    for (const [settingName, settingValue] of Object.entries(settingsMapping)) {
        if (isRelevantOption(chartType, settingName)
            && !(
                (item1?.[settingValue] === item2?.[settingValue]) || !settingValue
            )
        )
            return false
    }
    return true
}