import React from "react";

export const Pill = ({ children, className }) => {
  return (
    <div
      className={`${className} t-numbers-m p-2 rounded-lg text-indigo-600 bg-indigo-50`}
    >
      {children}
    </div>
  );
};
