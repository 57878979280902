import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMap, useMapEvents, LayersControl, Tooltip } from 'react-leaflet'
import L from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import { isNumeric, sumByKeys } from '../../utils/dataManipulation';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import { addCommaToNumber, formatNumberBasedOnUser } from '../../utils/namesManipulation';
import { isEqual } from 'lodash';

const { BaseLayer, Overlay } = LayersControl;

// Fix default icon issue in some configurations
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
L.DomUtil.TRANSITION = true
const createCircularIcon = (size, color) => {
    return L.divIcon({
        className: 'custom-marker',
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        popupAnchor: [0, -size / 2],
        html: `<div style="background-color:${color}; width:${size}px; height:${size}px; border-radius:50%;"></div>`
    });
};

export const MyMap = React.memo(({ data, radius, eventHandlers, resizeTriggerVariables = [], rerenderVariable, ...props }) => {
    const [renderFlag, setRenderFlag] = useState(true)
    const [zoomLevel, setZoomLevel] = useState(2);
    const [boundsSet, setBoundsSet] = useState(false);
    const MapZoomListener = () => {
        const map = useMapEvents({
            zoom: () => {
                setZoomLevel(map.getZoom());
            },
        });
        return null;
    };

    useEffect(() => {
        setRenderFlag(renderFlag => !renderFlag)
    }, [rerenderVariable])

    useEffect(() => {
        if (miniMapRef.current)
            miniMapRef.current.invalidateSize();
    }, [...resizeTriggerVariables])


    const pointEventHandlers = (point) => {
        let output = {}
        if (!eventHandlers) return output
        Object.entries(eventHandlers)?.forEach(([eventName, action]) => {
            output[eventName] = (...rest) => action(point, ...rest)
        })

        return output
    }

    const latValues = data?.map(point => point.latitude)
    const lonValues = data?.map(point => point.longitude)

    latValues.sort((a, b) => a - b)
    lonValues.sort((a, b) => a - b)

    const centerIndex = Math.floor(data?.length / 2)

    const center = [latValues[centerIndex], lonValues[centerIndex]]

    const MapZoomToFit = () => {
        const map = useMap();

        useEffect(() => {
            if (!boundsSet && data.length > 0) {
                const bounds = L.latLngBounds(data.map((point) => [point.latitude, point.longitude]));
                map.fitBounds(bounds);
                setBoundsSet(true);
            }
        }, [data, boundsSet]);

        return null;
    };
    const miniMapRef = useRef(null);

    return <MapContainer
        center={center}
        zoom={zoomLevel}
        scrollWheelZoom={true}
        key={rerenderVariable}
        {...props}
        ref={miniMapRef}
    >
        <MapZoomToFit />
        <MapZoomListener />
        <LayersControl position="bottomleft">
            {/* Base layers */}
            <BaseLayer checked name="Basic">
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                    attribution="&copy; CartoDB"
                />
            </BaseLayer>

            <BaseLayer name="Standard">
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </BaseLayer>

            <BaseLayer name='Satellite'>
                <TileLayer
                    url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    attribution="&copy; Esri"
                />
            </BaseLayer>
            <BaseLayer name='Dark Themed'>
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    attribution="&copy; CartoDB"
                />
            </BaseLayer>
            <BaseLayer name='Topographic'>
                <TileLayer
                    url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
                    attribution="&copy; OpenTopoMap contributors"
                />
            </BaseLayer>



            {/* You can add more overlay layers as needed */}
        </LayersControl>
        <MarkerCluster pointEventHandlers={pointEventHandlers} data={data} />
    </MapContainer>
}, isEqual)


const MarkerCluster = ({ data, pointEventHandlers }) => {
    const map = useMap();
    useEffect(() => {
        const markers = L.markerClusterGroup({
            maxClusterRadius: 5,
            disableClusteringAtZoom: 9,
            spiderfyOnMaxZoom: false,
        });
        data?.forEach((point) => {
            const marker = L.marker(point.center, {
                icon: createCircularIcon(20, point.color),
            });

            marker.bindTooltip(`
                    <div style="font-size:10px; padding: 5px; border-radius: 5px;">
                        <strong >${point.name || ''}</strong><br />
                        <span class="t-numbers-xxs"> ${formatNumberBasedOnUser(point.value) || 'No value'}</span>
                    </div>
            `, {
                permanent: false,
                direction: 'top',
            });

            const eventHandlers = pointEventHandlers(point)
            Object.entries(eventHandlers).forEach(([eventName, handler]) => {
                marker.on(eventName, () => {
                    const pointXYCoordinates = map?.latLngToContainerPoint(point.center);
                    const mapContainer = map.getContainer();
                    const containerRect = mapContainer.getBoundingClientRect();
                    const viewportPosition = {
                        x: containerRect.left + pointXYCoordinates.x,
                        y: containerRect.top + pointXYCoordinates.y,
                    };
                    handler(viewportPosition)
                });

            })
            markers.addLayer(marker);
        });

        map.addLayer(markers);

        return () => {
            map.removeLayer(markers);
        };
    }, [map, data]);

    return null;
};