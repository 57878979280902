export const buildingUseTypesConfig = {
    'Office': {
        color: "#4EA72E",
        label: "Office",
    },
    'Retail, High Street': {
        color: "#FFC000",
        label: "Retail, High Street",
    },
    'Retail, Shopping Center': {
        color: "#eeee00",
        label: "Retail, Shopping Center",
    },
    'Retail, Warehouse': {
        color: "#FF6600",
        label: "Retail, Warehouse",
    },
    'Industrial, Distribution Warehouse Cooled': {
        color: "#E49EDD",
        label: "Industrial, Distribution Warehouse Cooled",
    },
    'Industrial, Distribution Warehouse': {
        color: "#7030A0",
        label: "Industrial, Distribution Warehouse",
    },
    'Hotel': {
        color: "#0070C0",
        label: "Hotel",
    },
    'Healthcare': {
        color: "#94DCF8",
        label: "Healthcare",
    },
    'Lodging, Leisure & Recreation': {
        color: "#00FFFF",
        label: "Lodging, Leisure & Recreation",
    },
    'Residential': {
        color: "#104861",
        label: "Residential",
    },

};


export const buildingUsageConfig = {
    Rented: {
        // color: '#00B050',
        color: 'var(--clr-plunge-500)',
        label: 'Average annual used area [m²]'
    },
    Vacant: {
        // color: '#00B050',
        textColor: '#000',
        color: 'var(--clr-mystic-red-500)',
        label: 'Average annual vacant area [m²]'
    }
}



