import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const RadioButton = ({
  value,
  setValue,
  options,
  displayOptions,
  label,
  row,
}) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl className={row && "flex-row"}>
      {label && (
        <FormLabel className={`t-heading-s-700 sentence-case ${row && "mr-4"}`}>
          {label}
        </FormLabel>
      )}
      <RadioGroup value={value} onChange={handleChange} row={row}>
        {options?.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option}
              control={<Radio />}
              label={displayOptions[index]}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
