export default {
  noInfoAvailable: "Es liegen keine Informationen vor!",
  nav: {
    "building envelope": "Building envelope",
    "energy supply system": "Energieversorgungssystem",
    other: "Weiteres",
    windows: "Fenster",
    wall: "Wand",
    rooftop: "Dach",
    "shading system": "Beschattungssystem",
    "heating system": "Heizsystem",
    "hot water": "Warmwassersystem",
    "cooling system": "Kühlsystem",
    "PV system": "PV Anlage",
    "building automation": "Gebäudeautomation",

    // TODO: quality check needed.
    windowsDetails: "Fensterdetails",
    wallDetails: "Wanddetails",
    rooftopDetails: "Dachdetails",
    "shading systemDetails": "Beschattungssystemdetails",
    "heating systemDetails": "Heizsystemdetails",
    "hot waterDetails": "Warmwassersystemdetails",
    "cooling systemDetails": "Kühlsystemdetails",
    "PV systemDetails": "PV Anlagedetails",
    "building automationDetails": "Gebäudeautomationdetails",

    // TODO: quality check needed.
    windowsFilesDownload: "Alle Fensterdateien herunterladen",
    wallFilesDownload: "Alle Wanddateien herunterladen",
    rooftopFilesDownload: "Alle Dachdateien herunterladen",
    "shading systemFilesDownload":
      "Alle Beschattungssystemdateien herunterladen",
    "heating systemFilesDownload": "Alle Heizsystemdateien herunterladen",
    "hot waterFilesDownload": "Alle Warmwassersystemdateien herunterladen",
    "cooling systemFilesDownload": "Alle Kühlsystemdateien herunterladen",
    "PV systemFilesDownload": "Alle PV Anlagedateien herunterladen",
    "building automationFilesDownload":
      "Alle Gebäudeautomationdateien herunterladen",
  },
  table: {
    title: {},
    columns: {
      Description: "Beschreibung",
      Area: "Fläche",
      Colour: "Farbe",
      Modernization: "Modernisierung",
      numberOfWindows: "Anzahl der Fenster",
      uValue: "U-Wert",
      gValue: "G-Wert",
      lastChecked: "Zuletzt überprüft",
      windowType: "Fenstertyp",
      files: "Datei",
      Files: "Datei",
      Layer: "Schicht",
      Thickness: "Dicke",
      Density: "Dichte",
      "Thermal conductivity": "Wärmeleitfähigkeit",
      "Heat capacity": "Wärmekapazität",

      "Created at": "Created at",
      "Updated at": "Updated at",
      Installed: "Installed",
      Id: "Id",
    },
  },
};
