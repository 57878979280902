import moment from "moment";

const url = process.env.REACT_APP_API_URL + "/api/energy_consumption";
const quantityToApiModel = (quantity) => {
  switch (quantity) {
    case "consumption":
      return "EnergyConsumptionModel";
    case "price":
      return "PricePerformanceModel";
    case "emission":
      return "CarbonDioxideEmissionModel";
  }
};
const dateToApiDateString = (date) => moment(date).format("YYYY-MM-DD");
export default {
  monthly: {
    list: ({ projectId, quantity, startDate, endDate }) => {
      const modelName = quantityToApiModel(quantity);
      const startInterval = dateToApiDateString(startDate);
      const endInterval = dateToApiDateString(endDate);
      return `${url}/get_monthly_data/?project_id=${projectId}&model_name=${modelName}&start_interval=${startInterval} 00:00:00&end_interval=${endInterval} 23:59:59`;
    },
  },
  daily: {
    list: ({ projectId, quantity, startDate, endDate }) => {
      const modelName = quantityToApiModel(quantity);
      const startInterval = dateToApiDateString(startDate);
      const endInterval = dateToApiDateString(endDate);
      return `${url}/get_daily_data/?project_id=${projectId}&model_name=${modelName}&start_interval=${startInterval} 00:00:00&end_interval=${endInterval} 23:59:59`;
    },
  },
  hourly: {
    list: ({ projectId, quantity, startDate, endDate }) => {
      const modelName = quantityToApiModel(quantity);
      const startInterval = dateToApiDateString(startDate);
      const endInterval = dateToApiDateString(endDate);
      return `${url}/get_hourly_data/?project_id=${projectId}&model_name=${modelName}&start_interval=${startInterval} 00:00:00&end_interval=${endInterval} 23:59:59`;
    },
  },
};
