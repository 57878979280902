import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { withDashboardChartsPrecalculator } from "./DashboardChartsPrecalculator";
import { useParams } from "react-router-dom";
import { renderCustomizedLabel } from "../chart/PieAndDoughnutChart";
import { getOldProjectId } from "../../utils/hardcoding";

const SinglePieChart = ({ className, data, label }) => {
  return (
    <Stack flexDirection="column" className={className}>
      <p className=" t-body-xl capitalize my-1">{label}</p>
      <ResponsiveContainer width={"100%"} height={225}>
        <PieChart height={200} margin={0}>
          <Pie
            data={data}
            label={renderCustomizedLabel}
            labelLine={false}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={110}
            fill="#82ca9d"
          >
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Stack>
  );
};

const DashboardPieChart = ({
  props,
  isSingleChart,
  pieChartDataFirst,
  pieChartDataSecond,
}) => {
  const { className } = props;
  const { t } = useTranslation();
  const { projectId: newProjectId } = useParams();
  const projectId = getOldProjectId(newProjectId)
  const newProject =
    Number(projectId) == 13 ||
    Number(projectId) == 16 ||
    Number(projectId) == 19 ||
    Number(projectId) == 20 ||
    Number(projectId) == 21;
  const bobHannover = Number(projectId) == 17;
  const bobHam = Number(projectId) == 18;
  const vahingen = Number(projectId) == 21;
  const helvesanger10 = Number(projectId) == 26;
  const springenhof = Number(projectId) == 50;
  const klinik = Number(projectId) == 55;

  return (
    <Stack className={`items-start w-full ${"justify-around "} ${className} `}>
      {pieChartDataFirst && (
        <SinglePieChart
          data={pieChartDataFirst}
          label={
            helvesanger10 || springenhof
              ? "Always down 0%"
              : bobHannover
                ? "60% occupancy"
                : bobHam
                  ? "Occupancy 0%"
                  : klinik
                    ? 'Status quo'
                    : !newProject
                      ? t("EnergyPerformance.dashboard.simulated")
                      : vahingen
                        ? "Energy Demand (Simulated)"
                        : "Energy Consumption"
          }
          className={` ${isSingleChart ? "w-full" : " w-[46%]"}  `}
        />
      )}
      {pieChartDataSecond && (
        <SinglePieChart
          data={pieChartDataSecond}
          label={
            helvesanger10 || springenhof
              ? "No shading 100%"
              : bobHannover
                ? "60% occupancy 3h avg"
                : bobHam
                  ? "Occupancy 100%"
                  : klinik
                    ? 'GEG'
                    : !newProject
                      ? t("EnergyPerformance.dashboard.measured")
                      : vahingen
                        ? "Energy Consumption (Measured)"
                        : "Energy Demand"
          }
          className={` ${isSingleChart ? "w-full" : " w-[46%]"}  `}
        />
      )}
    </Stack>
  );
};

export default withDashboardChartsPrecalculator(DashboardPieChart);
