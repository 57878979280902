import { Paper } from "@mui/material";
import { RadioButton } from "../RadioButton";

export const ConfigFilter = ({
  value,
  setValue,
  options,
  displayOptions,
  label,
}) => {
  return (
    <Paper className="rounded-none p-2 pl-3 border-0">
      <RadioButton
        value={value}
        setValue={setValue}
        options={options}
        displayOptions={displayOptions}
        label={label}
      />
    </Paper>
  );
};
