import { Paper, Stack } from "@mui/material";
import { Loading } from "../Loading";
import { BulletChartAxis } from "./BulletChartAxis";
import { BulletChartColorBar } from "./BulletChartColorBar";
import BulletChartLegend from "./BulletChartLegend";
import { BulletChartZones } from "./BulletChartZones";
import { Pill } from "./Pill";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";

export const BulletChart = ({
  className,
  bulletColorBarClassName,
  header,
  pill,
  bullets,
  legends,
  axis,
  zones,
  hasZones = false,
  isLoading,
}) => {
  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="16rem">
      {/* <Paper className={`${className} `}> */}
      <div className={className}>
        {/* Header */}
        <Stack spacing={4}>
          <h2 className="t-heading-l">{header}</h2>
          <Pill>{pill}</Pill>
        </Stack>

        <BulletChartLegend
          legends={legends}
          hasZones={hasZones}
          className="mt-4"
        />
        {/* Chart */}
        <div className="relative pt-8 mt-6 h-23">
          <BulletChartColorBar bullets={bullets} axis={axis} className={`h-4`} gradientClassName={bulletColorBarClassName} />
          <BulletChartAxis axis={axis} className="mt-6" />
          {hasZones && (
            <BulletChartZones zones={zones} axis={axis} className="mt-6" />
          )}
        </div>
        {/* </Paper> */}
      </div>
    </LoadingOrEmptyWrapper>
  );
};



export const BulletChartDoubleWithIntervals = ({
  className,
  bulletColorBarClassName,
  header,
  pill,
  bullets,
  thresholds,
  labels,
  axis,
  zones,
  hasZones = false,
  isLoading,
}) => {

  const axisLength = axis?.[axis?.length - 1] - axis?.[0]

  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="16rem">
      <div className={className}>
        {/* Chart */}
        <div className="relative h-23">
          <div className="h-6">
            <div className="relative h-full">
              <BulletChartColorBar
                bullets={bullets?.filter(bullet => !bullet.reverse)}
                bulletProps={{
                  bottom: '16px'
                }}
                axis={axis} className={`h-6 rounded-none rounded-tl-full rounded-tr-full`} gradientClassName={bulletColorBarClassName} />
              <Stack className="absolute top-0 w-full h-full">
                {thresholds.map((threshold, index) => {
                  const maxIndex = thresholds?.length - 1
                  if (index === maxIndex) return
                  const label = labels[index]
                  const labelIntervalLength = thresholds[index + 1] - threshold
                  const labelWidth = 100 * labelIntervalLength / axisLength
                  const isLastLabel = index === maxIndex - 1
                  return <Stack key={index} className="justify-center h-full" style={{ borderRight: !isLastLabel && '1px solid black', width: `${labelWidth}%` }}>
                    <span className="t-numbers-m">
                      {label}
                    </span>
                  </Stack>
                }
                )}
              </Stack>
            </div>
          </div>
          <BulletChartAxis axis={axis} className="relative h-6 flex items-center" />
          <BulletChartColorBar bullets={bullets?.filter(bullet => bullet.reverse)}
            bulletProps={{ top: '16px' }}
            axis={axis} className={`h-6 rounded-none rounded-bl-full rounded-br-full`} gradientClassName={bulletColorBarClassName} />
        </div>
      </div>
    </LoadingOrEmptyWrapper>
  );
}