import styled from "styled-components";

export const LabelColumn = ({
  title,
  status,
  barsInforamtions,
  height,
  marginBottom,
}) => {
  return (
    <Wrapper>
      <div className="article-title">
        <h3>{title}</h3>
      </div>
      <div className="energy-status">
        {barsInforamtions?.map((bar, index) => {
          const transparent = bar.rating !== status.rating;
          return (
            <div
              key={index}
              style={{
                opacity: `${transparent ? 0 : 1}`,
                height: height,
                marginBottom: marginBottom,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="80%"
                height="100%"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                >
                  <polygon
                    className="p"
                    fill={bar.color}
                    points="0,50 40,100 100,100 100,0 40,0"
                  />
                </svg>

                <text
                  x="60%"
                  y="50%"
                  dominant-baseline="middle"
                  textAnchor="middle"
                  fill="white"
                  fontSize="1rem"
                >
                  {`${status.score} | ${status.rating}`}
                </text>
              </svg>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.article`
  width: 20%;
  text-align: center;
  border-left: 1px solid gray;
  .article-title {
    margin-bottom: 1rem;
  }
`;
