export const energyRatingsConf = [
  {
    rating: "a",
    bgColor: "var(--clr-cathode-green-900)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "b",
    bgColor: "var(--clr-cathode-green-800)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "c",
    bgColor: "var(--clr-cathode-green-700)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "d",
    bgColor: "var(--clr-star-600)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "e",
    bgColor: "var(--clr-tangerine-500)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "f",
    bgColor: "var(--clr-mystic-red-500)",
    textColor: "var(--clr-white)",
  },
  {
    rating: "g",
    bgColor: "var(--clr-mystic-red-700)",
    textColor: "var(--clr-white)",
  },
];

export const EnergyLabel = ({ energyClass, className, size }) => {
  const indexInConf = energyRatingsConf?.findIndex(
    (element) => element.rating === energyClass?.toLowerCase()
  );
  const bgColor = energyRatingsConf[indexInConf]?.bgColor;
  const textColor = energyRatingsConf[indexInConf]?.textColor;
  return (
    <div
      className={className}
      style={{
        color: textColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: bgColor,
        borderRadius: "5px",
        textTransform: "uppercase",
      }}
    >
      <span className={`${size === "sm" ? "t-heading-s" : "t-heading-l2"}`}>
        {energyClass}
      </span>
    </div>
  );
};
