export default {
  uploadImage: "Bild hochladen",
  dragToUpload:
    "Ziehen Sie Ihr Bild per Drag & Drop oder durchsuchen Sie es, um es hochzuladen.",
  successfulImgaeUpload: "Ihr Bild wurde erfolgreich hochgeladen!",
  OK: "Okay",
  sorry: "sorry",
  browse: "durchsuchen",
  upload: "hochladen", // TODO: make completely sure

  uploadFiles: "Dateien hochladen",
  successfulFileUpload: "Ihre Datei wurde erfolgreich hochgeladen!", // TODO: make completely sure
  dragToUploadFile:
    "Ziehen Sie Ihre Datei per Drag-and-Drop oder durchsuchen Sie sie, um sie hochzuladen.", // TODO: make completely sure

  uploadingFile: "Datei wird hochgeladen",
  cancel: "abbrechen",
  getDataProblem: "Beim Abrufen der Daten ist ein Problem aufgetreten!",
  noRecords: "Es sind keine Datensätze vorhanden!",
  downloadFileProblem:
    "Verzeihung! Beim Herunterladen der Dateien ist ein Problem aufgetreten.",
};
