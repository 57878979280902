import { Paper } from "@mui/material";
import { sentenceCase } from "../../utils/namesManipulation";
import { SecondaryNavLink } from "../SecondaryNavLink";
import { useTranslation } from "react-i18next";
import { dataLabels } from "../../configs/TechnicalData";

const NavMenuItem = ({ label, onClick, isDisabled, isActive }) => {
  return (
    <li onClick={onClick} className="px-2 mt-2">
      <SecondaryNavLink
        className="w-full h-10"
        isActive={isActive}
        isDisabled={isDisabled}
      >
        {label}
      </SecondaryNavLink>
    </li>
  );
};

const NavMenuItemGroup = ({
  groupLabel,
  childrenLabels,
  enabledTabs,
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  return (
    <li>
      <h3 className="border-b-gray-100 py-2 px-4 t-heading-l0  ">
        {groupLabel}
      </h3>
      <ul className="mb-4">
        {childrenLabels.map((label, index) => {
          return (
            <NavMenuItem
              key={index}
              isActive={label === activeTab}
              onClick={() => {
                if (enabledTabs?.includes(label)) setActiveTab(label);
              }}
              label={t(`TechnicalData.nav.${label}`)}
              isDisabled={!enabledTabs?.includes(label)}
            />
          );
        })}
      </ul>
    </li>
  );
};
export const NavMenu = ({
  className,
  enabledTabs,
  activeTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  return (
    <Paper className={className}>
      <ul className="mb-4">
        {Object.keys(dataLabels)?.map((groupLabel, index) => {
          return (
            <NavMenuItemGroup
              key={index}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              enabledTabs={enabledTabs}
              groupLabel={sentenceCase(t(`TechnicalData.nav.${groupLabel}`))}
              childrenLabels={dataLabels[groupLabel]}
            />
          );
        })}
      </ul>
    </Paper>
  );
};
