import React from "react";
import styled from "styled-components";
import { Icon } from "./Icon";

export const SecondaryNavLink = ({
  children,
  className,
  isActive,
  isDisabled,
  onClick,
}) => {
  if (!isActive)
    return (
      <Wrapper
        disabled={isDisabled}
        className={`t-body-xl-400 ${className}`}
        isActive={isActive}
        onClick={(event) => {
          event.currentTarget.blur();
        }}
      >
        <span>{children}</span>
      </Wrapper>
    );
  else {
    return (
      <Wrapper
        disabled={isDisabled}
        className={`t-body-xl-400 ${className}`}
        isActive={isActive}
        onClick={(event) => {
          event.currentTarget.blur();
        }}
      >
        <span>{children}</span>
        <Icon iconName="ChevronRight" size="md" color="white"></Icon>
      </Wrapper>
    );
  }
};

const Wrapper = styled("button")(({ isActive }) => ({
  "span:first-letter": {
    textTransform: "uppercase",
  },
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem 0.8rem 1rem 1.2rem",
  backgroundColor: `${
    isActive ? "var(--clr-secondary-blue-500)" : "transparent"
  }`,
  border: "2px solid transparent",
  // border: "none",
  borderRadius: "4px",
  color: `${isActive ? "var(--clr-white)" : "var(--clr-secondary-blue-500)"}`,
  outline: "none",
  textAlign: "left",
  ":hover:enabled": {
    cursor: "pointer",
    background: `${
      isActive
        ? "var(--clr-secondary-blue-500)"
        : "var(--clr-secondary-blue-50)"
    }`,
  },
  ":focus": {
    background: "var(--clr-secondary-blue-50)",
    border: "2px solid var(--clr-secondary-blue-200)",
    outline: "none",
  },
  ":disabled": {
    color: "var(--clr-gray-300)",
  },
  ".home__icon path": {
    stroke: "var(--clr-white)",
  },
}));
