import * as React from "react";
import styled from "@emotion/styled";
import { Icon } from "./Icon";
import { Dropzone } from "./Dropzone";
import Modal from "@mui/material/Modal";
import { useDropzone } from "react-dropzone";
import { useEffect, useState } from "react";
import { ButtonNew } from "./ButtonNew";
import { useTranslation } from "react-i18next";

export default function CustomizedDialogs({
  Component,
  token,
  url,
  formDataFileKeyName,
  onSuccess,
  onError,
  queryParams,
  errorMessage,
  successMessage,
  headerTitle,
  headerText,
  hasError,
}) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [uploadState, setUploadState] = useState("FileNotSelected");
  const [selectedFileName, setSelectedFileName] = useState("FileNotSelected");

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setUploadState("FileSelected");
      setSelectedFileName(
        acceptedFiles.length === 0 ? "" : acceptedFiles[0].path
      );
    }
  }, [acceptedFiles]);
  const [progress, setProgress] = useState(0);

  var percent = 0;

  const [xhr, setXHR] = useState(new XMLHttpRequest());

  const uploadXHR = () => {
    setUploadState("UploadStarted");
    var formData = new FormData();

    const fileToUpload = acceptedFiles[0];
    formData.append(formDataFileKeyName, fileToUpload);

    if (queryParams) {
      queryParams.forEach((param) => {
        formData.append(param.name, param.value);
      });
    }

    let newxhr = new XMLHttpRequest();
    newxhr.responseType = "json";
    newxhr.onreadystatechange = () => {
      if (newxhr.readyState === 4) {
        var elem = document.getElementById("input");
        elem.parentNode.removeChild(elem);
        setUploadState("UploadFinished");
        if (newxhr.status === 201) {
          if (onSuccess) onSuccess(newxhr.response);
        } else {
          if (onError) {
            onError(newxhr);
          }
        }
      }
    };
    newxhr.open("POST", url);
    newxhr.upload.addEventListener(
      "progress",
      function (event) {
        if (event.lengthComputable) {
          percent = (100 * event.loaded) / event.total;
          setProgress(percent);
        }
      },
      true
    );

    if (token) newxhr.setRequestHeader("Authorization", "Bearer " + token);

    newxhr.send(formData);
    setXHR(newxhr);
  };

  const cancelUpload = () => {
    xhr.abort();
    handleClose();
    setUploadState("FileNotSelected");
  };

  const handleClickOpen = () => {
    setModalOpen(true);
    setUploadState("FileNotSelected");
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Wrapper>
      <span onClick={handleClickOpen}>{Component}</span>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Wrapper2>
          {uploadState === "UploadFinished" && !hasError && (
            <>
              <div>
                <p className="finished-msg t-heading-m text-gray-800  sentence-case">
                  {successMessage}
                </p>
                <p className="finished-file-name t-body-s text-gray-500">
                  {selectedFileName}
                </p>
              </div>
              <ButtonNew
                className="finished-btn"
                variant="primary"
                onClick={handleClose}
                size="lg"
              >
                {t("general.OK")}
              </ButtonNew>
            </>
          )}
          {uploadState === "UploadFinished" && hasError && (
            <>
              <div>
                <p className="finished-msg t-heading-m text-gray-800  sentence-case">
                  {t("general.sorry")}! {errorMessage}.
                </p>
                <p className="finished-file-name t-body-s text-red-600">
                  {selectedFileName}
                </p>
              </div>
              <ButtonNew
                className="finished-btn"
                variant="primary"
                onClick={handleClose}
                size="lg"
              >
                {t("general.OK")}
              </ButtonNew>
            </>
          )}
          {uploadState !== "UploadFinished" && (
            <>
              <h3 className="t-heading-m text-gray-800 sentence-case">
                {headerTitle}
              </h3>
              <p className="t-body-s text-gray-500 sentence-case">
                {headerText}
              </p>
              <Dropzone
                className="dropzone"
                {...{
                  uploadState,
                  selectedFileName,
                  upload: uploadXHR,
                  cancelUpload: cancelUpload,
                  progress,
                  open: open,
                  getRootProps,
                  getInputProps,
                }}
              />
            </>
          )}

          <Icon iconName="Close" className="close" onClick={handleClose} />
        </Wrapper2>
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled("section")({});

const Wrapper2 = styled("section")({
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 400,
  backgroundColor: "var(--clr-white)",
  padding: "5rem 2.4rem 2.4rem 2.4rem",
  display: "flex",
  flexDirection: "column",
  outline: "none",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "var(--box-shadow-elevation-400)",
  borderRadius: "7px",
  border: "1px solid var(--clr-gray-100)",
  position: "absolute",
  textAlign: "center",
  p: {
    marginTop: "0.4rem",
  },
  ".dropzone": {
    marginTop: "3rem",
    height: "24.8rem",
    width: "100%",
  },
  ".close": {
    position: "absolute",
    display: "block",
    top: "2rem",
    right: "2rem",
    cursor: "pointer",
  },
  ".finished-msg": {
    marginTop: "8.4rem",
  },
  ".finished-file-name": {
    marginTop: "0.4rem",
  },
});
