import { Paper, Stack } from "@mui/material";
import React from "react";
import { ButtonNew } from "./ButtonNew";
import { Icon } from "./Icon";
import { Loading } from "./Loading";
import { Table } from "./Table";
import { LoadingOrEmptyWrapper } from "./LoadingAndEmptyHandler";
import { ComponentsCommonHeader } from "./ComponentsCommonHeader";
const TableWithHeader = ({
  className,
  header,
  action,
  actionText,
  tableData,
  tableHeaders,
  isLoading,
  idColumnName,
  hasActionAccess,
  editable,
  rowRemoveable,
  onBlur,
  actionIsDisabled,
}) => {
  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="15rem">
      <Paper className={`relative ${className}`}>
        <ComponentsCommonHeader
          title={header}
          hasActionAccess={hasActionAccess}
          action={action}
          actionIsDisabled={actionIsDisabled}
          actionText={actionText}
        />
        <Table
          className="mt-4"
          data={tableData}
          headers={tableHeaders}
          idColumnName={idColumnName}
          editable={editable}
          rowRemoveable={rowRemoveable}
          onBlur={onBlur}
        ></Table>
      </Paper>
    </LoadingOrEmptyWrapper>
  );
};

export default TableWithHeader;
