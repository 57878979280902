import { Paper } from "@mui/material";
import i18next from "i18next";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "rsuite/dist/rsuite-no-reset.min.css";
import DashboardBarChart from "./DashboardBarChart";
import DashboardLegends from "./DashboardLegends";
import DashboardPieChart from "./DashboardPieChart";
import { useState } from "react";

export const DashboardChartsContainer = ({
  isLoading,
  total_area,
  config,
  chartData,
  resources,
  measuredResources,
}) => {
  const [hourlysortType, setHourlysortType] = useState("time");

  const isSimulated = config.measureType?.includes("simulated");
  const isMeasured = config.measureType?.includes("measured");
  const selectedResources = resources.filter((resource) =>
    config.selectedResourcesOptions.includes(resource.name)
  );
  const selectedMeasuredResources = measuredResources.filter((resource) =>
    config.selectedResourcesOptions.includes(resource.name)
  );

  const quantityUnit = config.allUnits[config.quantity];

  const resourcesFirst = isSimulated ? selectedResources : null;
  const resourcesSecond = isMeasured ? selectedMeasuredResources : null;

  return (
    <>
      {/* Pie charts */}
      <Paper className="p-1">
        <DashboardPieChart
          resourcesFirst={resourcesFirst}
          resourcesSecond={resourcesSecond}
          data={chartData}
          unit={quantityUnit}
          buildingArea={total_area}
          className="w-100"
          isLoading={isLoading}
        />
      </Paper>

      {/* Legends */}
      <Paper
        className={`p-1 pr-4 ${
          isMeasured & isSimulated ? "row-span-2" : "row-span-1"
        }`}
      >
        <DashboardLegends
          data={chartData}
          resourcesFirst={resourcesFirst}
          resourcesSecond={resourcesSecond}
          unit={quantityUnit}
          buildingArea={total_area}
          isLoading={isLoading}
        />
      </Paper>

      {/* Bar chart  */}
      <Paper
        className={`p-1 pr-2 ${
          isMeasured & isSimulated ? "col-span-1" : "col-span-2"
        } `}
      >
        <DashboardBarChart
          resourcesFirst={resourcesFirst}
          resourcesSecond={resourcesSecond}
          data={chartData}
          xAxisDataKey="aggregated_time"
          tickFormatter={(date) => {
            return config.timeframe === "daily"
              ? moment(date).format("DD.MM.YY")
              : date;
          }}
          width="100%"
          height={300}
          unit={quantityUnit}
          isLoading={isLoading}
          buildingArea={total_area}
          timeframe={config.timeframe}
          hourlysortType={hourlysortType}
          setHourlysortType={setHourlysortType}
        />
      </Paper>
    </>
  );
};
