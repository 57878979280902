import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetGoals, useGetScenarios } from "../api/hooks/allHooks";
import { FormSection } from "../components/Modernization/FormSection";
import { GoalsSection } from "../components/Modernization/GoalsSection";
import { ScenariosSection } from "../components/Modernization/ScenariosSection";
import { ScreenShotContext } from "./BuildingPassportSharedLayout";
import { IsAllowed } from "../acl";
import { Helmet } from "react-helmet-async";
import { Wizard } from "../components/Modernization/Wizard";
import { eventNames, trackGAEvent } from "../functions/googleAnalytics";

export const Modernization = () => {
  const { projectId } = useParams();

  useEffect(() => {
    trackGAEvent(eventNames.MODERN_VIEW)
  }, []);

  return (
    <>
      <Helmet>
        <title>Modernization | Leaftech</title>
      </Helmet>

      <Wizard projectId={projectId} />
    </>
  );
};
