import { Grid, Paper, Stack } from "@mui/material";
import moment, { max } from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "rsuite/dist/rsuite-no-reset.min.css";
import { useGetBuilding, useGetDashboard } from "../../api/hooks/allHooks";
import { setChartData } from "../../features/energyPerformance/energyPerformanceSlice";
import {
  convertDailyToMonthly,
  filterDataToDateRange,
  minMaxByKey,
} from "../../utils/dataManipulation";
import { Loading } from "../Loading";
import { DashboardChartsContainer } from "./DashboardChartsContainer";
import { DashboardFilters } from "./DashboardFilters";
import { DashboardHeader } from "./DashboardHeader";
import i18next from "i18next";
import { LoadingOrEmptyWrapper } from "../LoadingAndEmptyHandler";
import { getOldProjectId } from "../../utils/hardcoding";
import { NoDataBanner } from "./NoDataBanner";

export const Dashboard = ({ }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { config, chartData } = useSelector((store) => store.energyPerformance);
  const { projectId: newProjectId } = useParams();
  const projectId = getOldProjectId(newProjectId)
  const newProject =
    Number(projectId) == 13 ||
    Number(projectId) == 16 ||
    Number(projectId) == 19 ||
    Number(projectId) == 20 ||
    Number(projectId) == 21;
  const bobHannover = Number(projectId) == 17;
  const bobHam = Number(projectId) == 18;
  const vahingen = Number(projectId) == 21;
  const helvesanger10 = Number(projectId) == 26;
  const springenhof = Number(projectId) == 50;

  const { data: building } = useGetBuilding(newProjectId);
  const total_area = building?.total_area;
  const common = {
    projectId: newProjectId,
    startDate: new Date("2000-01-01"),
    endDate: new Date("2100-01-01"),
    interval: "daily",
  };

  let quantitiesConfig = [
    {
      name: "consumption",
      apiKey: "",
      displayLabel: !newProject ? "Consumption" : "Energy",
      unit: "kWh",
    },
    {
      name: "price",
      apiKey: "",
      displayLabel: !newProject ? "Price" : "Costs",
      unit: "€",
    },
    {
      name: "emission",
      apiKey: "",
      displayLabel: "CO₂ emission",
      unit: "KgCO₂",
    },
  ];

  if (bobHannover)
    quantitiesConfig = [
      {
        name: "consumption",
        apiKey: "",
        displayLabel: !newProject ? "Consumption" : "Energy",
        unit: "kWh",
      },
    ];

  const {
    data: allPrice,
    isLoading: prIsLoading,
    isError: prIsError,
  } = useGetDashboard({
    ...common,
    quantity: "price",
  });
  const {
    data: allConsumption,
    isLoading: coIsLoading,
    isError: coIsError,
  } = useGetDashboard({
    ...common,
    quantity: "consumption",
  });
  const {
    data: allEmission,
    isLoading: emIsLoading,
    isError: emIsError,
  } = useGetDashboard({
    ...common,
    quantity: "emission",
  });

  const { min: minDate, max: maxDate } = minMaxByKey({
    data: allConsumption || [],
    key: "aggregated_time",
  });
  const quantityAllData = {
    price: allPrice || [],
    consumption: allConsumption || [],
    emission: allEmission || [],
  };
  const someChartIsLoading = coIsLoading || prIsLoading || emIsLoading;

  const componentRef = useRef();

  const { data, isLoading, isError } = useGetDashboard({
    projectId: newProjectId,
    quantity: config.quantity,
    startDate: moment(config.hourlyDates[0]).format("YYYY-MM-DD"),
    endDate: moment(config.hourlyDates[0]).format("YYYY-MM-DD"),
    interval: "hourly",
    enabled: config.timeframe === "hourly",
  });
  const hourlyData = data || [];

  useEffect(() => {
    if (config.timeframe === "hourly") {
      if (isError) toast.error(t("EnergyPerformance.dashboard.getDataProblem"));
    } else {
      if (
        (config.quantity === "consumption" && coIsError) ||
        (config.quantity === "emission" && emIsError) ||
        (config.quantity === "price" && prIsError)
      )
        toast.error(t("EnergyPerformance.dashboard.getDataProblem"));
    }
  }, [isError, prIsError, emIsError, coIsError]);

  useEffect(() => {
    if (config.timeframe === "hourly") {
      const warn = localStorage.getItem("hourlyTimeFrameShowMessage");
      if (!warn) {
        toast.warn(
          "As hourly data can be one day long, please select a single date. data for start date is shown by default."
        );
        localStorage.setItem("hourlyTimeFrameShowMessage", true);
      }
    }
  }, [config.timeframe]);

  useEffect(() => {
    if (!someChartIsLoading) {
      if (config.timeframe === "hourly")
        dispatch(setChartData([...hourlyData]));
      else {
        let filteredData = filterDataToDateRange({
          data: quantityAllData[config.quantity],
          startDate: config.dates[0],
          endDate: config.dates[1],
          dateKey: "aggregated_time",
        });
        if (config.timeframe === "monthly") {
          filteredData = convertDailyToMonthly({
            data: [...filteredData],
            dateKey: "aggregated_time",
          });
        }
        dispatch(setChartData(filteredData));
      }
    }
  }, [config, someChartIsLoading, data]);

  let measuredResources, resources;
  if (bobHam || helvesanger10 || bobHannover) {
    measuredResources = [
      {
        name: "heating",
        dataKey: "heating_system_value_measured",
        legendLabel:
          bobHam || helvesanger10 || bobHannover
            ? "Heating demand"
            : i18next.t("EnergyPerformance.dashboard.Heating system"),
        color: "var(--clr-vivid-orchid-300)",
      },
      {
        name: "cooling",
        dataKey: "cooling_system_value_measured",
        legendLabel:
          bobHam || helvesanger10 || bobHannover
            ? "Cooling demand"
            : i18next.t("EnergyPerformance.dashboard.Cooling system"),
        color: "var(--clr-plunge-500)",
      },
    ];
    resources = [
      {
        name: "heating",
        dataKey: "heating_system_value_simulated",
        legendLabel:
          bobHam || helvesanger10 || bobHannover
            ? "Heating demand"
            : i18next.t("EnergyPerformance.dashboard.Heating system"),
        color: "var(--clr-vivid-orchid-500)",
      },
      {
        name: "cooling",
        dataKey: "cooling_system_value_simulated",
        legendLabel:
          bobHam || helvesanger10 || bobHannover
            ? "Cooling demand"
            : i18next.t("EnergyPerformance.dashboard.Cooling system"),
        color: "var(--clr-plunge-700)",
      },
    ];
  } else if (springenhof) {
    measuredResources = [
      {
        name: "heating",
        dataKey: "heating_system_value_measured",
        legendLabel: "Heating demand",
        color: "var(--clr-vivid-orchid-300)",
      },
    ];
    resources = [
      {
        name: "heating",
        dataKey: "heating_system_value_simulated",
        legendLabel: "Heating demand",
        color: "var(--clr-vivid-orchid-500)",
      },
    ];
  } else {
    measuredResources = [
      {
        name: "electricity",
        dataKey: "electricity_system_value_measured",
        legendLabel: i18next.t("EnergyPerformance.dashboard.Electricity"),
        color: "var(--clr-mystic-red-300)",
      },
      {
        name: "hot_water",
        dataKey: "hot_water_system_value_measured",
        legendLabel: !newProject
          ? i18next.t("EnergyPerformance.dashboard.Hot water system")
          : "PV Yield",
        color: "var(--clr-secondary-blue-300)",
      },
      {
        name: "heating",
        dataKey: "heating_system_value_measured",
        legendLabel: bobHam
          ? "Heating demand"
          : i18next.t("EnergyPerformance.dashboard.Heating system"),
        color: "var(--clr-vivid-orchid-300)",
      },
      {
        name: "cooling",
        dataKey: "cooling_system_value_measured",
        legendLabel: bobHam
          ? "Cooling demand"
          : i18next.t("EnergyPerformance.dashboard.Cooling system"),
        color: "var(--clr-plunge-500)",
      },
    ];
    resources = [
      {
        name: "electricity",
        dataKey: "electricity_system_value_simulated",
        legendLabel: i18next.t("EnergyPerformance.dashboard.Electricity"),
        color: "var(--clr-mystic-red-500)",
      },
      {
        name: "hot_water",
        dataKey: "hot_water_system_value_simulated",
        legendLabel: !newProject
          ? i18next.t("EnergyPerformance.dashboard.Hot water system")
          : "PV Yield",
        color: "var(--clr-secondary-blue-500)",
      },
      {
        name: "heating",
        dataKey: "heating_system_value_simulated",
        legendLabel: bobHam
          ? "Heating demand"
          : i18next.t("EnergyPerformance.dashboard.Heating system"),
        color: "var(--clr-vivid-orchid-500)",
      },
      {
        name: "cooling",
        dataKey: "cooling_system_value_simulated",
        legendLabel: bobHam
          ? "Cooling demand"
          : i18next.t("EnergyPerformance.dashboard.Cooling system"),
        color: "var(--clr-plunge-700)",
      },
    ];
  }


  const isThereAnyData = allConsumption?.length || allPrice?.length || allEmission?.length
  return (
    <LoadingOrEmptyWrapper showLoading={someChartIsLoading} showEmpty={!isThereAnyData} height="400px"
      emptyComponent={() => <NoDataBanner />}
    >
      <article
        className="bg-white mt-8 px-4 pb-4 rounded-xl overflow-hidden"
        ref={componentRef}
      >
        <DashboardHeader
          isDownloadDisabled={!chartData?.length > 0}
          displayLabel={
            quantitiesConfig.find((i) => i.name === config.quantity)
              ?.displayLabel
          }
          componentRef={componentRef}
        />
        {/* Dashboard body */}
        <div className="rounded-xl bg-gray-50">
          <div
            className="grid grid-rows-[280px_335px] gap-2 p-2 "
            style={{
              gridTemplateColumns: "24rem calc(100% - 59.6rem) 34rem",
            }}
          >
            {/* Configs */}
            <DashboardFilters
              className="h-full row-span-2"
              minDate={minDate}
              maxDate={maxDate}
              resources={resources}
              quantitiesConfig={quantitiesConfig}
            />
            {/* Charts */}
            <DashboardChartsContainer
              chartData={chartData}
              resources={resources}
              measuredResources={measuredResources}
              total_area={total_area}
              config={config}
              isLoading={isLoading}
            />
          </div>
        </div>
      </article>
    </LoadingOrEmptyWrapper>
  );
};
