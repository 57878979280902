import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const Footer = ({ className }) => {
  const { t } = useTranslation();
  return (
    <footer
      className={`t-body-s text-gray-600 border-t-gray-100 mt-3 h-8 pt-4 bg-gray-50 ${className}`}
    >
      <Stack className="justify-between">
        <span className="text-gray-600">
          {t(`SharedLayout.footer.copyright`)}
        </span>
        <Stack spacing={8} className="text-gray-600">
          <span className="text-blue-500">
            {t(`SharedLayout.footer.imprint`)}
          </span>
          <span className="text-blue-500">
            {t(`SharedLayout.footer.privacyPolicy`)}
          </span>
        </Stack>
      </Stack>
    </footer>
  );
};
