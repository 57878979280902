import { distinctFilter } from "../utils/dataManipulation";
import { sentenceCase } from "../utils/namesManipulation";

export const extractLocations = (allBuildings, nullAlternative) => {
  if (!allBuildings) return allBuildings;
  const dataLocations = allBuildings
    ?.map((building) => {
      if (!building?.location) return nullAlternative;
      return sentenceCase(building?.location);
    })
    ?.filter(distinctFilter);
  return dataLocations;
};

export const extractCertifications = (allBuildings, nullAlternative) => {
  if (!allBuildings) return allBuildings;
  const dataCertificates = allBuildings
    ?.reduce((total, current) => {
      const certificates = current?.certificate
        ?.filter((cert) => cert.type !== "EU EPC")
        ?.map((cert) => cert.type);
      if (!certificates || certificates.length === 0)
        total.push(nullAlternative);
      else {
        total.push(...(certificates || []));
      }
      return total;
    }, [])
    .filter(distinctFilter);
  return dataCertificates;
};



const energyEfficiencyClassConf = [
  {
    rating: "+A",
    bgColor: "#24FF02",
    textColor: "#000",
    max: 30,
  },
  {
    rating: "A",
    bgColor: "#8EFF02",
    textColor: "#000",
    max: 50,
  },
  {
    rating: "B",
    bgColor: "#B9FF01",
    textColor: "#000",
    max: 75,
  },
  {
    rating: "C",
    bgColor: "#E5FE01",
    textColor: "#000",
    max: 100,
  },
  {
    rating: "D",
    bgColor: "#FBFE01",
    textColor: "#000",
    max: 130,
  },
  {
    rating: "E",
    bgColor: "#FDF001",
    textColor: "#000",
    max: 160,
  },
  {
    rating: "F",
    bgColor: "#FFD902",
    textColor: "#000",
    max: 200,
  },
  {
    rating: "G",
    bgColor: "#FD7809",
    textColor: "#000",
    max: 250,
  },
  {
    rating: "H",
    bgColor: "#FC3901",
    textColor: "#000",
    max: Infinity,
  },
];

export const getEnergyClassItem = ({ energyClass, energyConsumption }) => {
  const indexInConf = energyEfficiencyClassConf?.findIndex(
    (element) => (energyClass === element.rating) ||
      ((energyConsumption < element.max) && (energyConsumption !== null) && (energyConsumption !== undefined))
  );
  if (indexInConf === -1) return
  return energyEfficiencyClassConf[indexInConf]
}