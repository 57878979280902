// TODO: send a credential error toast when 401.
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonNew, Checkbox, CustomModal, Input } from "../components";
import { setIsInactivelyLoggedOut, setUser } from "../features/user/userSlice";

import { useMediaQuery } from "@mui/material";
import logo from "../Asset/LEAFTECH.svg";
import backgroundImgCropped from "../Asset/LooperBGCropped.png";
import backgroundImgLg from "../Asset/LooperBGLg.png";
import apiUrls from "../api/urls/";
import { PasswordInput } from "../components/Input";
import { Helmet } from "react-helmet-async";
import { loginUser } from "../api/functions/all";
import { addUserToLocalStorage } from "../utils/localStorage";

const Login = () => {
  const isSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("loginChangeBackground")
  );

  const { t, i18n } = useTranslation();
  const alternativeLanguage = i18n.language === "en" ? "de" : "en";

  const dispatch = useDispatch();
  const { user, isInactivelyLoggedOut } = useSelector((store) => store.user);
  const [isLoading, setIsLoading] = useState(false);
  const [consent, setConsent] = useState(false);
  const name = useRef("");
  const password = useRef("");
  const hanleSubmit = async (e) => {
    e.preventDefault();
    if (!name.current?.value | !password.current?.value | !consent) {
      toast.error(t("Login.form.fieldsMustBeFilled"));
      return;
    }
    const values = {
      username: name.current?.value,
      password: password.current?.value,
      grant_type: "password",
    };
    setIsLoading(true);
    loginUser(values)
      .then((resp) => {
        setIsLoading(false);
        dispatch(setUser(resp));
        addUserToLocalStorage(resp);
        toast.success(t("Login.welcome"));
      })
      .catch((error) => {
        setIsLoading(false);
        const defaultErrorMessage = "sorry! there was a problem on our end!";
        const msg =
          error.response.status === 401
            ? "username or password is wrong!"
            : defaultErrorMessage;
        toast.error(msg);
      });
  };

  const setModalOpen = (modalState) =>
    dispatch(setIsInactivelyLoggedOut(modalState));

  if (user) return <Navigate to={"/"} />;
  return (
    <>
      <Helmet>
        <title>Login | Leaftech</title>
      </Helmet>
      <section>
        {/* Background image */}
        <img
          src={isSmallScreen ? backgroundImgCropped : backgroundImgLg}
          alt="Login Background"
          className="absolute right-0 w-7/10 h-screen"
        />
        {/* Logo */}
        <div className="text-center absolute p-6 right-0">
          <p className="t-subheading-m uppercase">{t("Login.logoText")}</p>
          <a href="https://leaftech.eu/" target="_blank" rel="noreferrer">
            <img src={logo} alt="logo" className="mt-1" />
          </a>
        </div>
        <ButtonNew
          onClick={() => {
            localStorage.setItem("language", alternativeLanguage);
            i18n.changeLanguage(alternativeLanguage);
          }}
          variant="tertiary"
          size="lg"
          className="uppercase absolute right-50 top-5"
        >
          {alternativeLanguage}
        </ButtonNew>

        {/* Form */}

        <form className="w-102 absolute left-30 top-1/2 -translate-y-1/2">
          <h1 className="t-heading-xl">{t("Login.title")}</h1>
          <span className="t-heading-m mt-2 inline-block">
            {t("Login.subtitle")}
          </span>
          {/* inputs-container */}
          <div className="text-right mt-10 w-full">
            {/* username */}
            <Input
              className="mb-4 block w-full "
              inputRef={name}
              label={t("Login.form.username.placeholder")}
              placeholder={t("Login.form.username.placeholder")}
              height="4.8rem"
            />
            {/* password */}
            <PasswordInput
              className="mb-4 block w-full"
              label={t("Login.form.password.placeholder")}
              placeholder={t("Login.form.password.placeholder")}
              inputRef={password}
              height="4.8rem"
            />
            <a
              className="t-body-n text-blue-500"
              href={`${apiUrls.user.resetPassword}`}
            >
              {t("Login.form.forgotText")}
            </a>
          </div>
          {/* Agree to terms and conditions */}
          <div className="mt-10 mb-4 ">
            <Checkbox
              checked={consent}
              onChange={(e) => {
                setConsent(e.target.checked);
              }}
            >
              <span className="ml-3 inline-block">
                <span className="t-heading-s inline-block mr-1 cursor-pointer">
                  {t("Login.form.consent")}
                </span>
                <Link to="/privacyPolicy">
                  <span className="t-body-n text-blue-500">
                    {t("Login.form.toTermsAndConditions")}
                  </span>
                </Link>
              </span>
            </Checkbox>
          </div>
          <ButtonNew
            onClick={hanleSubmit}
            type="submit"
            variant="primary"
            disabled={isLoading}
            size="lg"
          >
            <p className="t-mobile-label">{t("Login.form.signInText")}</p>
          </ButtonNew>
          <div className="mt-4">
            <span className="t-heading-s">{t("Login.form.noAccount")}</span>
            <a
              href="mailto:service@leaftech.eu"
              className="t-body-n text-blue-500 inline-block ml-1"
            >
              {t("Login.form.help")}
            </a>
          </div>
        </form>

        <CustomModal
          modalOpen={isInactivelyLoggedOut}
          setModalOpen={setModalOpen}
          height="21.6rem"
          className="relative"
        >
          <p className="t-heading-m text-gray-800">
            {t("SharedLayout.forceLogoutTitle")}
          </p>
          <p className="t-body-l text-gray-500 mt-1">
            {t("SharedLayout.forceLogoutText")}
          </p>
          <ButtonNew
            variant="primary"
            onClick={() => setModalOpen(false)}
            size="md"
            type="button"
            className="mt-8 capitalize absolute left-1/2"
            style={{ transform: "translateX(-50%)" }}
          >
            {t("Login.login")}
          </ButtonNew>
        </CustomModal>
      </section>
    </>
  );
};

export default Login;
