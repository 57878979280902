import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  breakpoints: {
    values: {
      loginChangeBackground: 1160,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1230,
      xl: 1330,
      xl2: 1400,
      "2xl": 1440,
    },
  },
  spacing: (factor) => `${0.4 * factor}rem`,
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
        direction: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        sx: {
          color: "inherit",
          padding: "2.4rem",
          backgroundColor: "var(--clr-white)",
          border: "1px solid var(--clr-gray-100)",
          borderRadius: "10px",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        sx: {
          fontFamily: "Nunito Sans",
          fontSize: "1.4rem",
          fontWeight: 400,
          lineHeight: "2rem",
          letterSpacing: "0em",
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        sx: {
          padding: "4px 8px",
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        sx: {
          padding: "4px 8px",
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'transparent',
        centered: true,
        sx: {
          height: '24px',
          padding: '8px 0px',
          // borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--clr-secondary-blue-50)',

        }
      }
    },

    MuiTab: {
      defaultProps: {
        disableRipple: true,
        sx: {
          cursor: 'pointer',
          background: 'transparent',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          fontWeight: 900,
          borderRadius: 2,
          margin: '0 4px',
          color: 'var(--clr-secondary-blue-300)',
          color: 'black',
          '&:hover': {
            backgroundColor: 'var(--clr-secondary-blue-100)'
          },
          '&.Mui-selected': {
            backgroundColor: 'var(--clr-secondary-blue-300)',
            color: 'var(--clr-white)',
            border: 'none',
            outline: 'none'
          },


        }
      }
    },

    MuiCard: {
      defaultProps: {
        sx: {
          padding: "1.6rem",
          borderRadius: "8px",
          border: "1px solid transparent",
          outline: "1px solid transparent",
          cursor: "pointer",
          transition: "all 0.15s ease-out",
          backgroundColor: "var(--clr-white)",
          boxShadow: "var(--box-shadow-elevation-100)",
          ":hover": {
            border: "1px solid var(--clr-leaftech-blue-100)",
            boxShadow: "var(--box-shadow-up-hover)",
          },
          ":active": {
            background: "var(--clr-gray-50)",
            outline: "1px solid var(--clr-gray-100)",
          },
          ":focus:not(:active)": {
            outline: "3px solid var(--clr-leaftech-blue-200)",
          },
        },
        tabIndex: 0,
        onKeyDown: (event) => {
          if (event.code === "Space" || event.code === "Enter") {
            event.currentTarget.click();
          }
        },
      },
    },
  },
});
