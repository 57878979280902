export default {
  chart: {
    energyTitle: "Energiebewertung nach Grundfläche",
    carbonTitle: "Zertifizierung nach Bodenfläche",
    platinum: "DGNB-building in use-Platinum",
    gold: "DGNB-Gebäude im Einsatz-Gold",
    silver: "DGNB-building in use-silver",
    bronze: "DGNB-building in use-Bronze",
    notCertified: "Nicht zertifiziert",
  },
  cards: {
    energyTitle: "EPC Bewertungszertifikate",
    energyButtonLong: "Alle EPC Dateien herunterladen",
    carbonTitle: "Zertifikate",
    carbonButtonLong: "Alle Zertifikate herunterladen",
    buttonShort: "Herunterladen",
    noCertificate: "Es gibt keine weitere verfügbare Zertifizierung!",
  },
};
