import Breadcrumbs from "@mui/material/Breadcrumbs";
import * as React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Icon } from "./Icon";

export function Breadcrumb({ breadcrumbs }) {
  const breadcrumbsUrls = breadcrumbs
    ?.filter((breadcrumb) => breadcrumb.text)
    ?.map((breadcrumb) => {
      return {
        url: breadcrumb.url,
        text: breadcrumb.text,
      };
    });
  const breadcrumbsLinks = breadcrumbsUrls?.map((url, index) => {
    const lastLinkClass =
      index === breadcrumbsUrls.length - 1 ? "last" : "before-last";

    return (
      <Link key={index} to={url.url} underline="none">
        <p className={`t-body-m ${lastLinkClass}`}>{url.text}</p>
      </Link>
    );
  });

  return (
    <Wrapper>
      <Breadcrumbs
        separator={<Icon iconName="ChevronRight" size="sm" />}
        aria-label="breadcrumb"
      >
        {breadcrumbsLinks}
      </Breadcrumbs>
    </Wrapper>
  );
}
const Wrapper = styled("div")({
  ".last": {
    color: "var(--clr-secondary-blue-300)",
  },
  ".before-last": {
    color: "var(--clr-gray-900)",
  },

  "& .MuiBreadcrumbs-separator": {
    marginLeft: "0.1rem",
    marginRight: "0.1rem",
  },
});
