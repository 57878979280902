import { Stack } from "@mui/material";
import { ButtonNew } from "./ButtonNew";
import { Icon } from "./Icon";

export const ComponentsCommonHeader = ({
  className,
  title,
  hasActionAccess,
  action,
  actionIsDisabled,
  actionText,
}) => {
  return (
    <Stack className={`py-3 justify-between ${className}`}>
      <span className="t-heading-l  ">{title}</span>
      {hasActionAccess && (
        <ButtonNew
          onClick={action}
          size="sm"
          variant="tertiary"
          disabled={actionIsDisabled}
        >
          <Stack>
            <Icon
              color="var(--clr-secondary-blue-500)"
              iconName="Download"
              disabled={actionIsDisabled}
            ></Icon>
            <p className="ml-1.5 t-body-m">{actionText}</p>
          </Stack>
        </ButtonNew>
      )}
    </Stack>
  );
};
