import "./German.css";

export const German = () => {
  return (
    <body class="cd15 doc-content">
      <p class="cd3">
        <span class="cd20">Datenschutzerkl&auml;rung</span>
      </p>

      <p class="cd1">
        <span class="cd19 title-text">
          Im Folgenden informieren Sie dar&uuml;ber, welche personenbezogene
          Daten wir zu welchen Zwecken im Zusammenhang mit Ihrem Besuch und der
          Verwendung unserer Plattform verwenden. Sollten Sie dar&uuml;ber
          hinaus noch Fragen zum Umgang mit ihren personenbezogenen Daten haben,
          k&ouml;nnen Sie sich gerne an den Verantwortlichen wenden
          (Kontaktdaten sind unter dem Abschnitt &bdquo;Hinweis zur
          Verantwortlichen Stelle&ldquo; angegeben).
        </span>
      </p>
      <p class="cd1">
        <span class="cd19">
          Der Inhalt dieser Datenschutzerkl&auml;rung im &Uuml;berblick:
        </span>
      </p>
      <ol class="cd5 lst-kix_list_5-0 start" start="1">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">Datenschutz auf einen Blick</span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Begriff der personenbezogenen Daten</span>
      </p>
      <p class="cd1">
        <span class="c0">Datenerfassung auf dieser Website</span>
      </p>
      <p class="cd1">
        <span class="c0">Analyse-Tools und Tools von Drittanbietern</span>
      </p>
      <ol class="cd5 lst-kix_list_5-0" start="2">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">Hosting</span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Externes Hosting</span>
      </p>
      <p class="cd1">
        <span class="c0">Auftragsverarbeitung</span>
      </p>
      <ol class="cd5 lst-kix_list_5-0" start="3">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser Website
          </span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Hinweis zur verantwortlichen Stelle</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Rechtsgrundlagen f&uuml;r die Erhebung und Verarbeitung
          personenbezogener daten
        </span>
      </p>
      <p class="cd1">
        <span class="c0">Speicherdauer</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
          sowie gegen Direktwerbung (Art. 21 DSGVO)
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde
        </span>
      </p>
      <p class="cd1">
        <span class="c0">Recht auf Daten&uuml;bertragbarkeit</span>
      </p>
      <p class="cd1">
        <span class="c0">Auskunft, L&ouml;schung und Berichtigung</span>
      </p>
      <p class="cd1">
        <span class="c0">Recht auf Einschr&auml;nkung der Verarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">SSL- bzw. TLS-Verschl&uuml;sselung</span>
      </p>
      <ol class="cd5 lst-kix_list_5-0" start="4">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">Datenerfassung auf dieser Webseite</span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Cookies</span>
      </p>
      <p class="cd1">
        <span class="c0">Server-Log-Dateien</span>
      </p>
      <p class="cd1">
        <span class="c0">Kontaktformular</span>
      </p>
      <p class="cd1">
        <span class="c0">Anfrage per E-Mail, Telefon oder Telefax</span>
      </p>
      <ol class="cd5 lst-kix_list_5-0" start="5">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">Analyse-Tools und Werbung</span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Google Tag Manager</span>
      </p>
      <p class="cd1">
        <span class="c0">Google Analytics</span>
      </p>
      <p class="cd1">
        <span class="c0">Hotjar</span>
      </p>
      <p class="cd1">
        <span class="c0">Clarity</span>
      </p>
      <p class="cd1">
        <span class="c0">Google Ads</span>
      </p>
      <p class="cd1">
        <span class="c0">Google Conversion-Tracking</span>
      </p>
      <ol class="cd5 lst-kix_list_5-0" start="6">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">Plugins und Tools</span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Google reCAPTCHA</span>
      </p>
      <ol class="cd5 lst-kix_list_5-0" start="7">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">Audio- und Videokonferenzen</span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Datenverarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">Zweck und Rechtsgrundlagen</span>
      </p>
      <p class="cd1">
        <span class="c0">Speicherdauer</span>
      </p>
      <p class="cd1">
        <span class="c0">Eingesetzte Konferenz-Tools</span>
      </p>
      <p class="cd1">
        <span class="c0">Google Meet</span>
      </p>
      <p class="cd1">
        <span class="c0">Auftragsverarbeitung</span>
      </p>
      <ol class="cd5 lst-kix_list_5-0" start="8">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd6">Datenerfassung auf unserer Customer Plattform</span>
        </li>
      </ol>
      <p class="cd1">
        <span class="c0">Zweck der Verarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">Rechtsgrundlage</span>
      </p>
      <p class="cd1">
        <span class="c0">Dauer der Datenspeicherung</span>
      </p>
      <p class="cd1">
        <span class="c0">Google Analytics</span>
      </p>

      <ol class="cd5 lst-kix_list_4-0 start" start="1">
        <li class="cd1 cd9 li-bullet-0">
          <span class="cd10">Datenschutz auf einen Blick</span>
        </li>
      </ol>
      <p class="cd3">
        <span class="cd7">Begriff der personenbezogenen Daten</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die folgenden Hinweise geben einen einfachen &Uuml;berblick
          dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
          diese Website besuchen. &nbsp;Nach der DSGVO sind
          &bdquo;personenbezogene Daten&ldquo; alle Informationen, die sich auf
          eine identifizierte oder identifizierbare nat&uuml;rliche Person
          beziehen; dazu z&auml;hlen unter anderem ihr Name, Ihre
          E-Mail-Adresse, Ihre Telefonnummer, Ihr Geburtsdatum oder Ihre
          Anschrift. &nbsp;Auch Informationen dar&uuml;ber, wie Sie diese oder
          andere Webseiten nutzen, k&ouml;nnen personenbezogene Daten sein.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Datenerfassung auf dieser Website</span>
      </p>
      <p class="cd3">
        <span class="cd6">
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt
          &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser
          Datenschutzerkl&auml;rung entnehmen.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Wie erfassen wir Ihre Daten?</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie
          in ein Kontaktformular eingeben.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
          Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
          technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder
          Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
          automatisch, sobald Sie diese Website betreten.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Zweck der Verarbeitung</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur
          Analyse Ihres Nutzerverhaltens verwendet werden.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Rechtsgrundlage</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. f
          DSGVO. &nbsp;Der genannte Zweck stellt das berechtigte Interesse an
          der Datenverarbeitung im Sinne des Art. 6 Abs. 1 S. 1 lit. f DSGVO.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Dauer der Datenspeicherung</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Die Daten werden gel&ouml;scht, sobald sie f&uuml;r die Erreichung des
          Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist in der
          Regel nach sp&auml;testens einem Monat der Fall.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Widerspruchsm&ouml;glichkeit</span>
      </p>
      <p class="cd3">
        <span class="cd17">
          Soweit Daten im beschriebenen Umfang erfasst werden, ist dies f&uuml;r
          die Sicherung und den Betrieb der Website zwingend erforderlich. Es
          besteht daher keine Widerspruchsm&ouml;glichkeit.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">
          Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
          Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
          personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
          Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen.
          Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft
          widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten
          Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie
          sich jederzeit an uns wenden.
        </span>
      </p>
      <p class="cd1">
        <span class="cd7">Analyse-Tools und Tools von Drittanbietern</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in
          der folgenden Datenschutzerkl&auml;rung.
        </span>
      </p>
      <p class="cd3">
        <span class="cd10">2. Hosting</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Externes Hosting</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Diese Website wird extern gehostet. Die personenbezogenen Daten, die
          auf dieser Website erfasst werden, werden auf den Servern des Hosters
          / der Hoster gespeichert. Hierbei kann es sich v.&nbsp;a. um
          IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
          Daten, die &uuml;ber eine Website generiert werden, handeln.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung
          gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6
          Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
          effizienten Bereitstellung unseres Online-Angebots durch einen
          professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
          &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
          Cookies oder den Zugriff auf Informationen im Endger&auml;t des
          Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten,
          wie dies zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist
          und unsere Weisungen in Bezug auf diese Daten befolgen.
        </span>
      </p>
      <p class="cd3">
        <span class="c0">Wir setzen folgende(n) Hoster ein:</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Microsoft Limited UK
          <br />
          Microsoft Campus
          <br />
          <br />
          Thames Valley Park
          <br />
          Reading
          <br />
          Berkshire
          <br />
          RG6 1WG
          <br />
          UNITED KINGDOM
          <br />
          <br />
          Phone: (+44) 0344 800 2400
          <br />
          Fax: (+44) 0870 60 20 100
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Auftragsverarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
          Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es
          sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </span>
      </p>
      <p class="cd3">
        <span class="cd10">
          3. Allgemeine Hinweise und Pflichtinformationen
        </span>
      </p>

      <p class="cd3">
        <span class="cd7">Hinweis zur verantwortlichen Stelle</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
          Website ist:
        </span>
      </p>
      <p class="cd3">
        <span class="c0">
          Michael Dittel
          <br />
          Am Kr&ouml;gel 2<br />
          10179 Berlin
        </span>
      </p>
      <p class="cd3">
        <span class="c0">
          Telefon: +49 170 9529249
          <br />
          E-Mail: contact@leaftech.eu
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
          Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und
          Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen,
          E-Mail-Adressen o. &Auml;.) entscheidet.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">
          Rechtsgrundlagen f&uuml;r die Erhebung und Verarbeitung
          personenbezogener Daten
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Soweit wir f&uuml;r Verarbeitungsvorg&auml;nge personenbezogener Daten
          Ihre Einwilligung einholen, dient Art. 6 Abs. 1 S.1 lit. a DSGVO als
          Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer personenbezogenen
          Daten.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Bei der Verarbeitung Ihrer personenbezogenen Daten zur Erf&uuml;llung
          eines Vertrages zwischen Ihnen und Leaftech GmbH dient Art. 6 Abs. 1
          S.1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch f&uuml;r
          Verarbeitungsvorg&auml;nge, die zur Durchf&uuml;hrung vorvertraglicher
          Ma&szlig;nahmen erforderlich sind.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Soweit eine Verarbeitung personenbezogener Daten zur Erf&uuml;llung
          einer rechtlichen Verpflichtung erforderlich ist, der unser
          Unternehmen unterliegt, dient Art. 6 Abs. 1 S.1 lit. c DSGVO als
          Rechtsgrundlage.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres
          Unternehmens oder eines Dritten erforderlich und &uuml;berwiegen die
          Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
          erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 S.1 lit. f DSGVO
          als Rechtsgrundlage f&uuml;r die Verarbeitung.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Speicherdauer</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt.
          Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine
          Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
          gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen
          Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten
          haben (z.&nbsp;B. steuer- oder handelsrechtliche
          Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
          L&ouml;schung nach Fortfall dieser Gr&uuml;nde.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">
          Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA
          oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn
          diese Tools aktiv sind, k&ouml;nnen Ihre personenbezogene Daten in
          diese Drittstaaten &uuml;bertragen und dort verarbeitet werden. Wir
          weisen darauf hin, dass in diesen L&auml;ndern kein mit der EU
          vergleichbares Datenschutzniveau garantiert werden kann.
          Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene
          Daten an Sicherheitsbeh&ouml;rden herauszugeben, ohne dass Sie als
          Betroffener hiergegen gerichtlich vorgehen k&ouml;nnten. Es kann daher
          nicht ausgeschlossen werden, dass US-Beh&ouml;rden (z.&nbsp;B.
          Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
          &Uuml;berwachungszwecken verarbeiten, auswerten und dauerhaft
          speichern. Wir haben auf diese Verarbeitungst&auml;tigkeiten keinen
          Einfluss.
        </span>
      </p>
      <p class="cd1">
        <span class="cd12">
          F&uuml;r die USA gibt es keinen g&uuml;ltigen Angemessenheitsbeschluss
          der Europ&auml;ischen Kommission. Wir st&uuml;tzen uns bei der
          Daten&uuml;bermittlung im Bedarfsfall auf Standardvertragsklauseln.
          Standardvertragsklauseln sind schriftliche Zusagen zwischen Parteien,
          die als Grundlage f&uuml;r die Daten&uuml;bermittlung aus der EU in
          Drittl&auml;nder dienen k&ouml;nnen, indem sie geeignete Garantien zum
          Datenschutz zur Verf&uuml;gung stellen. Standardvertragsklauseln sind
          von der Europ&auml;ischen Kommission genehmigt worden und k&ouml;nnen
          von den Parteien, von denen sie genutzt werden, nicht ge&auml;ndert
          werden. Die von der Europ&auml;ischen Kommission erlassenen
          Standardvertragsklauseln finden Sie&nbsp;
        </span>
        <span class="cd16">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj&amp;sa=D&amp;source=editors&amp;ust=1689159517000111&amp;usg=AOvVaw0ow36HZg97-ED09d4B3c_d"
          >
            hier
          </a>
        </span>
        <span class="cd18">,&nbsp;</span>
        <span class="cd16">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/EN/TXT/?uri%3DCELEX%253A32010D0087&amp;sa=D&amp;source=editors&amp;ust=1689159517000510&amp;usg=AOvVaw0icO8D_uJ3WVmlXAuEEiS6"
          >
            hier
          </a>
        </span>
        <span class="cd18">&nbsp;</span>
        <span class="cd12">und</span>
        <span class="cd18">&nbsp;</span>
        <span class="cd16">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/EN/TXT/?uri%3DCELEX%253A32004D0915&amp;sa=D&amp;source=editors&amp;ust=1689159517000859&amp;usg=AOvVaw3sSKWLT_eHIMvzB84Y9Zb6"
          >
            hier
          </a>
        </span>
        <span class="cd18">.&nbsp;</span>
      </p>
      <p class="cd3">
        <span class="cd7">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
          ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die
          Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">
          Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen
          sowie gegen Direktwerbung (Art. 21 DSGVO)
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder
          f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gr&uuml;nden, die
          sich aus ihrer besonderen Situation ergeben, gegen die Verarbeitung
          ihrer personenbezogenen Daten Widerspruch einzulegen; dies gilt auch
          f&uuml;r ein auf diese Bestimmungen gest&uuml;tztes Profiling. die
          jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
          entnehmen sie dieser Datenschutzerkl&auml;rung. wenn sie Widerspruch
          einlegen, werden wir ihre betroffenen personenbezogenen Daten nicht
          mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende
          schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen,
          die ihre Interessen, Rechte und Freiheiten &uuml;berwiegen oder die
          Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung
          von Rechtsanspr&uuml;chen (Widerspruch nach art. 21 abs. 1 DSGVO).
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Werden ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
          betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung sie betreffender personenbezogener Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch f&uuml;r das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. wenn sie
          widersprechen, werden ihre personenbezogenen Daten anschlie&szlig;end
          nicht mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach
          art. 21 abs. 2 DSGVO).
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">
          Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
          Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
          insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts,
          ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen
          Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
          anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Recht auf Daten&uuml;bertragbarkeit</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an
          sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren
          Format aush&auml;ndigen zu lassen. Sofern Sie die direkte
          &Uuml;bertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Auskunft, L&ouml;schung und Berichtigung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Recht auf Einschr&auml;nkung der Verarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
          jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der
          Verarbeitung besteht in folgenden F&auml;llen:
        </span>
      </p>
      <ul class="cd5 lst-kix_list_1-0 start">
        <li class="cd1 cd9 li-bullet-0">
          <span class="c0">
            Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
            personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
            Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
            Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </span>
        </li>
        <li class="cd1 cd9 li-bullet-0">
          <span class="c0">
            Wenn die Verarbeitung Ihrer personenbezogenen Daten
            unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
            L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
            verlangen.
          </span>
        </li>
        <li class="cd1 cd9 li-bullet-0">
          <span class="c0">
            Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie
            sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von
            Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
            L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen.
          </span>
        </li>
        <li class="cd1 cd9 li-bullet-0">
          <span class="c0">
            Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
            haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
            vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
            &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </span>
        </li>
      </ul>
      <p class="cd1">
        <span class="c0">
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
          eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer
          Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur
          Geltendmachung, Aus&uuml;bung oder Verteidigung von
          Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
          nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines
          wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union
          oder eines Mitgliedstaats verarbeitet werden.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">SSL- bzw. TLS-Verschl&uuml;sselung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
          &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
          oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-
          bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung
          erkennen Sie daran, dass die Adresszeile des Browsers von
          &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile.
        </span>
      </p>
      <p class="cd3">
        <span class="c0">
          Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen
          die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
          mitgelesen werden.
        </span>
      </p>
      <p class="cd3">
        <span class="cd10">4. Datenerfassung auf dieser Website</span>
      </p>
      <p class="cd3">
        <span class="cd7">Cookies</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
          Cookies sind kleine Datenpakete und richten auf Ihrem Endger&auml;t
          keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die
          Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente
          Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden
          nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies
          bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst
          l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren
          Webbrowser erfolgt.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem
          Endger&auml;t gespeichert werden, wenn Sie unsere Seite betreten
          (Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die
          Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.&nbsp;B.
          Cookies zur Abwicklung von Zahlungsdienstleistungen).
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht
          funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion oder die
          Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten
          auszuwerten&nbsp;oder Werbung anzuzeigen.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Cookies, die zur Durchf&uuml;hrung des elektronischen
          Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
          erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die
          Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B.
          Cookies zur Messung des Webpublikums) erforderlich sind (notwendige
          Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO
          gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
          Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
          notwendigen Cookies zur technisch fehlerfreien und optimierten
          Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
          Speicherung von Cookies und vergleichbaren
          Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
          lit. a DSGVO und &sect; 25 Abs. 1 TTDSG); die Einwilligung ist
          jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
          Setzen von Cookies informiert werden und Cookies nur im Einzelfall
          erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder
          generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
          Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei der
          Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website
          eingeschr&auml;nkt sein.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
          werden, werden wir Sie hier&uuml;ber im Rahmen dieser
          Datenschutzerkl&auml;rung gesondert informieren und ggf. eine
          Einwilligung abfragen.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Server-Log-Dateien</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
          &uuml;bermittelt. Dies sind:
        </span>
      </p>
      <ul class="cd5 lst-kix_list_2-0 start">
        <li class="cd2 li-bullet-0">
          <span class="c0">Browsertyp und Browserversion</span>
        </li>
        <li class="cd2 li-bullet-0">
          <span class="c0">verwendetes Betriebssystem</span>
        </li>
        <li class="cd2 li-bullet-0">
          <span class="c0">Referrer URL</span>
        </li>
        <li class="cd2 li-bullet-0">
          <span class="c0">Hostname des zugreifenden Rechners</span>
        </li>
        <li class="cd2 li-bullet-0">
          <span class="c0">Uhrzeit der Serveranfrage</span>
        </li>
        <li class="cd2 li-bullet-0">
          <span class="c0">IP-Adresse</span>
        </li>
      </ul>
      <p class="cd1">
        <span class="c0">
          Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird
          nicht vorgenommen.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
          der technisch fehlerfreien Darstellung und der Optimierung seiner
          Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst
          werden.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Kontaktformular</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r
          den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben
          wir nicht ohne Ihre Einwilligung weiter.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde; die Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
          uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
          entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer
          Anfrage). Zwingende gesetzliche Bestimmungen &ndash; insbesondere
          Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Anfrage per E-Mail, Telefon oder Telefax</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines
          Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
          vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
          &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde; die Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
          verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
          Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
          Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
          &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
          unber&uuml;hrt.
        </span>
      </p>
      <p class="cd3">
        <span class="cd10">5. Analyse-Tools und Werbung</span>
      </p>
      <p class="cd3">
        <span class="cd7">Google Tag Manager</span>
      </p>
      <p class="cd3">
        <span class="c0">
          Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland
          Limited, Gordon House, Barrow Street, Dublin 4, Irland.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking-
          oder Statistik-Tools und andere Technologien auf unserer Website
          einbinden k&ouml;nnen. Der Google Tag Manager selbst erstellt keine
          Nutzerprofile, speichert keine Cookies und nimmt keine
          eigenst&auml;ndigen Analysen vor. Er dient lediglich der Verwaltung
          und Ausspielung der &uuml;ber ihn eingebundenen Tools. Der Google Tag
          Manager erfasst jedoch Ihre IP-Adresse, die auch an das
          Mutterunternehmen von Google in die Vereinigten Staaten
          &uuml;bertragen werden kann.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse an einer schnellen und unkomplizierten Einbindung und
          Verwaltung verschiedener Tools auf seiner Website. Sofern eine
          entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und
          &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
          Cookies oder den Zugriff auf Informationen im Endger&auml;t des
          Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst.
          Die Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Google Analytics</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Diese Website nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Ireland Limited
          (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Google Analytics erm&ouml;glicht es dem Websitebetreiber, das
          Verhalten der Websitebesucher zu analysieren. Hierbei erh&auml;lt der
          Websitebetreiber verschiedene Nutzungsdaten, wie z.&nbsp;B.
          Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft
          des Nutzers. Diese Daten werden dem jeweiligen Endger&auml;t des Users
          zugeordnet. Eine Zuordnung zu einer User-ID erfolgt nicht.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Des Weiteren k&ouml;nnen wir mit Google Analytics u.&nbsp;a. Ihre
          Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet
          Google Analytics verschiedene Modellierungsans&auml;tze, um die
          erfassten Datens&auml;tze zu erg&auml;nzen und setzt
          Machine-Learning-Technologien bei der Datenanalyse ein.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Google Analytics verwendet Technologien, die die Wiedererkennung des
          Nutzers zum Zwecke der Analyse des Nutzerverhaltens erm&ouml;glichen
          (z.&nbsp;B. Cookies oder Device-Fingerprinting). Die von Google
          erfassten Informationen &uuml;ber die Benutzung dieser Website werden
          in der Regel an einen Server von Google in den USA &uuml;bertragen und
          dort gespeichert.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
          nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
          Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd1">
        <span class="cd12">
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://privacy.google.com/businesses/controllerterms/mccs/&amp;sa=D&amp;source=editors&amp;ust=1689159517007635&amp;usg=AOvVaw1NT9ThxWt5TlADURHB_fos"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd6">Browser Plugin</span>
      </p>
      <p class="cd3">
        <span class="cd12">
          Sie k&ouml;nnen die Erfassung und Verarbeitung Ihrer Daten durch
          Google verhindern, indem Sie das unter dem folgenden Link
          verf&uuml;gbare Browser-Plugin herunterladen und installieren:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Dde&amp;sa=D&amp;source=editors&amp;ust=1689159517008333&amp;usg=AOvVaw3uNYDzp7nbDIqI3SKV_yh3"
          >
            https://tools.google.com/dlpage/gaoptout?hl=de
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd12">
          Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
          finden Sie in der Datenschutzerkl&auml;rung von Google:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://support.google.com/analytics/answer/6004245?hl%3Dde&amp;sa=D&amp;source=editors&amp;ust=1689159517008841&amp;usg=AOvVaw1t5E5oXXFGPNuEz8IgJ-l_"
          >
            https://support.google.com/analytics/answer/6004245?hl=de
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd6">Google-Signale</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir nutzen Google-Signale. Wenn Sie unsere Website besuchen, erfasst
          Google Analytics u.&nbsp;a. Ihren Standort, Suchverlauf und
          YouTube-Verlauf sowie demografische Daten (Besucherdaten). Diese Daten
          k&ouml;nnen mit Hilfe von Google-Signal f&uuml;r personalisierte
          Werbung verwendet werden. Wenn Sie &uuml;ber ein Google-Konto
          verf&uuml;gen, werden die Besucherdaten von Google-Signal mit Ihrem
          Google-Konto verkn&uuml;pft und f&uuml;r personalisierte
          Werbebotschaften verwendet. Die Daten werden au&szlig;erdem f&uuml;r
          die Erstellung anonymisierter Statistiken zum Nutzerverhalten unserer
          User verwendet.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Auftragsverarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
          abgeschlossen und setzen die strengen Vorgaben der deutschen
          Datenschutzbeh&ouml;rden bei der Nutzung von Google Analytics
          vollst&auml;ndig um.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Hotjar</span>
      </p>
      <p class="cd1">
        <span class="cd12">
          Diese Website nutzt Hotjar. Anbieter ist die Hotjar Ltd., Level 2, St
          Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000,
          Malta, Europe (Website:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://www.e-recht24.de/mitglieder/tools/projekt-manager-20/&amp;sa=D&amp;source=editors&amp;ust=1689159517009870&amp;usg=AOvVaw3_HTQNcVZ-K9YRNrmFqp46"
          >
            https://www.hotjar.com
          </a>
        </span>
        <span class="c0">).</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Hotjar ist ein Werkzeug zur Analyse Ihres Nutzerverhaltens auf dieser
          Website. Mit Hotjar k&ouml;nnen wir u.&nbsp;a. Ihre Maus- und
          Scrollbewegungen und Klicks aufzeichnen. Hotjar kann dabei auch
          feststellen, wie lange Sie mit dem Mauszeiger auf einer bestimmten
          Stelle verblieben sind. Aus diesen Informationen erstellt Hotjar
          sogenannte Heatmaps, mit denen sich feststellen l&auml;sst, welche
          Websitebereiche vom Websitebesucher bevorzugt angeschaut werden.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Des Weiteren k&ouml;nnen wir feststellen, wie lange Sie auf einer
          Seite verblieben sind und wann Sie sie verlassen haben. Wir
          k&ouml;nnen auch feststellen, an welcher Stelle Sie Ihre Eingaben in
          ein Kontaktformular abgebrochen haben (sog. Conversion-Funnels).
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Dar&uuml;ber hinaus k&ouml;nnen mit Hotjar direkte Feedbacks von
          Websitebesuchern eingeholt werden. Diese Funktion dient der
          Verbesserung der Webangebote des Websitebetreibers.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Hotjar verwendet Technologien, die die Wiedererkennung des Nutzers zum
          Zwecke der Analyse des Nutzerverhaltens erm&ouml;glichen (z.&nbsp;B.
          Cookies oder Einsatz von Device-Fingerprinting).
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
          Einsatz deso.&nbsp;g.Dienstes ausschlie&szlig;lich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 TTDSG. Die Einwilligung ist
          jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde,
          erfolgt die Verwendung dieses Dienstes auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO; der Websitebetreiber hat ein berechtigtes Interesse an
          der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch
          seine Werbung zu optimieren.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Deaktivieren von Hotjar</span>
      </p>
      <p class="cd1">
        <span class="cd12">
          Wenn Sie die Datenerfassung durch Hotjar deaktivieren m&ouml;chten,
          klicken Sie auf folgenden Link und folgen Sie den dortigen
          Anweisungen:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://www.hotjar.com/policies/do-not-track/&amp;sa=D&amp;source=editors&amp;ust=1689159517010870&amp;usg=AOvVaw0m8PwiNlV7OPAkzEOUx3LO"
          >
            https://www.hotjar.com/policies/do-not-track/
          </a>
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Bitte beachten Sie, dass die Deaktivierung von Hotjar f&uuml;r jeden
          Browser bzw. f&uuml;r jedes Endger&auml;t separat erfolgen muss.
        </span>
      </p>
      <p class="cd1">
        <span class="cd12">
          N&auml;here Informationen &uuml;ber Hotjar und zu den erfassten Daten
          entnehmen Sie der Datenschutzerkl&auml;rung von Hotjar unter dem
          folgenden Link:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://www.hotjar.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1689159517011368&amp;usg=AOvVaw2sK-X0oN-GBVUS__EPYJ6K"
          >
            https://www.hotjar.com/privacy
          </a>
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Auftragsverarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
          Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es
          sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Clarity</span>
      </p>
      <p class="cd1">
        <span class="cd12">
          Diese Website nutzt Clarity. Anbieter ist die Microsoft Ireland
          Operations Limited, One Microsoft Place, South County Business Park,
          Leopardstown, Dublin 18, Irland,&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://docs.microsoft.com/en-us/clarity/&amp;sa=D&amp;source=editors&amp;ust=1689159517012131&amp;usg=AOvVaw3YObITz16IYeZUcgLzYy3e"
          >
            https://docs.microsoft.com/en-us/clarity/
          </a>
        </span>
        <span class="c0">&nbsp;(im Folgenden &bdquo;Clarity&ldquo;).</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Clarity ist ein Werkzeug zur Analyse des Nutzerverhaltens auf dieser
          Website. Hierbei erfasst Clarity insbesondere Mausbewegungen und
          erstellt eine grafische Darstellung dar&uuml;ber, auf welchen Teil der
          Website Nutzer besonders h&auml;ufig scrollen (Heatmaps). Clarity kann
          ferner Sitzungen aufzeichnen, sodass wir die Seitennutzung in Form von
          Videos ansehen k&ouml;nnen. Ferner erhalten wir Angaben &uuml;ber das
          allgemeine Nutzerverhalten innerhalb unserer Website.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Clarity verwendet Technologien, die die Wiedererkennung des Nutzers
          zum Zwecke der Analyse des Nutzerverhaltens erm&ouml;glichen
          (z.&nbsp;B. Cookies oder Einsatz von Device-Fingerprinting). Ihre
          personenbezogenen Daten werden auf den Servern von Microsoft
          (Microsoft Azure Cloud Service) in den USA gespeichert.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der
          Einsatz des o.&nbsp;g. Dienstes ausschlie&szlig;lich auf Grundlage von
          Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 TTDSG. Die Einwilligung ist
          jederzeit widerrufbar. Soweit keine Einwilligung eingeholt wurde,
          erfolgt die Verwendung dieses Dienstes auf Grundlage von Art. 6 Abs. 1
          lit. f DSGVO; der Websitebetreiber hat ein berechtigtes Interesse an
          einer effektiven Nutzeranalyse.
        </span>
      </p>
      <p class="cd3">
        <span class="cd12">
          Weitere Details zum Datenschutz von Clarity finden Sie hier:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://docs.microsoft.com/en-us/clarity/faq&amp;sa=D&amp;source=editors&amp;ust=1689159517012898&amp;usg=AOvVaw0BBAz0cd8EiuR80rB_7TY45"
          >
            https://docs.microsoft.com/en-us/clarity/faq
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd6">Auftragsverarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
          Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es
          sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </span>
      </p>
      <p class="cd3">
        <span class="cd7">Google Ads</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
          Online-Werbeprogramm der Google Ireland Limited
          (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Google Ads erm&ouml;glicht es uns Werbeanzeigen in der
          Google-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn der
          Nutzer bestimmte Suchbegriffe bei Google eingibt (Keyword-Targeting).
          Ferner k&ouml;nnen zielgerichtete Werbeanzeigen anhand der bei Google
          vorhandenen Nutzerdaten (z.&nbsp;B. Standortdaten und Interessen)
          ausgespielt werden (Zielgruppen-Targeting). Wir als Websitebetreiber
          k&ouml;nnen diese Daten quantitativ auswerten, indem wir
          beispielsweise analysieren, welche Suchbegriffe zur Ausspielung
          unserer Werbeanzeigen gef&uuml;hrt haben und wie viele Anzeigen zu
          entsprechenden Klicks gef&uuml;hrt haben.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
          nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
          Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd3">
        <span class="cd12">
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://policies.google.com/privacy/frameworks&amp;sa=D&amp;source=editors&amp;ust=1689159517014117&amp;usg=AOvVaw2DxPXWlwauThGGZ-tMRIkx"
          >
            https://policies.google.com/privacy/frameworks
          </a>
        </span>
        <span class="cd12">&nbsp;und&nbsp;</span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://privacy.google.com/businesses/controllerterms/mccs/&amp;sa=D&amp;source=editors&amp;ust=1689159517014515&amp;usg=AOvVaw3ndEcZcd7rTgG72Qe18DAfi"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd7">Google Conversion-Tracking</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Diese Website nutzt Google Conversion Tracking. Anbieter ist die
          Google Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow
          Street, Dublin 4, Irland.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Mit Hilfe von Google-Conversion-Tracking k&ouml;nnen Google und wir
          erkennen, ob der Nutzer bestimmte Aktionen durchgef&uuml;hrt hat. So
          k&ouml;nnen wir beispielsweise auswerten, welche Buttons auf unserer
          Website wie h&auml;ufig geklickt und welche Produkte besonders
          h&auml;ufig angesehen oder gekauft wurden. Diese Informationen dienen
          dazu, Conversion-Statistiken zu erstellen. Wir erfahren die
          Gesamtanzahl der Nutzer, die auf unsere Anzeigen geklickt haben und
          welche Aktionen sie durchgef&uuml;hrt haben. Wir erhalten keine
          Informationen, mit denen wir den Nutzer pers&ouml;nlich identifizieren
          k&ouml;nnen. Google selbst nutzt zur Identifikation Cookies oder
          vergleichbare Wiedererkennungstechnologien.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
          nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
          Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd1">
        <span class="cd12">
          Mehr Informationen zu Google Conversion-Tracking finden Sie in den
          Datenschutzbestimmungen von Google:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Dde&amp;sa=D&amp;source=editors&amp;ust=1689159517015329&amp;usg=AOvVaw2PrOrhYfD28tMDLKNezgeN"
          >
            https://policies.google.com/privacy?hl=de
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd10">6. Plugins und Tools</span>
      </p>
      <p class="cd3">
        <span class="cd7">Google reCAPTCHA</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir nutzen &bdquo;Google reCAPTCHA&ldquo; (im Folgenden
          &bdquo;reCAPTCHA&ldquo;) auf dieser Website. Anbieter ist die Google
          Ireland Limited (&bdquo;Google&ldquo;), Gordon House, Barrow Street,
          Dublin 4, Irland.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Mit reCAPTCHA soll &uuml;berpr&uuml;ft werden, ob die Dateneingabe auf
          dieser Website (z.&nbsp;B. in einem Kontaktformular) durch einen
          Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
          analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
          verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der
          Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
          verschiedene Informationen aus (z.&nbsp;B. IP-Adresse, Verweildauer
          des Websitebesuchers auf der Website oder vom Nutzer get&auml;tigte
          Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google
          weitergeleitet.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die reCAPTCHA-Analysen laufen vollst&auml;ndig im Hintergrund.
          Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
          stattfindet.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
          Interesse daran, seine Webangebote vor missbr&auml;uchlicher
          automatisierter Aussp&auml;hung und vor SPAM zu sch&uuml;tzen. Sofern
          eine entsprechende Einwilligung abgefragt wurde, erfolgt die
          Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit.
          a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die
          Speicherung von Cookies oder den Zugriff auf Informationen im
          Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne
          des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd3">
        <span class="cd12">
          Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
          Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen
          unter folgenden Links:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Dde&amp;sa=D&amp;source=editors&amp;ust=1689159517016633&amp;usg=AOvVaw3o717acBAvMWS8sLSpun8d"
          >
            https://policies.google.com/privacy?hl=de
          </a>
        </span>
        <span class="cd12">&nbsp;und&nbsp;</span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://policies.google.com/terms?hl%3Dde&amp;sa=D&amp;source=editors&amp;ust=1689159517016942&amp;usg=AOvVaw2ZAM-SPOgR7nlCC0JEk7pP"
          >
            https://policies.google.com/terms?hl=de
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd10">7. Audio- und Videokonferenzen</span>
      </p>
      <p class="cd3">
        <span class="cd6">Datenverarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          F&uuml;r die Kommunikation mit unseren Kunden setzen wir unter anderen
          Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten Tools
          sind unten aufgelistet. Wenn Sie mit uns per Video- oder
          Audiokonferenz via Internet kommunizieren, werden Ihre
          personenbezogenen Daten von uns und dem Anbieter des jeweiligen
          Konferenz-Tools erfasst und verarbeitet.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung der
          Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre
          Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer der
          Konferenz, Beginn und Ende (Zeit) der Teilnahme an der Konferenz,
          Anzahl der Teilnehmer und sonstige &bdquo;Kontextinformationen&ldquo;
          im Zusammenhang mit dem Kommunikationsvorgang (Metadaten).
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Des Weiteren verarbeitet der Anbieter des Tools alle technischen
          Daten, die zur Abwicklung der Online-Kommunikation erforderlich sind.
          Dies umfasst insbesondere IP-Adressen, MAC-Adressen, Ger&auml;te-IDs,
          Ger&auml;tetyp, Betriebssystemtyp und -version, Client-Version,
          Kameratyp, Mikrofon oder Lautsprecher sowie die Art der Verbindung.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder in
          sonstiger Weise bereitgestellt werden, werden diese ebenfalls auf den
          Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten z&auml;hlen
          insbesondere Cloud-Aufzeichnungen, Chat-/ Sofortnachrichten,
          Voicemails hochgeladene Fotos und Videos, Dateien, Whiteboards und
          andere Informationen, die w&auml;hrend der Nutzung des Dienstes
          geteilt werden.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Bitte beachten Sie, dass wir nicht vollumf&auml;nglich Einfluss auf
          die Datenverarbeitungsvorg&auml;nge der verwendeten Tools haben.
          Unsere M&ouml;glichkeiten richten sich ma&szlig;geblich nach der
          Unternehmenspolitik des jeweiligen Anbieters. Weitere Hinweise zur
          Datenverarbeitung durch die Konferenztools entnehmen Sie den
          Datenschutzerkl&auml;rungen der jeweils eingesetzten Tools, die wir
          unter diesem Text aufgef&uuml;hrt haben.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Zweck und Rechtsgrundlagen</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Konferenz-Tools werden genutzt, um mit angehenden oder bestehenden
          Vertragspartnern zu kommunizieren oder bestimmte Leistungen
          gegen&uuml;ber unseren Kunden anzubieten (Art. 6 Abs. 1 lit. b DSGVO).
          Des Weiteren dient der Einsatz der Tools der allgemeinen Vereinfachung
          und Beschleunigung der Kommunikation mit uns bzw. unserem Unternehmen
          (berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO).
          Soweit eine Einwilligung abgefragt wurde, erfolgt der Einsatz der
          betreffenden Tools auf Grundlage dieser Einwilligung; die Einwilligung
          ist jederzeit mit Wirkung f&uuml;r die Zukunft widerrufbar.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Speicherdauer</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die unmittelbar von uns &uuml;ber die Video- und Konferenz-Tools
          erfassten Daten werden von unseren Systemen gel&ouml;scht, sobald Sie
          uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung
          widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt.
          Gespeicherte Cookies verbleiben auf Ihrem Endger&auml;t, bis Sie sie
          l&ouml;schen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
          unber&uuml;hrt.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
          Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
          keinen Einfluss. F&uuml;r Einzelheiten dazu informieren Sie sich bitte
          direkt bei den Betreibern der Konferenz-Tools.
        </span>
      </p>
      <p class="cd3">
        <span class="cd6">Eingesetzte Konferenz-Tools</span>
      </p>
      <p class="cd3">
        <span class="c0">Wir setzen folgende Konferenz-Tools ein:</span>
      </p>
      <p class="cd3">
        <span class="cd7">Google Meet</span>
      </p>
      <p class="cd1">
        <span class="cd12">
          Wir nutzen Google Meet. Anbieter ist die Google Ireland Limited,
          Gordon House, Barrow Street, Dublin 4, Irland. Details zur
          Datenverarbeitung entnehmen Sie der Datenschutzerkl&auml;rung von
          Google:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://policies.google.com/privacy?hl%3Dde&amp;sa=D&amp;source=editors&amp;ust=1689159517018924&amp;usg=AOvVaw1ioXqT7Traa2Md-eV-kpiC"
          >
            https://policies.google.com/privacy?hl=de
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd3">
        <span class="cd6">Auftragsverarbeitung</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur
          Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es
          sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
        </span>
      </p>
      <p class="cd3">
        <span class="cd10">
          8. Datenerfassung auf unserer Customer Plattform
        </span>
      </p>
      <p class="cd21">
        <span class="c0">
          Wenn Sie ein Konto bei uns zur Verwendung unserer Customer Plattform
          einrichten, erfassen wir personenbezogene Daten, die mit Ihrem Konto
          verbunden sind, einschlie&szlig;lich Ihres Namens, Ihrer
          Kontaktinformationen, Ihrer Kontodaten, Ihrer
          Zahlungskarteninformationen und Ihrer Transaktionshistorie
          (zusammenfassend als &quot;Kontodaten&quot; bezeichnet).
        </span>
      </p>
      <p class="cd14">
        <span class="cd6">Zweck der Verarbeitung</span>
      </p>
      <p class="cd21">
        <span class="c0">
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          und eine vertragsgem&auml;&szlig;e Verwendung der Customer Plattform
          zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </span>
      </p>
      <p class="cd14">
        <span class="cd6">Rechtsgrundlage</span>
      </p>
      <p class="cd21">
        <span class="c0">
          Die Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b
          DSGVO. &nbsp;Der genannte Zweck stellt die Erf&uuml;llung eines
          Vertrags im Sinne des Art. 6 Abs. 1 S. 1 lit. b DSGVO dar.
        </span>
      </p>
      <p class="cd14">
        <span class="cd6">Dauer der Datenspeicherung</span>
      </p>
      <p class="cd21">
        <span class="c0">
          Wir werden Ihre Daten, die wir im Rahmen der Kontaktaufnahme erhalten
          haben, l&ouml;schen, sobald sie f&uuml;r die Erreichung des Zwecks
          ihrer Erhebung nicht mehr ben&ouml;tigt werden, Ihr Anliegen also
          vollst&auml;ndig bearbeitet ist und keine weitere Kommunikation mit
          Ihnen erforderlich ist oder von ihnen gew&uuml;nscht wird.
        </span>
      </p>
      <p class="cd14">
        <span class="cd6">Google Analytics</span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Customer Plattform nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Ireland Limited
          (&bdquo;Google&ldquo;), Gordon House, Barrow Street, Dublin 4, Irland.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Google Analytics erm&ouml;glicht es dem Websitebetreiber, das
          Verhalten der Websitebesucher zu analysieren. Hierbei erh&auml;lt der
          Websitebetreiber verschiedene Nutzungsdaten, wie z.&nbsp;B.
          Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und Herkunft
          des Nutzers. Diese Daten werden dem jeweiligen Endger&auml;t des Users
          zugeordnet. Eine Zuordnung zu einer User-ID erfolgt nicht.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Des Weiteren k&ouml;nnen wir mit Google Analytics u.&nbsp;a. Ihre
          Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner verwendet
          Google Analytics verschiedene Modellierungsans&auml;tze, um die
          erfassten Datens&auml;tze zu erg&auml;nzen und setzt
          Machine-Learning-Technologien bei der Datenanalyse ein.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Google Analytics verwendet Technologien, die die Wiedererkennung des
          Nutzers zum Zwecke der Analyse des Nutzerverhaltens erm&ouml;glichen
          (z.&nbsp;B. Cookies oder Device-Fingerprinting). Die von Google
          erfassten Informationen &uuml;ber die Benutzung dieser Website werden
          in der Regel an einen Server von Google in den USA &uuml;bertragen und
          dort gespeichert.
        </span>
      </p>
      <p class="cd1">
        <span class="c0">
          Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung
          nach Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG. Die
          Einwilligung ist jederzeit widerrufbar.
        </span>
      </p>
      <p class="cd1">
        <span class="cd12">
          Die Daten&uuml;bertragung in die USA wird auf die
          Standardvertragsklauseln der EU-Kommission gest&uuml;tzt. Details
          finden Sie hier:&nbsp;
        </span>
        <span class="cd4">
          <a
            class="cd8"
            href="https://www.google.com/url?q=https://privacy.google.com/businesses/controllerterms/mccs/&amp;sa=D&amp;source=editors&amp;ust=1689159517021000&amp;usg=AOvVaw177ZkdMuQv6ZHXjGqi1G0_"
          >
            https://privacy.google.com/businesses/controllerterms/mccs/
          </a>
        </span>
        <span class="c0">.</span>
      </p>
      <p class="cd13 cd22">
        <span class="cd11"></span>
      </p>
    </body>
  );
};
