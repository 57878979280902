import { Stack } from "@mui/material";
import React from "react";
import LegendBullet from "./LegendBullet";

export const GeneralChartLegend = ({
  legends,
  className,
}) => {
  return (
    <Stack spacing={4} className={`flex-wrap ${className}`}>
      {legends?.map((legend, index) => {
        return (
          <Stack spacing={2} key={index} >
            <LegendBullet
              width="0.8rem"
              height="0.8rem"
              type="circle"
              color={legend.color}
            />
            <span className="t-body-xl">{legend.label}</span>
            <span className="t-body-xl text-gray-500">{legend.value}</span>
          </Stack>
        );
      })}
    </Stack>
  );
};
