import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Grid, Menu, MenuItem, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import { GrPowerReset } from "react-icons/gr";
import { TfiSave } from "react-icons/tfi";
import "rsuite/dist/rsuite-no-reset.min.css";
import { LoadingOrEmptyWrapper } from "../../components/LoadingAndEmptyHandler";
import { deepEqual, distinctFilter } from "../../utils/dataManipulation";

import "react-resizable/css/styles.css";
import { ButtonNew, Chip, CustomModal } from "../../components";
// import { GeneralErrorBoundary } from "../../ErrorBoundary";
import { Icon } from "../../components";
import { DynamicDashboard } from "../../components/chart/DynamicDashboard";
import { Divider } from "../../components/Divider";
import { DynamicFilterFactory } from "../../components/DynamicFormInput";
import { ColorInput } from "../../components/Input";
import { ScrollableTabsButtonAuto } from "../../components/Tabs";
import { chartTypesEnums, valuesEnums, colorCategoriesEnums } from "../../enums/dynamicDashboard";
import { theme } from "../../styles/theme";
import { diagramInitialConfig } from "../../utils/dynamicDashboard";
import { LocalStorageService } from "../../utils/localStorage";
import { FilterTypes } from "../../enums/components";
import { eventNames, withGAEventTracking } from "../../functions/googleAnalytics";


const defaultMinW = 3;
const defaultMinH = 3;

const defaultChart = {
    type: "1",
    // i: "0",
    id: 0,
    x: 0,
    y: 0,
    w: defaultMinW + 1,
    h: defaultMinH + 2,
    minW: defaultMinW,
    minH: defaultMinH,
    maxH: 20,
    draggable: true,
    diagrams: [diagramInitialConfig],
    sortValue: null,
    chartType: null,
    title: null,
    xAxis: null,
    yAxis: null,
    dataPoint: null,
    legendsPosition: 'bottom',
};


const DashboardTab = ({ value, setValue, onDelete, hadDeleteIcon }) => {
    const onChange = (e) => {
        setValue(e.target.value)
    }

    const [hovered, setHovered] = useState(false)

    const inputRef = useRef(null);
    const spanHiddenRef = useRef(null);
    const spanVisibleRef = useRef(null);

    useEffect(() => {
        if (spanHiddenRef.current && inputRef.current) {
            inputRef.current.style.width = `${spanHiddenRef.current.offsetWidth + 24}px`;
            if (spanVisibleRef.current)
                spanVisibleRef.current.style.width = `${spanHiddenRef.current.offsetWidth + 24}px`;
        }
    }, [value, hovered]);

    return (
        <>
            {hovered &&
                <>
                    <input
                        ref={inputRef}
                        style={{
                            border: 'none'
                        }}
                        className="min-w-[5rem] h-8 p-2 text-center t-subheading-m capitalize bg-transparent"
                        value={value}
                        onChange={onChange}
                        onBlur={() => {
                            setHovered(false)
                        }}
                    />

                    <span ref={spanHiddenRef} className="t-subheading-m capitalize" style={{ height: 0, visibility: 'hidden', whiteSpace: 'pre' }}>
                        {value || ' '}
                    </span>
                </>
            }
            {!hovered &&
                <span
                    ref={spanVisibleRef}
                    onClick={() => setHovered(true)}
                    // onDoubleClick={() => setHovered(false)}
                    className="min-w-[5rem] h-8 text-center p-2 t-subheading-m capitalize bg-transparent cursor-pointer"
                >
                    {value}
                </span>
            }
            {hadDeleteIcon &&
                <Icon onClick={onDelete} iconName={'Close'} color={'white'}
                    className='absolute top-2 right-2 w-[1rem] h-[1rem]' />
            }
        </>
    )

}

const Header = ({ portfolioConfigs,
    defaultDashboardConfigsHasChanged,
    dashboardConfigsHasChanged,
    onResetDashboardConfigs,
    saveDashboardConfigs,
    addChart,
    tabs,
    addTab,
    deleteTab,
    updateTabName,
    currentTabIndex,
    setCurrentTabIndex,
    onOpenColors
}) => {
    const { t } = useTranslation()

    const tabsLabelsComponents = tabs?.map((tab, index) => {
        return {
            label: <DashboardTab
                value={tab}
                setValue={(newValue) => { updateTabName(newValue, index) }}
                onDelete={() => deleteTab(index)}
                hadDeleteIcon={!(tabs?.length === 1)}
            />
        }
    })

    const [modalOpen, setModalOpen] = useState(false)

    return (
        <Stack className=" -ml-4 justify-between">
            <ScrollableTabsButtonAuto
                tabs={tabs}
                addTab={addTab}
                tabsLabelsComponents={tabsLabelsComponents}
                setCurrentTabIndex={setCurrentTabIndex}
                currentTabIndex={currentTabIndex}
                className=' flex-1 max-w-[800px]' />

            <Stack gap={4} className="items-center justify-end w-150">
                <ButtonNew
                    variant="primary"
                    size="md"
                    onClick={onOpenColors}
                >
                    <Stack gap={2}>
                        <Icon iconName={'ColorPalette'} svgClassName="w-4 h-4" />
                        <span>Colors</span>
                    </Stack>
                </ButtonNew>

                {defaultDashboardConfigsHasChanged && (
                    <>
                        <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} height={300}>
                            <Stack flexDirection={'column'} className="justify-between h-full -mt-4">
                                <Stack flexDirection={'column'} className="t-heading-m">
                                    <Icon className={'mb-4 !h-10 !w-10'} svgClassName={'!h-10 !w-10'} iconName={'Info'} color={'var(--clr-mystic-red-500)'} />
                                    <h3>{t('Portfolio.resetSettings')}</h3>
                                    <p className="t-subheading-m text-red-500">{t('Portfolio.settingsWillBeGone')}</p>
                                </Stack>
                                <Stack className="gap-8">
                                    <ButtonNew
                                        variant="secondary"
                                        size="md"
                                        onClick={() => setModalOpen(false)}
                                        className={'capitalize'}
                                    >
                                        {t('general.cancel')}
                                    </ButtonNew>

                                    <ButtonNew
                                        variant="primary"
                                        size="md"
                                        onClick={() => {
                                            onResetDashboardConfigs()
                                            setModalOpen(false)
                                        }}
                                    >
                                        {t('general.resetAnyway')}
                                    </ButtonNew>
                                </Stack>
                            </Stack>

                        </CustomModal>
                        <ButtonNew
                            variant="primary"
                            size="md"
                            onClick={() => setModalOpen(true)}
                        >
                            <Stack gap={2}>
                                <GrPowerReset className="cursor-pointer w-4 h-4" />
                                <span>Reset To Default</span>
                            </Stack>
                        </ButtonNew>
                    </>
                )}
                {dashboardConfigsHasChanged && (
                    <>
                        <ButtonNew
                            variant="primary"
                            size="md"
                            onClick={() => saveDashboardConfigs(portfolioConfigs)}
                        >
                            <Stack gap={2}>
                                <TfiSave className="cursor-pointer w-4 h-4" />
                                <span>Save</span>
                            </Stack>
                        </ButtonNew>
                    </>
                )}
                <ButtonNew variant="primary" size="md" onClick={addChart}>
                    +Add new chart
                </ButtonNew>
            </Stack>
        </Stack>

    )


}

const Filters = ({ filterCategories, removeFilterName, addFilterName, data, setFilteredData, setConfig }) => {

    const [showID, setShowID] = useState(undefined)

    const [anchorEl, setAnchorEl] = useState(null);
    const [showFiltersModal, setShowFiltersModal] = useState(false)

    const filtersDefaultVisibility = LocalStorageService.getItem(LocalStorageService.PORTFOLIO_FILTERS_VISIBILITY)

    const [filtersInfoIsVisible, setFiltersInfoIsVisible] = useState(filtersDefaultVisibility)
    const [activeFilterCategoryName, setActiveFilterCategoryName] = useState(filterCategories?.[0]?.categoryName)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (!data?.length) return

        let partiallyFilteredData = [...data]
        filterCategories.forEach(filterCategory => {
            filterCategory?.filters
                ?.filter(filter => filterCategory.selectedFiltersNames?.includes(filter?.filterName))
                ?.forEach(filter => {
                    const { filterType, mainDataKey, ...args } = filter
                    if (!mainDataKey) return
                    const filterFactory = new DynamicFilterFactory(filterType, args)
                    const filterInstance = filterFactory.createFilterClassInstance()
                    partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey)
                })
        })

        setFilteredData(partiallyFilteredData)
    }, [data, filterCategories])

    return (
        <>
            <div className="relative pl-4 ">

                {/* Filters Info  */}
                <Stack className={`py-4 items-start ${!filtersInfoIsVisible && 'w-0 overflow-hidden h-5'}`}
                >
                    <ButtonNew
                        variant="secondary"
                        size="md"
                        onClick={() => setShowFiltersModal(true)}
                        className='mr-8'
                    >
                        <Stack gap={2}>
                            <Icon size={'md'} iconName={'Filter'} />
                            <span>Filters</span>
                        </Stack>
                    </ButtonNew>

                    <Stack className="flex-wrap items-start gap-4">
                        {filterCategories?.map((filterCategory, index) => {
                            return filterCategory?.selectedFiltersNames?.map(name => {
                                const filter = filterCategory?.filters?.find(filter => filter?.filterName === name)
                                let { filterType, ...args } = filter
                                args = {
                                    ...args, props: {
                                        ...args.props,
                                        onContextMenu: (e) => {
                                            e.preventDefault();
                                            setActiveFilterCategoryName(filterCategory.categoryName)
                                            setShowFiltersModal(true)
                                        },
                                    }
                                }
                                const filterFactory = new DynamicFilterFactory(filterType, args)
                                const filterInstance = filterFactory.createFilterClassInstance()
                                if (filterInstance) {
                                    const FilterComponent = filterInstance.createComponent()
                                    return (
                                        <Stack className="gap-1 w-80">
                                            <Icon size={'md'} iconName={'Remove'} color='var(--clr-mystic-red-500)'
                                                onClick={() => {
                                                    removeFilterName(name)
                                                }}
                                            />
                                            {FilterComponent}
                                        </Stack>
                                    )


                                }
                            }


                            )
                        }
                        )}

                    </Stack >


                </Stack>

                {/* Filters fold/expand icon */}
                <Stack className={`w-4 min-h-[2.4rem] absolute z-100 bg-blue-50 top-1 bottom-1 left-0 -ml-4 
          items-center justify-center t-numbers-m hover:bg-blue-200 cursor-pointer 
          `}
                    onClick={() => {
                        setFiltersInfoIsVisible(filtersInfoIsVisible => !filtersInfoIsVisible)
                        LocalStorageService.setItem(LocalStorageService.PORTFOLIO_FILTERS_VISIBILITY, !filtersInfoIsVisible)
                    }}
                >
                    <Icon size={'sm'} iconName={'ChevronRight'} className={`${filtersInfoIsVisible && 'rotate-180'} `} />
                </Stack>
            </div>

            {/* Filters Modal */}
            <CustomModal
                modalOpen={showFiltersModal}
                setModalOpen={setShowFiltersModal}
                width='60rem'
                height='90%'
                top={24}
                right={24}
                left='unset'
                style={{ transform: 'none' }}
            >
                <h3 className="t-heading-l">Filter Catergories</h3>
                <Stack className="py-4 flex-col gap-2 items-center w-full">
                    <Grid className="w-full" container spacing={4}>
                        {filterCategories?.map((filterCategory, index) => {
                            const isActive = filterCategory.categoryName == activeFilterCategoryName
                            return <Grid item xs={4}>
                                <Chip
                                    isOn={isActive}
                                    onClick={() => setActiveFilterCategoryName(filterCategory.categoryName)}
                                >
                                    <span>{filterCategory.categoryName}</span>
                                </Chip>
                            </Grid>

                        })}

                    </Grid>

                    <Divider className='mt-4' />
                    <Stack className="mt-8 w-full flex-col items-center">

                        {filterCategories?.map((filterCategory, index) => {
                            if (filterCategory.categoryName === activeFilterCategoryName)
                                return (
                                    <Stack className="w-full px-3 gap-2" flexDirection={'column'}>
                                        <ButtonNew
                                            onClick={(e) => {
                                                setShowID(index)
                                                handleClick(e)
                                            }}
                                            variant="secondary"
                                            size="sm"
                                            className="mt-1  mb-4"
                                            id={"basic-button" + index}
                                            aria-controls={(showID === index && open) ? ('basic-menu' + index) : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={(showID === index && open) ? 'true' : undefined}
                                        >
                                            + Add filter
                                        </ButtonNew>
                                        <Menu
                                            id={"basic-menu" + index}
                                            anchorEl={anchorEl}
                                            open={showID === index && open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button' + index,
                                                disablePadding: true
                                            }}
                                            sx={{
                                                '.MuiMenu-paper': {
                                                    padding: '8px 0'
                                                }
                                            }}
                                        >
                                            {filterCategory?.filters?.map(f => {
                                                if (filterCategory.selectedFiltersNames?.includes(f?.filterName)) return
                                                return (
                                                    <MenuItem className=""
                                                        onClick={() => {
                                                            handleClose()
                                                            setShowID(undefined)
                                                            setConfig(f?.filterName, undefined)
                                                            addFilterName(f?.filterName)
                                                        }
                                                        }>
                                                        <Stack className="w-full justify-between gap-8 t-body-l">
                                                            <span>
                                                                {f?.filterName}
                                                            </span>
                                                            <span>+</span>
                                                        </Stack>
                                                    </MenuItem>

                                                )
                                            })}
                                        </Menu>
                                        {
                                            filterCategory?.selectedFiltersNames?.map(name => {
                                                const filter = filterCategory?.filters?.find(filter => filter?.filterName === name)
                                                let { filterType, ...args } = filter
                                                if (filterType === FilterTypes.NUMERIC_RANGE_PICKER)
                                                    args = { ...args, props: { ...args.props, changeByTyping: true } }
                                                const filterFactory = new DynamicFilterFactory(filterType, args)
                                                const filterInstance = filterFactory.createFilterClassInstance()
                                                if (filterInstance) {
                                                    const FilterComponent = filterInstance.createComponent()
                                                    return (
                                                        <Stack className="gap-1 w-full">
                                                            <Icon size={'md'} iconName={'Remove'} color='var(--clr-mystic-red-500)'
                                                                onClick={() => {
                                                                    removeFilterName(name)
                                                                }}
                                                            />
                                                            {FilterComponent}
                                                        </Stack>
                                                    )


                                                }
                                            })
                                        }

                                    </Stack>

                                )
                        }
                        )}
                    </Stack>

                </Stack >
            </CustomModal>

        </>

    )
}

const adjustConfigs = (configs) => {
    return JSON.stringify(configs)
}


export const DynamicDashboardMultiTab = ({
    filteredData,
    setFilteredData,
    generalDashboardData,
    isLoading,
    savedPortfolioAndColorsConfigs,
    defaultPortfolioConfigsAndColorsConfigs,
    filtersCategories,
    categoricalColumns,
    numericalColumns,
    defaultColors,
    getColorEntity,
    allAvailableFilters,

    dynamicChartsRowHeight,
    allSettings,
    allFilters,
    chartTypes,
    useGetSpecificData,
    getDataTransformatorMemoDependencyArray,
    dataTransformator,
    getColumnSortFunction,
    getUnit,
    getHoveredItemRelatedItems,
    isItemHovered,
    hoverEffect = true,

    setTriggerFlag,
    renderMiniMapObjectClickModal,
    IdColumnName,
    nameForLocalStorage,
    getColumnDisplayName
}) => {

    const [userSavedConfigs, setUserSavedConfigs] = useState()
    useEffect(() => {
        setUserSavedConfigs(savedPortfolioAndColorsConfigs)
    }, [savedPortfolioAndColorsConfigs])

    // const userColors = useMemo(() => savedPortfolioAndColorsConfigs?.colors, [savedPortfolioAndColorsConfigs?.colors])

    const getAllDataEntities = ({ data, categoricalColumns, numericalColumns }) => {
        const entities = {}
        entities[colorCategoriesEnums.COLUMNS] = {}
        numericalColumns?.forEach((col) => { entities[colorCategoriesEnums.COLUMNS][col] = undefined })

        entities[colorCategoriesEnums.VALUES] = {}
        categoricalColumns?.forEach((col) => {
            const columnUniqueValues = data?.map(row => row[col])?.filter(distinctFilter)
            if (columnUniqueValues?.filter(e => e !== undefined && e !== null)?.length > 0) {
                entities[colorCategoriesEnums.VALUES][col] = {}
                columnUniqueValues?.forEach(val => { entities[colorCategoriesEnums.VALUES][col][val] = undefined })
            }
        })
        return entities
    }

    const mergeColorConfigs = (configs) => {
        const mergedConfigs = {}

        mergedConfigs[colorCategoriesEnums.COLUMNS] = configs?.reduce((total, current) => {
            return { ...total, ...current?.[colorCategoriesEnums.COLUMNS] }
        }, {})

        mergedConfigs[colorCategoriesEnums.VALUES] = configs?.reduce((total, current) => {
            const merged = {}
            const totalKeys = Object.keys(total)
            totalKeys?.forEach(key => {
                merged[key] = { ...total[key], ...(current?.[colorCategoriesEnums.VALUES]?.[key] || {}) }
            })
            return { ...current?.[colorCategoriesEnums.VALUES], ...merged }
        }, {})

        mergedConfigs[colorCategoriesEnums.MIXED] = configs?.reduce((total, current) => {
            const merged = {}
            const totalKeys = Object.keys(total)
            totalKeys?.forEach(key => {
                merged[key] = { ...total[key], ...(current?.[colorCategoriesEnums.MIXED]?.[key] || {}) }
            })
            return { ...current?.[colorCategoriesEnums.MIXED], ...merged }
        }, {})

        return mergedConfigs
    }

    const allDataEntities = useMemo(() => {
        return getAllDataEntities({ data: generalDashboardData, categoricalColumns, numericalColumns })
    }, [generalDashboardData])

    const [colorsConfigs, setColorsConfigs] = useState({})
    const [portfolioConfigs, setPortfolioConfigs] = useState(savedPortfolioAndColorsConfigs?.tabs)

    useEffect(() => {
        const mergedColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, savedPortfolioAndColorsConfigs?.colors, colorsConfigs])
        setColorsConfigs(mergedColorConfigs)
    }, [generalDashboardData, savedPortfolioAndColorsConfigs?.colors])

    const saveDashboardConfigs = () => {
        // localStorage.setItem("portfolioConfigs", JSON.stringify(portfolioConfigs));
        localStorage.setItem(nameForLocalStorage, JSON.stringify({ colors: colorsConfigs, tabs: portfolioConfigs }));
        setUserSavedConfigs({ colors: colorsConfigs, tabs: portfolioConfigs })
    }

    const saveDashboardConfigsWithGA = () => withGAEventTracking(eventNames.DYNAMIC_DASHBOARD_SAVE, {}, saveDashboardConfigs)

    const onResetDashboardConfigs = () => {
        setUserSavedConfigs({ colors: defaultPortfolioConfigsAndColorsConfigs?.colors, tabs: defaultPortfolioConfigsAndColorsConfigs?.tabs })
        setPortfolioConfigs(defaultPortfolioConfigsAndColorsConfigs?.tabs)

        const mergedDefaultColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, defaultPortfolioConfigsAndColorsConfigs?.colors])
        setColorsConfigs(mergedDefaultColorConfigs)
        localStorage.removeItem(nameForLocalStorage);
    };

    const onResetDashboardConfigsWithGA = () => withGAEventTracking(eventNames.DYNAMIC_DASHBOARD_RESET, {}, onResetDashboardConfigs)


    const mergedColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, userSavedConfigs?.colors])
    const dashboardConfigsHasChanged = !deepEqual(
        adjustConfigs({ colors: mergedColorConfigs, tabs: userSavedConfigs?.tabs }),
        adjustConfigs({ colors: colorsConfigs, tabs: portfolioConfigs }))

    const mergedDefaultColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, defaultPortfolioConfigsAndColorsConfigs?.colors])
    const defaultDashboardConfigsHasChanged = !deepEqual(
        adjustConfigs({ colors: mergedDefaultColorConfigs, tabs: defaultPortfolioConfigsAndColorsConfigs?.tabs }),
        adjustConfigs({ colors: colorsConfigs, tabs: portfolioConfigs }))


    const tabs = portfolioConfigs?.map(tabConfigs => tabConfigs.tab)

    const [currentTabIndex, setCurrentTabIndex] = useState(0)

    const tabConfigs = portfolioConfigs?.[currentTabIndex]
    useEffect(() => {
        const tabConfigs = portfolioConfigs?.[currentTabIndex]
        if (!tabConfigs && portfolioConfigs?.length)
            setCurrentTabIndex(0)
    }, [portfolioConfigs])

    const allChartsConfigs = tabConfigs?.settings
    const setAllChartsConfigs = (tabAllChartsConfigs) => setPortfolioConfigs(portfoliosConfigs => {
        const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
            if (i !== currentTabIndex) return tabConfigs
            return { ...tabConfigs, settings: tabAllChartsConfigs }
        })
        return newPortfoliosConfigs
    })

    const allFiltersConfig = tabConfigs?.filters
    const setAllFiltersConfig = (tabAllFiltersConfigs) => setPortfolioConfigs(portfoliosConfigs => {
        const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
            if (i !== currentTabIndex) return tabConfigs
            return { ...tabConfigs, filters: tabAllFiltersConfigs }
        })
        return newPortfoliosConfigs
    })

    const selectedFiltersNames = useMemo(() => filtersCategories.map(category => {
        return { ...category, selectedFiltersNames: tabConfigs?.selectedFiltersNames?.filter(name => category.filtersNames.includes(name)) }
    }), [portfolioConfigs, currentTabIndex])

    const addFilterName = (name) => {
        setPortfolioConfigs(portfoliosConfigs => {
            const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
                if (i !== currentTabIndex) return tabConfigs
                const newFilterNames = tabConfigs.selectedFiltersNames
                newFilterNames.push(name)
                return { ...tabConfigs, selectedFiltersNames: newFilterNames }
            })
            return newPortfoliosConfigs
        })
    }

    const removeFilterName = (name) => {
        setPortfolioConfigs(portfoliosConfigs => {
            const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
                if (i !== currentTabIndex) return tabConfigs
                const newFilterNames = tabConfigs.selectedFiltersNames?.filter(filterName => filterName !== name)
                return { ...tabConfigs, selectedFiltersNames: newFilterNames }
            })
            return newPortfoliosConfigs
        })
    }

    const updateTabName = (newTabName, index) => setPortfolioConfigs(portfoliosConfigs => {
        const updatedPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
            if (i !== index) return tabConfigs
            return { ...tabConfigs, tab: newTabName }
        })
        return updatedPortfoliosConfigs
    })

    const addTab = () => {
        setCurrentTabIndex(currentTabIndex => portfolioConfigs?.length)
        setPortfolioConfigs(portfolioConfigs => {
            return [...portfolioConfigs, {
                filters: {},
                settings: [],
                selectedFiltersNames: [], tab: `New Tab`
            }]

        })
    }

    const deleteTab = (index) => {
        setPortfolioConfigs(portfolioConfigs => {
            return portfolioConfigs?.filter((_tabConfigs, i) => index !== i)
        })
        setCurrentTabIndex(currentTabIndex => index === 0 ? 0 : index - 1)

    }
    const scrollRef = useRef()

    const addChart = () => {
        setPortfolioConfigs(portfoliosConfigs => {
            const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
            const maxY = allChartsConfigs?.length
                ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
                : -1;
            const maxId = allChartsConfigs?.length
                ? Math.max(...allChartsConfigs?.map((l) => l.id))
                : -1;
            const newChart = {
                ...defaultChart,
                y: maxY + 1,
                id: maxId + 1,
            };

            const newallChartsConfigs = [...allChartsConfigs, newChart]
            const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
                if (i !== currentTabIndex) return tabConfigs
                return { ...tabConfigs, settings: newallChartsConfigs }
            })
            return newPortfoliosConfigs
        })

        scrollRef.current?.scrollIntoView({
            behavior: "smooth",
            block: 'center'
        });
    };

    const removeChart = (id) => {
        setPortfolioConfigs(portfoliosConfigs => {
            const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
            const newallChartsConfigs = allChartsConfigs.filter((l) => l.id !== id)
            const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
                if (i !== currentTabIndex) return tabConfigs
                return { ...tabConfigs, settings: newallChartsConfigs }
            })
            return newPortfoliosConfigs
        })
    };

    const updateChart = (id, key, value) => {
        setPortfolioConfigs(portfoliosConfigs => {
            const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
            const newallChartsConfigs = allChartsConfigs.map((l) => {
                if (l.id !== id) return l;
                else return { ...l, [key]: value };
            })
            const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
                if (i !== currentTabIndex) return tabConfigs
                return { ...tabConfigs, settings: newallChartsConfigs }
            })
            return newPortfoliosConfigs
        })
    };

    const [openColorsModal, setOpenColorsModal] = useState(false)
    const [selectedColorEntity, setSelectedColorEntity] = useState()

    const [openColorPicker, setOpenColorPicker] = useState(false)


    useEffect(() => {
        setPortfolioConfigs(savedPortfolioAndColorsConfigs?.tabs)
    }, [savedPortfolioAndColorsConfigs?.tabs])


    const setConfig = (filterName, value) => {
        setAllFiltersConfig({ ...allFiltersConfig, [filterName]: value })
    }
    const allAvailableFiltersMemoized = useMemo(() => allAvailableFilters({ allFiltersConfig, setConfig })
        , [generalDashboardData, portfolioConfigs?.[currentTabIndex]])

    const selectedFilters = useMemo(() => {
        return selectedFiltersNames.map(filterCategory => {
            const filters = filterCategory?.filtersNames?.map(name => allAvailableFiltersMemoized?.find(filter => name === filter?.filterName))
            return { ...filterCategory, filters }
        })
    }, [selectedFiltersNames, portfolioConfigs?.[currentTabIndex], generalDashboardData, currentTabIndex])

    if (allChartsConfigs)
        return (
            <LoadingOrEmptyWrapper showLoading={isLoading} height="400px">
                <article
                    className="bg-white mt-8 px-4 pb-4 rounded-xl overflow-hidden"
                >
                    <Header
                        portfolioConfigs={portfolioConfigs}
                        updateChart={updateChart}
                        defaultDashboardConfigsHasChanged={defaultDashboardConfigsHasChanged}
                        dashboardConfigsHasChanged={dashboardConfigsHasChanged}
                        onResetDashboardConfigs={onResetDashboardConfigsWithGA}
                        saveDashboardConfigs={saveDashboardConfigsWithGA}
                        addChart={addChart}
                        tabs={tabs}
                        addTab={addTab}
                        deleteTab={deleteTab}
                        updateTabName={updateTabName}
                        currentTabIndex={currentTabIndex}
                        setCurrentTabIndex={setCurrentTabIndex}
                        onOpenColors={() => {
                            setSelectedColorEntity(undefined)
                            setOpenColorsModal(openColorsModal => !openColorsModal)
                        }}
                    />
                    {openColorsModal &&
                        <Colors
                            modalOpen={openColorsModal}
                            setModalOpen={setOpenColorsModal}
                            selectedColorEntity={selectedColorEntity}
                            colorsConfigs={colorsConfigs}
                            setColorsConfigs={setColorsConfigs}
                            getColumnDisplayName={getColumnDisplayName}
                        />
                    }
                    {openColorPicker &&
                        <ColorPickerModal
                            modalOpen={openColorPicker}
                            setModalOpen={setOpenColorPicker}
                            selectedColorEntity={selectedColorEntity}
                            colorsConfigs={colorsConfigs}
                            setColorsConfigs={setColorsConfigs}
                            getColumnDisplayName={getColumnDisplayName}
                        />
                    }
                    <DynamicDashboardTab
                        key={currentTabIndex + portfolioConfigs}
                        filteredData={filteredData}
                        setFilteredData={setFilteredData}
                        generalDashboardData={generalDashboardData}
                        allChartsConfigs={allChartsConfigs}
                        setAllChartsConfigs={setAllChartsConfigs}
                        updateChart={updateChart}
                        removeChart={removeChart}
                        removeFilterName={removeFilterName}
                        addFilterName={addFilterName}
                        scrollRef={scrollRef}
                        colorsConfigs={colorsConfigs}
                        getColorEntity={getColorEntity}
                        setOpenColorPicker={setOpenColorPicker}
                        setSelectedColorEntity={setSelectedColorEntity}
                        hasFilterSection={allAvailableFiltersMemoized?.length > 0}
                        setConfig={setConfig}
                        selectedFilters={selectedFilters}

                        dynamicChartsRowHeight={dynamicChartsRowHeight}
                        allSettings={allSettings}
                        allFilters={allFilters}
                        chartTypes={chartTypes}
                        useGetSpecificData={useGetSpecificData}
                        getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
                        dataTransformator={dataTransformator}
                        getUnit={getUnit}
                        getHoveredItemRelatedItems={getHoveredItemRelatedItems}
                        isItemHovered={isItemHovered}
                        hoverEffect={hoverEffect}
                        setTriggerFlag={setTriggerFlag}

                        renderMiniMapObjectClickModal={renderMiniMapObjectClickModal}
                        IdColumnName={IdColumnName}
                        getColumnDisplayName={getColumnDisplayName}
                        getColumnSortFunction={getColumnSortFunction}

                    />
                </article>
            </LoadingOrEmptyWrapper >
        );
};


const getColorEntityOrder = (a, b, aColor, bColor) => {
    if (aColor && !bColor) return -1
    else if (!aColor && bColor) return 1
    else if (a < b) return -1
    else return 1
}

export const Colors = ({ modalOpen, setModalOpen, colorsConfigs, setColorsConfigs, getColumnDisplayName }) => {
    const [updatedColorsConfigs, setUpdatedColorsConfigs] = useState(colorsConfigs)

    const colorCategories = Object.entries(updatedColorsConfigs)?.map(([categoryName, entitiesColors]) => {
        return { categoryName, entities: Object.keys(entitiesColors) }
    })
    const [activeCategoryName, setActiveCategoryName] = useState(Object.keys(updatedColorsConfigs)?.[0])

    let activeCategorySubcategoriesNames = Object.keys(updatedColorsConfigs?.[activeCategoryName])
    activeCategorySubcategoriesNames.sort((a, b) => {
        const aEntities = Object.keys(colorsConfigs?.[activeCategoryName]?.[a] || {})
        const bEntities = Object.keys(colorsConfigs?.[activeCategoryName]?.[b] || {})

        const aColoredEntities = Object.values(colorsConfigs?.[activeCategoryName]?.[a] || {}).filter(e => e)
        const bColoredEntities = Object.values(colorsConfigs?.[activeCategoryName]?.[b] || {}).filter(e => e)

        if (aColoredEntities?.length === bColoredEntities?.length)
            return aEntities?.length - bEntities?.length
        else return bColoredEntities?.length - aColoredEntities?.length
    })

    let activeCategoryEntities = colorCategories?.find(category => category.categoryName === activeCategoryName)?.entities

    activeCategoryEntities.sort((a, b) => {
        const aColor = colorsConfigs?.[activeCategoryName]?.[a]
        const bColor = colorsConfigs?.[activeCategoryName]?.[b]
        return getColorEntityOrder(a, b, aColor, bColor)
    }
    )

    useEffect(() => {
        setUpdatedColorsConfigs(colorsConfigs)
    }, [colorsConfigs])

    return (
        <CustomModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            width='50rem'
            height='90%'
            top={24}
            right={24}
            left='unset'
            style={{ transform: 'none' }}
        >
            <h3 className="t-heading-l">Color Catergories</h3>
            <Stack className="pt-4 flex-col  h-full gap-2 items-center justify-between w-full">
                <Grid className="w-full" container spacing={4}>
                    {colorCategories?.map((colorCategory, index) => {
                        const isActive = colorCategory.categoryName == activeCategoryName
                        return <Grid item xs={4}>
                            <Chip
                                isOn={isActive}
                                onClick={() => setActiveCategoryName(colorCategory.categoryName)}
                            >
                                <span>{colorCategory.categoryName}</span>
                            </Chip>
                        </Grid>

                    })}

                </Grid>

                <Divider className='' />
                <div className="w-full  flex-grow  overflow-y-scroll ">
                    <Stack className=" w-full  flex-col items-center gap-2 pr-2">
                        {activeCategoryName === colorCategoriesEnums.COLUMNS && activeCategoryEntities?.map((entityName, index) => {
                            return (
                                <>
                                    <ColorPickerItem
                                        entityName={getColumnDisplayName({ colName: entityName })}
                                        color={updatedColorsConfigs?.[activeCategoryName]?.[entityName]}
                                        setColor={(value) => {
                                            setUpdatedColorsConfigs({
                                                ...updatedColorsConfigs, [activeCategoryName]: {
                                                    ...updatedColorsConfigs?.[activeCategoryName],
                                                    [entityName]: value
                                                }
                                            })
                                        }}
                                    />
                                    <Divider />
                                </>
                            )
                        }
                        )}
                        {[colorCategoriesEnums.VALUES, colorCategoriesEnums.MIXED].includes(activeCategoryName)
                            && activeCategorySubcategoriesNames?.map((subcategoryName, index) => {
                                let entities = Object.keys(updatedColorsConfigs[activeCategoryName][subcategoryName])
                                entities.sort((a, b) => {
                                    const aColor = colorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[a]
                                    const bColor = colorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[b]
                                    return getColorEntityOrder(a, b, aColor, bColor)
                                })

                                const noOfColoredEntities = entities?.filter(e => updatedColorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[e])?.length
                                const noOfDefindedEntities = entities.filter(e => e)?.length
                                if (noOfDefindedEntities > 0)
                                    return (
                                        <Accordion className="w-full boder-t-0" sx={{
                                            padding: 0,
                                        }}>
                                            <AccordionSummary
                                                expandIcon={<Icon iconName={'ChevronDown'} />}
                                                className="t-heading-s"
                                            >
                                                <Stack flexDirection={'column'} alignItems={'flex-start'} >
                                                    <h4>
                                                        {getColumnDisplayName({ colName: subcategoryName })}
                                                    </h4>
                                                    <span className="t-numbers-xxs">{`${noOfColoredEntities ?? 0} color${noOfColoredEntities > 1 ? 's' : ''} set`}</span>
                                                </Stack>
                                            </AccordionSummary>
                                            <AccordionDetails className="">
                                                <Stack className="gap-2 flex-col pl-4">
                                                    {entities?.map(entityName => {
                                                        return (
                                                            <>
                                                                <ColorPickerItem
                                                                    entityName={getColumnDisplayName({ colName: entityName })}
                                                                    color={updatedColorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[entityName]}
                                                                    setColor={(value) => {
                                                                        setUpdatedColorsConfigs({
                                                                            ...updatedColorsConfigs, [activeCategoryName]: {
                                                                                ...updatedColorsConfigs?.[activeCategoryName],
                                                                                [subcategoryName]: {
                                                                                    ...updatedColorsConfigs?.[activeCategoryName][subcategoryName],
                                                                                    [entityName]: value
                                                                                }
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <Divider />
                                                            </>

                                                        )
                                                    })}
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                            }
                            )}
                    </Stack>
                </div>
                <div className="h-10">
                    <ButtonNew variant={'primary'} size={'md'} className={'w-20'}
                        onClick={() => {
                            setColorsConfigs(updatedColorsConfigs)
                            setModalOpen(false)
                        }
                        }
                    >Apply</ButtonNew>

                </div>

            </Stack >
        </CustomModal >

    )
}


const ColorPickerItem = ({ className, colorInputClassName, entityName, color, setColor }) => {
    const defaultColor = '#000000'
    const ref = useRef()
    return (
        <Stack className={`justify-between w-full  ${className} relative`}>
            <span className="t-heading-s">{entityName}</span>
            {(color) ?
                <ColorInput color={color} setColor={setColor} className={`w-24 border-0 rounded ${colorInputClassName}`} />
                : (
                    <ButtonNew onClick={() => {
                        ref.current.click()
                        setColor(defaultColor)
                    }} variant={'primary'} size={'sm'}>
                        Choose color
                    </ButtonNew>
                )}

            <ColorInput
                className="w-0 h-0 right-0 absolute opacity-0"
                inputRef={ref} color={color} setColor={setColor} />

        </Stack>
    )
}

export const ColorPickerModal = ({ modalOpen, setModalOpen, selectedColorEntity, colorsConfigs, setColorsConfigs, getColumnDisplayName }) => {
    const { t } = useTranslation()

    const categoryName = selectedColorEntity?.categoryName
    const subcategoryName = selectedColorEntity?.subcategoryName
    const entityName = selectedColorEntity?.entityName

    let currentColor
    if (categoryName === colorCategoriesEnums.COLUMNS)
        currentColor = colorsConfigs?.[categoryName]?.[entityName]
    else {
        currentColor = colorsConfigs?.[categoryName]?.[subcategoryName]?.[entityName]
    }
    const [newColor, setNewColor] = useState(currentColor)

    useEffect(() => {
        setNewColor(currentColor)
    }, [currentColor])

    return (
        <CustomModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            width='55rem'
            height='35rem'
            top={'50%'}
            right={'50%'}
        >
            <h3 className="t-heading-l">Pick a color</h3>
            <Stack className="py-4  flex-col gap-2 items-center justify-between w-full h-full">
                <Breadcrumbs
                    separator={<Icon iconName="ChevronRight" size="sm" />}
                    aria-label="breadcrumb"
                >
                    {[getColumnDisplayName({ colName: categoryName }),
                    getColumnDisplayName({ colName: subcategoryName }),
                    getColumnDisplayName({ colName: entityName })].filter(e => e).map(e => <span>{e}</span>)}
                </Breadcrumbs>

                <ColorPickerItem
                    colorInputClassName={'w-8 h-8 '}
                    className={'!w-auto'}
                    color={newColor}
                    setColor={setNewColor}
                />
                <Stack gap={theme.spacing(8)}>
                    <ButtonNew variant={'secondary'} className={'w-25 capitalize'} size={'md'}
                        onClick={() => setModalOpen(false)}
                    >{t('general.cancel')}
                    </ButtonNew>
                    <ButtonNew variant={'primary'} className={'w-25'} size={'md'}
                        onClick={() => {
                            if (categoryName === colorCategoriesEnums.COLUMNS)
                                setColorsConfigs({
                                    ...colorsConfigs, [categoryName]: {
                                        ...colorsConfigs?.[categoryName],
                                        [entityName]: newColor
                                    }
                                })
                            else
                                setColorsConfigs({
                                    ...colorsConfigs, [categoryName]: {
                                        ...colorsConfigs?.[categoryName],
                                        [subcategoryName]: {
                                            ...colorsConfigs?.[categoryName]?.[subcategoryName],
                                            [entityName]: newColor
                                        }
                                    }
                                })
                            setModalOpen(false)
                        }}
                    >{t('general.apply')}</ButtonNew>
                </Stack>


            </Stack >
        </CustomModal>

    )
}


export const DynamicDashboardTab = ({
    filteredData,
    setFilteredData,
    generalDashboardData,
    allChartsConfigs,
    setAllChartsConfigs,
    updateChart,
    removeChart,
    removeFilterName,
    addFilterName,
    scrollRef,
    colorsConfigs,
    getColorEntity,
    setOpenColorPicker,
    setSelectedColorEntity,
    setConfig,
    selectedFilters,
    hasFilterSection,

    dynamicChartsRowHeight,
    allSettings,
    allFilters,
    chartTypes,
    useGetSpecificData,
    getDataTransformatorMemoDependencyArray,
    dataTransformator,
    getUnit,
    getHoveredItemRelatedItems,
    isItemHovered,
    hoverEffect = true,
    renderMiniMapObjectClickModal,
    setTriggerFlag,
    IdColumnName,
    getColumnDisplayName,
    getColumnSortFunction
}) => {

    const [clickedAssets, setClickedAssets] = useState([])



    const getColor = useCallback(({ quantity, chartType, diagrams }) => {
        const { categoryName, subcategoryName, entityName } = getColorEntity({ quantity, chartType, diagrams })

        if (categoryName === colorCategoriesEnums.COLUMNS)
            return colorsConfigs?.[categoryName]?.[entityName]
        return colorsConfigs?.[categoryName]?.[subcategoryName]?.[entityName]

    }, [colorsConfigs])

    const onLegendSelect = useCallback(({ params, config }) => {
        const { chartType, diagrams } = config
        const { categoryName, subcategoryName, entityName } = getColorEntity({ quantity: params?.name, chartType, diagrams })
        setSelectedColorEntity({ categoryName, subcategoryName, entityName })
        setOpenColorPicker(true)
    }, [])

    const [hoveredItem, setHoveredItem] = useState()

    const eventHandlers = useCallback((point, config) => {
        const { dataPoint, chartType, partitions, stacks, xDataKey, yDataKey } = config;

        if (chartType === chartTypesEnums.MINI_MAP) {
            return {

                click: (point, viewportPosition) => {
                    setClickedAssets(clickedAssets => {
                        const newClickedAssetId = point?.[IdColumnName]
                        // Remove hardcoded value (id)
                        if (clickedAssets?.map(asset => asset?.id)?.includes(newClickedAssetId))
                            return clickedAssets.filter(asset => asset?.id !== newClickedAssetId)
                        else return [...clickedAssets, { id: newClickedAssetId, assetPosition: viewportPosition }]
                    })
                    setTriggerFlag(triggerFlag => !triggerFlag)
                },
                mouseover: (point) => {
                    setHoveredItem({ point, config })
                },
                mouseout: (point) => {
                    setHoveredItem(undefined)
                }
            }
        }

    }, [])

    const onHover = useCallback(({ params, config, point }) => {
        if (hoverEffect)
            setHoveredItem({ params, config, point })
    }, [])

    const onMouseOut = useCallback(() => {
        if (hoverEffect)
            setHoveredItem()
    }, [])

    const getHoveredItemRelatedItemsMemoized = useMemo(() => {
        return getHoveredItemRelatedItems({ hoveredItem })
    }, [hoveredItem]
    )

    const isItemHoveredCallback = useCallback(({ row, chartType, xAxis, yAxis, diagrams }) => {
        return isItemHovered({
            hoveredItemRelatedItems: getHoveredItemRelatedItemsMemoized
            , hoveredItem, row, chartType, xAxis, yAxis, diagrams
        })

    }, [hoveredItem])


    return (
        <>
            {hasFilterSection &&
                <Filters
                    filterCategories={selectedFilters}
                    setFilteredData={setFilteredData}
                    data={generalDashboardData}
                    // setFilterCategories={setSelectedFiltersNames}
                    setConfig={setConfig}
                    removeFilterName={removeFilterName}
                    addFilterName={addFilterName}
                />
            }

            {renderMiniMapObjectClickModal({ objects: clickedAssets, setObjects: setClickedAssets })}

            <DynamicDashboard
                allChartsConfigs={allChartsConfigs}
                setAllChartsConfigs={setAllChartsConfigs}
                dynamicChartsRowHeight={dynamicChartsRowHeight}
                generalDashboardData={filteredData}
                allFilters={allFilters}
                allSettings={allSettings}
                chartTypes={chartTypes}
                specificDataGetter={useGetSpecificData}
                dataTransformator={dataTransformator}
                getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
                removeChart={removeChart}
                updateChart={updateChart}
                eventHandlers={eventHandlers}
                scrollRef={scrollRef}
                getUnit={getUnit}
                getColor={getColor}
                onLegendSelect={onLegendSelect}
                onHover={onHover}
                onMouseOut={onMouseOut}
                isItemHovered={isItemHoveredCallback}
                somethingIsHovered={!!hoveredItem}
                getColumnDisplayName={getColumnDisplayName}
                getColumnSortFunction={getColumnSortFunction}
                hoverEffect={hoverEffect}
            />
        </>
    );
};
