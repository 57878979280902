import { getColumnDisplayName, settingsOptions } from "../utils/dynamicDashboard";
import { chartTypesEnums, valuesEnums } from "./dynamicDashboard";


export const getEnergyDashboardColumnDisplayName = ({ colName }) => {
    return getColumnDisplayName({ colName, dataMapping })

}

export const FiltersNames = Object.freeze({
    SELECTED_SENSORS: 'selectedUtilities',
    SELECTED_SCENARIOS: 'selectedScenarios',
    TIMEFRAME: 'timeframe',
    SELECTED_DAYS_OF_WEEK: 'selectedDaysOfWeek',
    SELECTED_HOURS_OF_DAY: 'selectedHoursOfDay',
    DATE_RANGE: 'dateRange',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    DIAGRAMS: 'diagrams',
    X_AXIS: 'xAxis',
    Y_AXIS: 'yAxis',
    DATAPOINT: 'dataPoint',
    SUB_DATAPOINT: 'subDataPoint',
    TITLE: 'title',
    SORT_VALUE: 'sortValue',
    LEGEND_POSITION: 'legendsPosition',
    INVERT_X_AXIS: 'invertXAxis',
    INVERT_Y_AXIS: 'invertYAxis',
    AGGREGATE_X_AXIS: 'aggregateXAxis',

    DIAGRAMS_COLOR: 'color',
    DIAGRAMS_DIRECTION: 'direction',
    DIAGRAMS_STACKS: 'stacks',
    DIAGRAMS_PARTITIONS: 'partitions',
    DIAGRAMS_DIAGRAM_TYPE: 'diagramType',
    DIAGRAMS_DIAGRAM_DATA_KEY: 'dataKey',
    DIAGRAMS_SCENARIO: valuesEnums.DIAGRAM_FILTER_PREFIX + 'scenario',
    SHOW_PIE_LABELS: 'showPieLabels',
    IS_WORKING_DAY: 'isWorkingDay',
    IS_WORKING_HOUR: 'isWorkingHour',
    PER_M2: 'perM2'
})

const relevantConfigOptions = {
    [chartTypesEnums.AREA]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.SUB_DATAPOINT,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.HEIGHT_100,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        settingsOptions.SORT_BY,
        settingsOptions.PER_M2
    ],
    [chartTypesEnums.BAR]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.SUB_DATAPOINT,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        settingsOptions.SORT_BY,
        settingsOptions.PER_M2
    ],
    [chartTypesEnums.PIE]: [
        settingsOptions.TITLE,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DATA_POINT,
        settingsOptions.SUB_DATAPOINT,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.SHOW_PIE_LABELS,
        settingsOptions.PER_M2
    ],
    [chartTypesEnums.DOUGHNUT]: [
        settingsOptions.TITLE,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DATA_POINT,
        settingsOptions.SUB_DATAPOINT,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.SHOW_PIE_LABELS,
        settingsOptions.PER_M2

    ],
    [chartTypesEnums.LINE]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.SUB_DATAPOINT,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.SORT_BY,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        settingsOptions.PER_M2,
    ],
    [chartTypesEnums.COMPOSED]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.X,
        settingsOptions.QUANTITY,
        settingsOptions.DIAGRAM_TYPE,
        settingsOptions.SORT_BY,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        settingsOptions.AGGREGATE_X_AXIS,
        FiltersNames.DIAGRAMS_SCENARIO,
        settingsOptions.PER_M2

    ],
    [chartTypesEnums.KPI]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.SUB_DATAPOINT,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.PER_M2
    ],
    [chartTypesEnums.HISTOGRAM]: [
        settingsOptions.TITLE,
        settingsOptions.QUANTITY,
        settingsOptions.SORT_BY,
        settingsOptions.COLOR,
        settingsOptions.DATA_POINT,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        FiltersNames.DIAGRAMS_SCENARIO,
        settingsOptions.PER_M2

    ],
    [chartTypesEnums.HEATMAP]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.SUB_DATAPOINT,
        settingsOptions.X,
        settingsOptions.Y,
        settingsOptions.COLOR,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
        settingsOptions.PER_M2
    ],
};


export const isRelevantOption = (chartType, option, index) => {
    if (
        index > 0 &&
        chartType !== chartTypesEnums.HISTOGRAM &&
        chartType !== chartTypesEnums.COMPOSED
    )
        return false;
    return relevantConfigOptions[chartType]?.includes(option);
};


export const columnsDisplayNamesEnums = Object.freeze({
    COOLING_DEMAND: 'Cooling Demand',
    DATE_TIME: 'Datetime',
    ELECTRICITY_DEMAND: 'Electricity Demand',
    HEATING_DEMAND: 'Heating Demand',
    HOT_WATER_DEMAND: 'Hot Water Demand',
    LOAD_CONDUCTION_AVG: 'Load Conduction',
    LOAD_LUMINANCE_INTERNAL_AVG: 'Load Luminance',
    LOAD_PEOPLE_INTERNAL_AVG: 'Load People',
    LOAD_SOLAR_RADIATION_AVG: 'Load Solar Radiation',
    LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG: 'Load Technical Appliances',
    LOAD_VENTILATION_AVG: 'Load Ventilation',
    LOAD_WALLSTORAGE_AVG: 'Load Wall Storage',
    OCCUPANCY_PERCENTAGE_AVG: 'Occupancy',
    TEMPERATURE_INSIDE_CALCULATED_AVG: 'Temperature Inside Calculated',
    TEMPERATURE_INSIDE_MAXIMUM_AVG: 'Temperature Inside Max',
    TEMPERATURE_INSIDE_MEASURED_AVG: 'Temperature Inside Measured',
    TEMPERATURE_INSIDE_MINIMUM_AVG: 'Temperature Inside Min',
    TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG: 'Temperature Operative Calculated',
    TEMPERATURE_OUTSIDE_AIR_AVG: 'Temperature Outside Air',
    VENTILATION_INFILTRATION_FLOW_RATE: 'Ventilation Infiltration Flow Rate',
    VENTILATION_INFILTRATION_TEMPERATURE_AVG: 'Ventilation Infiltration Temperature',
    VENTILATION_MECHANICAL_FLOW_RATE: 'Ventilation Mechanical Flow Rate',
    VENTILATION_MECHANICAL_TEMPERATURE_AVG: 'Ventilation Mechanical Temperature',
    VENTILATION_NATURAL_FLOW_RATE: 'Ventilation Natural Flow Rate',
    VENTILATION_NATURAL_TEMPERATURE_AVG: 'Ventilation Natural Temperature',

    //  <<<<<<<<< New Columns
    COOLING_DEMAND_AUXILIARY_SYSTEM_AVG: "Cooling Demand Auxiliary",
    COOLING_DEMAND_MAIN_SYSTEM_AVG: "Cooling Demand Main",
    COOLING_DEMAND_VENTILATION_SYSTEM_AVG: "Cooling Demand Ventilation",
    DISTRICT_HEATING_AVG: "District Heating",
    FLOW_RATE_ADDITIONAL_VENTILATION_AVG: "Flow Rate Additional Ventilation",
    FLOW_RATE_INFILTRATION_AVG: "Flow Rate Infiltration",
    FLOW_RATE_MECHANICAL_VENTILATION_AVG: "Flow Rate Mechanical Ventilation",
    FLOW_RATE_NATURAL_VENTILATION_AVG: "Flow Rate Natural Ventilation",
    GRID_ELECTRICITY_AVG: "Grid Electricity",
    HEATING_DEMAND_AUXILIARY_SYSTEM_AVG: "Heating Demand Auxiliary",
    HEATING_DEMAND_MAIN_SYSTEM_AVG: "Heating Demand Main",
    HEATING_DEMAND_VENTILATION_SYSTEM_AVG: "Heating Demand Ventilation",
    HVAC_MODE_AVG: "HVAC Mode",
    ILLUMINATION_ELECTRICITY_DEMAND_AVG: "Illumination Electricity Demand",
    LOADS_ADDITIONAL_VENTILATION_AVG: "Loads Additional Ventilation",
    LOADS_CONDUCTION_FLOOR_AVG: "Loads Conduction Floor",
    LOADS_CONDUCTION_GROUND_AVG: "Loads Conduction Ground",
    LOADS_CONDUCTION_WALLS_AVG: "Loads Conduction Walls",
    LOADS_CONDUCTION_WINDOWS_AVG: "Loads Conduction Windows",
    LOADS_INFILTRATION_AVG: "Loads Infiltration",
    LOADS_MECHANICAL_VENTILATION_AVG: "Loads Mechanical Ventilation",
    LOADS_NATURAL_VENTILATION_AVG: "Loads Natural Ventilation",
    LOADS_OTHER_AVG: "Loads Other",
    NATURAL_GAS_AVG: "Natural Gas",
    OCCUPANCY_PEOPLE_AVG: "Occupancy People",
    OIL_HEATING_AVG: "Oil Heating",
    OTHER_ELECTRICITY_DEMAND_AVG: "Other Electricity Demand",
    PV_ELECTRICITY_AVG: "PV Electricity",
    TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG: "Technical Appliances Electricity Demand",
    TEMPERATURE_AIR_SUPPLY_AVG: "Temperature Air Supply",
    VENTILATION_ELECTRICITY_DEMAND_AVG: "Ventilation Electricity Demand",

    CET_HOUR: 'CET Hour',
    IS_WORKING_DAY: 'Working Day',
    IS_WORKING_HOUR: 'Working Hour',

    //  New Columns >>>>>>>>>



    DATAPOINT: 'Data Point',
    DAY_OF_MONTH: 'Day Of Month',
    DAY_OF_WEEK: 'Day Of Week',
    DAY_OF_YEAR: 'Day Of Year',
    HOUR_OF_DAY: 'Hour Of Day',
    HOUR_OF_YEAR: 'Hour Of Year',
    MONTH: 'Month',
    MONTH_NUMBER: 'Month Number',
    SCENARIO: 'Scenario',
    UTILITY: 'Utility',
    YEAR: 'Year',
    YEAR_MONTH: 'Year Month',

    CONSUMPTION: 'Consumption',
    COUNT: 'Count'

})


export const columnsAPINamesEnums = Object.freeze({
    COOLING_DEMAND: 'coolingDemand_avg',
    ELECTRICITY_DEMAND: 'electricityDemand_avg',
    HEATING_DEMAND: 'heatingDemand_avg',
    HOT_WATER_DEMAND: 'hotWaterDemand_avg',
    LOAD_CONDUCTION_AVG: 'loadsConduction_avg',
    LOAD_LUMINANCE_INTERNAL_AVG: 'loadsLuminanceInternal_avg',
    LOAD_PEOPLE_INTERNAL_AVG: 'loadsPeopleInternal_avg',
    LOAD_SOLAR_RADIATION_AVG: 'loadsSolarRadiation_avg',
    LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG: 'loadsTechnicalAppliancesInternal_avg',
    LOAD_VENTILATION_AVG: 'loadsVentilation_avg',
    LOAD_WALLSTORAGE_AVG: 'loadsWallStorage_avg',
    OCCUPANCY_PERCENTAGE_AVG: 'occupancyPercentage_avg',
    TEMPERATURE_INSIDE_CALCULATED_AVG: 'temperatureInsideCalculated_avg',
    TEMPERATURE_INSIDE_MAXIMUM_AVG: 'temperatureInsideMaximum_avg',
    TEMPERATURE_INSIDE_MEASURED_AVG: 'temperatureInsideMeasured_avg',
    TEMPERATURE_INSIDE_MINIMUM_AVG: 'temperatureInsideMinimum_avg',
    TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG: 'temperatureOperativeInsideCalculated_avg',
    TEMPERATURE_OUTSIDE_AIR_AVG: 'temperatureOutsideAir_avg',
    VENTILATION_INFILTRATION_FLOW_RATE: 'ventilationInfiltrationFlowRate_avg',
    VENTILATION_INFILTRATION_TEMPERATURE_AVG: 'ventilationInfiltrationTemperature_avg',
    VENTILATION_MECHANICAL_FLOW_RATE: 'ventilationMechanicalFlowRate_avg',
    VENTILATION_MECHANICAL_TEMPERATURE_AVG: 'ventilationMechanicalTemperature_avg',
    VENTILATION_NATURAL_FLOW_RATE: 'ventilationNaturalFlowRate_avg',
    VENTILATION_NATURAL_TEMPERATURE_AVG: 'ventilationNaturalTemperature_avg',

    // << NEW ADDED COLUMNS
    COOLING_DEMAND_AUXILIARY_SYSTEM_AVG: "coolingDemandAuxiliarySystem_avg",
    COOLING_DEMAND_MAIN_SYSTEM_AVG: "coolingDemandMainSystem_avg",
    COOLING_DEMAND_VENTILATION_SYSTEM_AVG: "coolingDemandVentilationSystem_avg",
    DISTRICT_HEATING_AVG: "districtHeating_avg",
    FLOW_RATE_ADDITIONAL_VENTILATION_AVG: "flowRateAdditionalVentilation_avg",
    FLOW_RATE_INFILTRATION_AVG: "flowRateInfiltration_avg",
    FLOW_RATE_MECHANICAL_VENTILATION_AVG: "flowRateMechanicalVentilation_avg",
    FLOW_RATE_NATURAL_VENTILATION_AVG: "flowRateNaturalVentilation_avg",
    GRID_ELECTRICITY_AVG: "gridElectricity_avg",
    HEATING_DEMAND_AUXILIARY_SYSTEM_AVG: "heatingDemandAuxiliarySystem_avg",
    HEATING_DEMAND_MAIN_SYSTEM_AVG: "heatingDemandMainSystem_avg",
    HEATING_DEMAND_VENTILATION_SYSTEM_AVG: "heatingDemandVentilationSystem_avg",
    HVAC_MODE_AVG: "hvacMode_avg",
    ILLUMINATION_ELECTRICITY_DEMAND_AVG: "illuminationElectricityDemand_avg",
    LOADS_ADDITIONAL_VENTILATION_AVG: "loadsAdditionalVentilation_avg",
    LOADS_CONDUCTION_FLOOR_AVG: "loadsConductionFloor_avg",
    LOADS_CONDUCTION_GROUND_AVG: "loadsConductionGround_avg",
    LOADS_CONDUCTION_WALLS_AVG: "loadsConductionWalls_avg",
    LOADS_CONDUCTION_WINDOWS_AVG: "loadsConductionWindows_avg",
    LOADS_INFILTRATION_AVG: "loadsInfiltration_avg",
    LOADS_MECHANICAL_VENTILATION_AVG: "loadsMechanicalVentilation_avg",
    LOADS_NATURAL_VENTILATION_AVG: "loadsNaturalVentilation_avg",
    LOADS_OTHER_AVG: "loadsOther_avg",
    NATURAL_GAS_AVG: "naturalGas_avg",
    OCCUPANCY_PEOPLE_AVG: "occupancyPeople_avg",
    OIL_HEATING_AVG: "oilHeating_avg",
    OTHER_ELECTRICITY_DEMAND_AVG: "otherElectricityDemand_avg",
    PV_ELECTRICITY_AVG: "pvElectricity_avg",
    TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG: "technicalAppliancesElectricityDemand_avg",
    TEMPERATURE_AIR_SUPPLY_AVG: "temperatureAirSupply_avg",
    VENTILATION_ELECTRICITY_DEMAND_AVG: "ventilationElectricityDemand_avg",

    CET_HOUR: 'cetHour',
    IS_WORKING_DAY: 'isWorkingDay',
    IS_WORKING_HOUR: 'isWorkingHour',
    // NEW ADDED COLUMNS >>

    DATE_TIME: 'datetime',
    DATAPOINT: 'dataPoint',
    DAY_OF_MONTH: 'dayOfMonth',
    DAY_OF_WEEK: 'dayOfWeek',
    DAY_OF_YEAR: 'dayOfYear',
    HOUR_OF_DAY: 'hourOfDay',
    HOUR_OF_YEAR: 'hourOfYear',
    MONTH: 'month',
    MONTH_NUMBER: 'monthNumber',
    SCENARIO: 'scenario',
    YEAR: 'year',
    YEAR_MONTH: 'yearMonth',
    UTILITY: 'utility',
    COUNT: 'Count',

    // Not really a dedicated column
    CONSUMPTION: 'consumption'
})


export const dataMapping = Object.freeze({
    [columnsAPINamesEnums.COOLING_DEMAND]: columnsDisplayNamesEnums.COOLING_DEMAND,
    [columnsAPINamesEnums.ELECTRICITY_DEMAND]: columnsDisplayNamesEnums.ELECTRICITY_DEMAND,
    [columnsAPINamesEnums.HEATING_DEMAND]: columnsDisplayNamesEnums.HEATING_DEMAND,
    [columnsAPINamesEnums.HOT_WATER_DEMAND]: columnsDisplayNamesEnums.HOT_WATER_DEMAND,
    [columnsAPINamesEnums.LOAD_CONDUCTION_AVG]: columnsDisplayNamesEnums.LOAD_CONDUCTION_AVG,
    [columnsAPINamesEnums.LOAD_LUMINANCE_INTERNAL_AVG]: columnsDisplayNamesEnums.LOAD_LUMINANCE_INTERNAL_AVG,
    [columnsAPINamesEnums.LOAD_PEOPLE_INTERNAL_AVG]: columnsDisplayNamesEnums.LOAD_PEOPLE_INTERNAL_AVG,
    [columnsAPINamesEnums.LOAD_SOLAR_RADIATION_AVG]: columnsDisplayNamesEnums.LOAD_SOLAR_RADIATION_AVG,
    [columnsAPINamesEnums.LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG]: columnsDisplayNamesEnums.LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG,
    [columnsAPINamesEnums.LOAD_VENTILATION_AVG]: columnsDisplayNamesEnums.LOAD_VENTILATION_AVG,
    [columnsAPINamesEnums.LOAD_WALLSTORAGE_AVG]: columnsDisplayNamesEnums.LOAD_WALLSTORAGE_AVG,
    [columnsAPINamesEnums.OCCUPANCY_PERCENTAGE_AVG]: columnsDisplayNamesEnums.OCCUPANCY_PERCENTAGE_AVG,
    [columnsAPINamesEnums.TEMPERATURE_INSIDE_CALCULATED_AVG]: columnsDisplayNamesEnums.TEMPERATURE_INSIDE_CALCULATED_AVG,
    [columnsAPINamesEnums.TEMPERATURE_INSIDE_MAXIMUM_AVG]: columnsDisplayNamesEnums.TEMPERATURE_INSIDE_MAXIMUM_AVG,
    [columnsAPINamesEnums.TEMPERATURE_INSIDE_MEASURED_AVG]: columnsDisplayNamesEnums.TEMPERATURE_INSIDE_MEASURED_AVG,
    [columnsAPINamesEnums.TEMPERATURE_INSIDE_MINIMUM_AVG]: columnsDisplayNamesEnums.TEMPERATURE_INSIDE_MINIMUM_AVG,
    [columnsAPINamesEnums.TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG]: columnsDisplayNamesEnums.TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG,
    [columnsAPINamesEnums.TEMPERATURE_OUTSIDE_AIR_AVG]: columnsDisplayNamesEnums.TEMPERATURE_OUTSIDE_AIR_AVG,
    [columnsAPINamesEnums.VENTILATION_INFILTRATION_FLOW_RATE]: columnsDisplayNamesEnums.VENTILATION_INFILTRATION_FLOW_RATE,
    [columnsAPINamesEnums.VENTILATION_INFILTRATION_TEMPERATURE_AVG]: columnsDisplayNamesEnums.VENTILATION_INFILTRATION_TEMPERATURE_AVG,
    [columnsAPINamesEnums.VENTILATION_MECHANICAL_FLOW_RATE]: columnsDisplayNamesEnums.VENTILATION_MECHANICAL_FLOW_RATE,
    [columnsAPINamesEnums.VENTILATION_MECHANICAL_TEMPERATURE_AVG]: columnsDisplayNamesEnums.VENTILATION_MECHANICAL_TEMPERATURE_AVG,
    [columnsAPINamesEnums.VENTILATION_NATURAL_FLOW_RATE]: columnsDisplayNamesEnums.VENTILATION_NATURAL_FLOW_RATE,
    [columnsAPINamesEnums.VENTILATION_NATURAL_TEMPERATURE_AVG]: columnsDisplayNamesEnums.VENTILATION_NATURAL_TEMPERATURE_AVG,
    [columnsAPINamesEnums.DATE_TIME]: columnsDisplayNamesEnums.DATE_TIME,
    [columnsAPINamesEnums.DATAPOINT]: columnsDisplayNamesEnums.DATAPOINT,
    [columnsAPINamesEnums.DAY_OF_MONTH]: columnsDisplayNamesEnums.DAY_OF_MONTH,
    [columnsAPINamesEnums.DAY_OF_WEEK]: columnsDisplayNamesEnums.DAY_OF_WEEK,
    [columnsAPINamesEnums.DAY_OF_YEAR]: columnsDisplayNamesEnums.DAY_OF_YEAR,
    [columnsAPINamesEnums.HOUR_OF_DAY]: columnsDisplayNamesEnums.HOUR_OF_DAY,
    [columnsAPINamesEnums.HOUR_OF_YEAR]: columnsDisplayNamesEnums.HOUR_OF_YEAR,
    [columnsAPINamesEnums.MONTH]: columnsDisplayNamesEnums.MONTH,
    [columnsAPINamesEnums.MONTH_NUMBER]: columnsDisplayNamesEnums.MONTH_NUMBER,
    [columnsAPINamesEnums.SCENARIO]: columnsDisplayNamesEnums.SCENARIO,
    [columnsAPINamesEnums.YEAR]: columnsDisplayNamesEnums.YEAR,
    [columnsAPINamesEnums.YEAR_MONTH]: columnsDisplayNamesEnums.YEAR_MONTH,
    [columnsAPINamesEnums.UTILITY]: columnsDisplayNamesEnums.UTILITY,
    [columnsAPINamesEnums.CONSUMPTION]: columnsDisplayNamesEnums.CONSUMPTION,
    [columnsAPINamesEnums.COUNT]: columnsDisplayNamesEnums.COUNT,

    // NEW COLUMNS
    [columnsAPINamesEnums.COOLING_DEMAND_AUXILIARY_SYSTEM_AVG]: columnsDisplayNamesEnums.COOLING_DEMAND_AUXILIARY_SYSTEM_AVG,
    [columnsAPINamesEnums.COOLING_DEMAND_MAIN_SYSTEM_AVG]: columnsDisplayNamesEnums.COOLING_DEMAND_MAIN_SYSTEM_AVG,
    [columnsAPINamesEnums.COOLING_DEMAND_VENTILATION_SYSTEM_AVG]: columnsDisplayNamesEnums.COOLING_DEMAND_VENTILATION_SYSTEM_AVG,
    [columnsAPINamesEnums.DISTRICT_HEATING_AVG]: columnsDisplayNamesEnums.DISTRICT_HEATING_AVG,
    [columnsAPINamesEnums.FLOW_RATE_ADDITIONAL_VENTILATION_AVG]: columnsDisplayNamesEnums.FLOW_RATE_ADDITIONAL_VENTILATION_AVG,
    [columnsAPINamesEnums.FLOW_RATE_INFILTRATION_AVG]: columnsDisplayNamesEnums.FLOW_RATE_INFILTRATION_AVG,
    [columnsAPINamesEnums.FLOW_RATE_MECHANICAL_VENTILATION_AVG]: columnsDisplayNamesEnums.FLOW_RATE_MECHANICAL_VENTILATION_AVG,
    [columnsAPINamesEnums.FLOW_RATE_NATURAL_VENTILATION_AVG]: columnsDisplayNamesEnums.FLOW_RATE_NATURAL_VENTILATION_AVG,
    [columnsAPINamesEnums.GRID_ELECTRICITY_AVG]: columnsDisplayNamesEnums.GRID_ELECTRICITY_AVG,
    [columnsAPINamesEnums.HEATING_DEMAND_AUXILIARY_SYSTEM_AVG]: columnsDisplayNamesEnums.HEATING_DEMAND_AUXILIARY_SYSTEM_AVG,
    [columnsAPINamesEnums.HEATING_DEMAND_MAIN_SYSTEM_AVG]: columnsDisplayNamesEnums.HEATING_DEMAND_MAIN_SYSTEM_AVG,
    [columnsAPINamesEnums.HEATING_DEMAND_VENTILATION_SYSTEM_AVG]: columnsDisplayNamesEnums.HEATING_DEMAND_VENTILATION_SYSTEM_AVG,
    [columnsAPINamesEnums.HVAC_MODE_AVG]: columnsDisplayNamesEnums.HVAC_MODE_AVG,
    [columnsAPINamesEnums.ILLUMINATION_ELECTRICITY_DEMAND_AVG]: columnsDisplayNamesEnums.ILLUMINATION_ELECTRICITY_DEMAND_AVG,
    [columnsAPINamesEnums.LOADS_ADDITIONAL_VENTILATION_AVG]: columnsDisplayNamesEnums.LOADS_ADDITIONAL_VENTILATION_AVG,
    [columnsAPINamesEnums.LOADS_CONDUCTION_FLOOR_AVG]: columnsDisplayNamesEnums.LOADS_CONDUCTION_FLOOR_AVG,
    [columnsAPINamesEnums.LOADS_CONDUCTION_GROUND_AVG]: columnsDisplayNamesEnums.LOADS_CONDUCTION_GROUND_AVG,
    [columnsAPINamesEnums.LOADS_CONDUCTION_WALLS_AVG]: columnsDisplayNamesEnums.LOADS_CONDUCTION_WALLS_AVG,
    [columnsAPINamesEnums.LOADS_CONDUCTION_WINDOWS_AVG]: columnsDisplayNamesEnums.LOADS_CONDUCTION_WINDOWS_AVG,
    [columnsAPINamesEnums.LOADS_INFILTRATION_AVG]: columnsDisplayNamesEnums.LOADS_INFILTRATION_AVG,
    [columnsAPINamesEnums.LOADS_MECHANICAL_VENTILATION_AVG]: columnsDisplayNamesEnums.LOADS_MECHANICAL_VENTILATION_AVG,
    [columnsAPINamesEnums.LOADS_NATURAL_VENTILATION_AVG]: columnsDisplayNamesEnums.LOADS_NATURAL_VENTILATION_AVG,
    [columnsAPINamesEnums.LOADS_OTHER_AVG]: columnsDisplayNamesEnums.LOADS_OTHER_AVG,
    [columnsAPINamesEnums.NATURAL_GAS_AVG]: columnsDisplayNamesEnums.NATURAL_GAS_AVG,
    [columnsAPINamesEnums.OCCUPANCY_PEOPLE_AVG]: columnsDisplayNamesEnums.OCCUPANCY_PEOPLE_AVG,
    [columnsAPINamesEnums.OIL_HEATING_AVG]: columnsDisplayNamesEnums.OIL_HEATING_AVG,
    [columnsAPINamesEnums.OTHER_ELECTRICITY_DEMAND_AVG]: columnsDisplayNamesEnums.OTHER_ELECTRICITY_DEMAND_AVG,
    [columnsAPINamesEnums.PV_ELECTRICITY_AVG]: columnsDisplayNamesEnums.PV_ELECTRICITY_AVG,
    [columnsAPINamesEnums.TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG]: columnsDisplayNamesEnums.TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG,
    [columnsAPINamesEnums.TEMPERATURE_AIR_SUPPLY_AVG]: columnsDisplayNamesEnums.TEMPERATURE_AIR_SUPPLY_AVG,
    [columnsAPINamesEnums.VENTILATION_ELECTRICITY_DEMAND_AVG]: columnsDisplayNamesEnums.VENTILATION_ELECTRICITY_DEMAND_AVG,

    [columnsAPINamesEnums.CET_HOUR]: columnsDisplayNamesEnums.CET_HOUR,
    [columnsAPINamesEnums.IS_WORKING_DAY]: columnsDisplayNamesEnums.IS_WORKING_DAY,
    [columnsAPINamesEnums.IS_WORKING_HOUR]: columnsDisplayNamesEnums.IS_WORKING_HOUR,
})


export const categoricalColumns = [
    columnsAPINamesEnums.DATE_TIME,
    columnsAPINamesEnums.DAY_OF_WEEK,
    columnsAPINamesEnums.MONTH,
    columnsAPINamesEnums.YEAR_MONTH,
    columnsAPINamesEnums.SCENARIO,
    columnsAPINamesEnums.UTILITY,

    columnsAPINamesEnums.IS_WORKING_DAY,
    columnsAPINamesEnums.IS_WORKING_HOUR,
]

export const categoricalColumnsDisplayNames = categoricalColumns.map(colName => getEnergyDashboardColumnDisplayName({ colName }))

export const staticUtilities = [
    columnsAPINamesEnums.COOLING_DEMAND,
    columnsAPINamesEnums.ELECTRICITY_DEMAND,
    columnsAPINamesEnums.HEATING_DEMAND,
    columnsAPINamesEnums.HOT_WATER_DEMAND,
    columnsAPINamesEnums.LOAD_CONDUCTION_AVG,
    columnsAPINamesEnums.LOAD_LUMINANCE_INTERNAL_AVG,
    columnsAPINamesEnums.LOAD_PEOPLE_INTERNAL_AVG,
    columnsAPINamesEnums.LOAD_SOLAR_RADIATION_AVG,
    columnsAPINamesEnums.LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG,
    columnsAPINamesEnums.LOAD_VENTILATION_AVG,
    columnsAPINamesEnums.LOAD_WALLSTORAGE_AVG,
    columnsAPINamesEnums.OCCUPANCY_PERCENTAGE_AVG,
    columnsAPINamesEnums.TEMPERATURE_INSIDE_CALCULATED_AVG,
    columnsAPINamesEnums.TEMPERATURE_INSIDE_MAXIMUM_AVG,
    columnsAPINamesEnums.TEMPERATURE_INSIDE_MEASURED_AVG,
    columnsAPINamesEnums.TEMPERATURE_INSIDE_MINIMUM_AVG,
    columnsAPINamesEnums.TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG,
    columnsAPINamesEnums.TEMPERATURE_OUTSIDE_AIR_AVG,
    columnsAPINamesEnums.VENTILATION_INFILTRATION_FLOW_RATE,
    columnsAPINamesEnums.VENTILATION_INFILTRATION_TEMPERATURE_AVG,
    columnsAPINamesEnums.VENTILATION_MECHANICAL_FLOW_RATE,
    columnsAPINamesEnums.VENTILATION_MECHANICAL_TEMPERATURE_AVG,
    columnsAPINamesEnums.VENTILATION_NATURAL_FLOW_RATE,
    columnsAPINamesEnums.VENTILATION_NATURAL_TEMPERATURE_AVG,

    // New Columns 
    columnsAPINamesEnums.COOLING_DEMAND_AUXILIARY_SYSTEM_AVG,
    columnsAPINamesEnums.COOLING_DEMAND_MAIN_SYSTEM_AVG,
    columnsAPINamesEnums.COOLING_DEMAND_VENTILATION_SYSTEM_AVG,
    columnsAPINamesEnums.DISTRICT_HEATING_AVG,
    columnsAPINamesEnums.FLOW_RATE_ADDITIONAL_VENTILATION_AVG,
    columnsAPINamesEnums.FLOW_RATE_INFILTRATION_AVG,
    columnsAPINamesEnums.FLOW_RATE_MECHANICAL_VENTILATION_AVG,
    columnsAPINamesEnums.FLOW_RATE_NATURAL_VENTILATION_AVG,
    columnsAPINamesEnums.GRID_ELECTRICITY_AVG,
    columnsAPINamesEnums.HEATING_DEMAND_AUXILIARY_SYSTEM_AVG,
    columnsAPINamesEnums.HEATING_DEMAND_MAIN_SYSTEM_AVG,
    columnsAPINamesEnums.HEATING_DEMAND_VENTILATION_SYSTEM_AVG,
    columnsAPINamesEnums.HVAC_MODE_AVG,
    columnsAPINamesEnums.ILLUMINATION_ELECTRICITY_DEMAND_AVG,
    columnsAPINamesEnums.LOADS_ADDITIONAL_VENTILATION_AVG,
    columnsAPINamesEnums.LOADS_CONDUCTION_FLOOR_AVG,
    columnsAPINamesEnums.LOADS_CONDUCTION_GROUND_AVG,
    columnsAPINamesEnums.LOADS_CONDUCTION_WALLS_AVG,
    columnsAPINamesEnums.LOADS_CONDUCTION_WINDOWS_AVG,
    columnsAPINamesEnums.LOADS_INFILTRATION_AVG,
    columnsAPINamesEnums.LOADS_MECHANICAL_VENTILATION_AVG,
    columnsAPINamesEnums.LOADS_NATURAL_VENTILATION_AVG,
    columnsAPINamesEnums.LOADS_OTHER_AVG,
    columnsAPINamesEnums.NATURAL_GAS_AVG,
    columnsAPINamesEnums.OCCUPANCY_PEOPLE_AVG,
    columnsAPINamesEnums.OIL_HEATING_AVG,
    columnsAPINamesEnums.OTHER_ELECTRICITY_DEMAND_AVG,
    columnsAPINamesEnums.PV_ELECTRICITY_AVG,
    columnsAPINamesEnums.TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG,
    columnsAPINamesEnums.TEMPERATURE_AIR_SUPPLY_AVG,
    columnsAPINamesEnums.VENTILATION_ELECTRICITY_DEMAND_AVG,

]

export const columnsUnits = {
    [columnsAPINamesEnums.DATE_TIME]: 'Datetime',
    [columnsAPINamesEnums.DAY_OF_MONTH]: '#Day',
    [columnsAPINamesEnums.DAY_OF_WEEK]: '',
    [columnsAPINamesEnums.DAY_OF_YEAR]: '#Day',
    [columnsAPINamesEnums.HOUR_OF_DAY]: '#Hour',
    [columnsAPINamesEnums.HOUR_OF_YEAR]: '#Hour',
    [columnsAPINamesEnums.MONTH]: '',
    [columnsAPINamesEnums.MONTH_NUMBER]: '#Month',
    [columnsAPINamesEnums.YEAR]: 'year',
    [columnsAPINamesEnums.YEAR_MONTH]: '',
    [columnsAPINamesEnums.SCENARIO]: '',
    [columnsAPINamesEnums.UTILITY]: '',
    [columnsAPINamesEnums.CONSUMPTION]: 'kWh/m²/a',
    [columnsAPINamesEnums.COUNT]: '#',

    // [columnsAPINamesEnums.COOLING_DEMAND]:'',
    // [columnsAPINamesEnums.ELECTRICITY_DEMAND]:'',
    // [columnsAPINamesEnums.HEATING_DEMAND]:'',
    // [columnsAPINamesEnums.HOT_WATER_DEMAND]:'',
    // [columnsAPINamesEnums.LOAD_CONDUCTION_AVG]:'kW',
    // [columnsAPINamesEnums.LOAD_LUMINANCE_INTERNAL_AVG]:'kW',
    // [columnsAPINamesEnums.LOAD_PEOPLE_INTERNAL_AVG]:'kW',
    // [columnsAPINamesEnums.LOAD_SOLAR_RADIATION_AVG]:'kW',
    // [columnsAPINamesEnums.LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG]:'kW',
    // [columnsAPINamesEnums.LOAD_VENTILATION_AVG]:'kW',
    // [columnsAPINamesEnums.LOAD_WALLSTORAGE_AVG]:'kW',
    // [columnsAPINamesEnums.OCCUPANCY_PERCENTAGE_AVG]:'%',
    // [columnsAPINamesEnums.TEMPERATURE_INSIDE_CALCULATED_AVG]:'°C',
    // [columnsAPINamesEnums.TEMPERATURE_INSIDE_MAXIMUM_AVG]:'°C',
    // [columnsAPINamesEnums.TEMPERATURE_INSIDE_MEASURED_AVG]:'°C',
    // [columnsAPINamesEnums.TEMPERATURE_INSIDE_MINIMUM_AVG]:'°C',
    // [columnsAPINamesEnums.TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG]:'°C',
    // [columnsAPINamesEnums.TEMPERATURE_OUTSIDE_AIR_AVG]:'°C',
    // [columnsAPINamesEnums.VENTILATION_INFILTRATION_FLOW_RATE]:'m³',
    // [columnsAPINamesEnums.VENTILATION_INFILTRATION_TEMPERATURE_AVG]:'m³',
    // [columnsAPINamesEnums.VENTILATION_MECHANICAL_FLOW_RATE]:'m³',
    // [columnsAPINamesEnums.VENTILATION_MECHANICAL_TEMPERATURE_AVG]:'m³',
    // [columnsAPINamesEnums.VENTILATION_NATURAL_FLOW_RATE]:'m³',
    // [columnsAPINamesEnums.VENTILATION_NATURAL_TEMPERATURE_AVG]:'m³',
    // [columnsAPINamesEnums.COOLING_DEMAND_AUXILIARY_SYSTEM_AVG]:'',
    // [columnsAPINamesEnums.COOLING_DEMAND_MAIN_SYSTEM_AVG]:'',
    // [columnsAPINamesEnums.COOLING_DEMAND_VENTILATION_SYSTEM_AVG]:'',
    // [columnsAPINamesEnums.DISTRICT_HEATING_AVG]:'',
    // [columnsAPINamesEnums.FLOW_RATE_ADDITIONAL_VENTILATION_AVG]:'m³',
    // [columnsAPINamesEnums.FLOW_RATE_INFILTRATION_AVG]:'m³',
    // [columnsAPINamesEnums.FLOW_RATE_MECHANICAL_VENTILATION_AVG]:'m³',
    // [columnsAPINamesEnums.FLOW_RATE_NATURAL_VENTILATION_AVG]:'m³',
    // [columnsAPINamesEnums.GRID_ELECTRICITY_AVG]:'',
    // [columnsAPINamesEnums.HEATING_DEMAND_AUXILIARY_SYSTEM_AVG]:'',
    // [columnsAPINamesEnums.HEATING_DEMAND_MAIN_SYSTEM_AVG]:'',
    // [columnsAPINamesEnums.HEATING_DEMAND_VENTILATION_SYSTEM_AVG]:'',
    // [columnsAPINamesEnums.HVAC_MODE_AVG]:'',
    // [columnsAPINamesEnums.ILLUMINATION_ELECTRICITY_DEMAND_AVG]:'',
    // [columnsAPINamesEnums.LOADS_ADDITIONAL_VENTILATION_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_CONDUCTION_FLOOR_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_CONDUCTION_GROUND_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_CONDUCTION_WALLS_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_CONDUCTION_WINDOWS_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_INFILTRATION_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_MECHANICAL_VENTILATION_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_NATURAL_VENTILATION_AVG]:'kW',
    // [columnsAPINamesEnums.LOADS_OTHER_AVG]:'kW',
    // [columnsAPINamesEnums.NATURAL_GAS_AVG]:'',
    // [columnsAPINamesEnums.OCCUPANCY_PEOPLE_AVG]:'',
    // [columnsAPINamesEnums.OIL_HEATING_AVG]:'',
    // [columnsAPINamesEnums.OTHER_ELECTRICITY_DEMAND_AVG]:'',
    // [columnsAPINamesEnums.PV_ELECTRICITY_AVG]:'',
    // [columnsAPINamesEnums.TECHNICAL_APPLIANCES_ELECTRICITY_DEMAND_AVG]:'',
    // [columnsAPINamesEnums.TEMPERATURE_AIR_SUPPLY_AVG]:'°C',
    // [columnsAPINamesEnums.VENTILATION_ELECTRICITY_DEMAND_AVG]:'',

}
