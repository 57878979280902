const url = process.env.REACT_APP_API_URL + "/api";
export default {
  goals: {
    list: (projectId) => `${url}/modernization_goals/?project_id=${projectId}`,
  },
  scenarios: {
    list: (projectId) => `${url}/modernization/?project_id=${projectId}`,
  },
  scenarioFiles: {
    download: (projectId) =>
      `${url}/modernization/get_whole_zip_files_of_scenario/?project_id=${projectId}`,
  },
  form: {
    post: `${url}/customized_modernization/`,
  },
  retrofits: {
    list: (projectId) => `${url}/retrofit/?project_id=${projectId}`,
    get: (id) => `${url}/retrofit/${id}/`,
    post: `${url}/retrofit/`,
  },
  crrem: {
    list: ({ projectId, degree, parameter }) =>
      `${url}/crrem_data/?parameter=${parameter}&project_id=${projectId}&degree=${degree}`,
  },
  selectedRetrofit: {
    list: (projectId) => `${url}/chosen_retrofit/?project_id=${projectId}`,
    get: (id) => `${url}/chosen_retrofit/${id}/`,
    post: `${url}/chosen_retrofit/`,
  },
};
