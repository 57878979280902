import { Stack } from "@mui/material";
import React from "react";
import LegendItem from "./LegendItem";

const BulletChartLegend = ({ legends, hasZones = false, className }) => {
  return (
    <Stack spacing={4} className={className}>
      {legends?.map((legend, index) => {
        if (index < 3 || hasZones)
          return (
            <LegendItem
              key={index}
              bulletType={legend.bulletType}
              bulletWidth="0.8rem"
              bulletHeight={
                legend.bulletType === "circle" ? "0.8rem" : "0.4rem"
              }
              color={legend.color}
              label={legend.legendLabel}
            />
          );
      })}
    </Stack>
  );
};

export default BulletChartLegend;
