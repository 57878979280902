import { Paper, Stack } from "@mui/material";
import React from "react";

const imagesSrc = [
  require("../../Asset/Images/modernization/energy block.png"),
  require("../../Asset/Images/modernization/co2 block.png"),
  require("../../Asset/Images/modernization/certificate block.png"),
  require("../../Asset/Images/modernization/money.png"),
];

export const SingleGoal = ({ value, type, index }) => {
  if (index > 3) return;
  return (
    <Paper className="p-0 ">
      <Stack spacing={4} className="justify-between h-24 py-0 pr-4 pl-6 ">
        {type === "goal" && (
          <>
            <p className=" t-body-xl sentence-case">{value}</p>
            <img src={imagesSrc[index]} alt="goal image" />
          </>
        )}
        {type === "budget" && (
          <>
            <p className=" t-body-xl sentence-case">€ {value} budget</p>
            <img src={imagesSrc[3]} alt="coins image" />
          </>
        )}
      </Stack>
    </Paper>
  );
};
