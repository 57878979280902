import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { getUnitBasedValue } from "../../functions/EnergyPerformance";
import { withDashboardChartsPrecalculator } from "./DashboardChartsPrecalculator";
import { sum } from "../../utils/dataManipulation";
import { RadioButton } from "../RadioButton";
const monthOrder = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DashboardBarChart = ({ props }) => {
  const {
    className,
    resourcesFirst,
    data,
    resourcesSecond,
    width,
    height,
    xAxisDataKey,
    tickFormatter,
    unit,
    buildingArea,
    timeframe,
    hourlysortType,
    setHourlysortType,
  } = props;
  const dataCopy = [...(data || [])];

  if (timeframe === "monthly") {
    dataCopy?.sort((a, b) => {
      const [monthA, yearA] = a?.aggregated_time?.split(" ");
      const [monthB, yearB] = b?.aggregated_time?.split(" ");
      if (yearA !== yearB) return yearA - yearB;
      const indexA = monthOrder.indexOf(monthA);
      const indexB = monthOrder.indexOf(monthB);
      return indexA - indexB;
    });
  }
  if (timeframe === "daily") {
    dataCopy?.sort((a, b) => {
      return new Date(a?.aggregated_time) - new Date(b?.aggregated_time);
    });
  }

  if (timeframe === "hourly" && hourlysortType === "value") {
    dataCopy?.sort((a, b) => {
      const aValues = Object.values({ ...a, aggregated_time: 0 });
      const bValues = Object.values({ ...b, aggregated_time: 0 });
      return sum(bValues) - sum(aValues);
    });
  }

  return (
    <div className={`stack-barchart relative ${className}`}>
      {timeframe === "hourly" && (
        <div className="absolute right-0 top-1 z-50 ">
          <RadioButton
            value={hourlysortType}
            setValue={setHourlysortType}
            options={["time", "value"]}
            label="Sort by"
            displayOptions={["Time", "Value"]}
            row={true}
          />
        </div>
      )}
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          id="my-chart-svg"
          data={dataCopy}
          margin={{ top: 30, left: 0, right: 0, bottom: 0 }}
          barGap={0}
          barCategoryGap={"20%"}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="var(--clr-gray-50)" />
          <XAxis
            tickFormatter={tickFormatter}
            dataKey={xAxisDataKey}
            stroke="var(--clr-gray-900)"
            angle={0}
            tickLine={false}
            tickMargin={5}
            style={{
              fontFamily: "Exo 2",
              fontSize: "1.2rem",
              fontWeight: 400,
              lineHeight: "1.4rem",
              letterSpacing: 0,
            }}
          />

          <YAxis
            className="t-heading-xxl"
            style={{
              fontFamily: "Exo 2",
              fontSize: "1.2rem",
              fontWeight: 400,
              lineHeight: "1.4rem",
              letterSpacing: 0,
            }}
            stroke="var(--clr-gray-900)"
            axisLine={true}
            tickLine={false}
            width={60}
            tickMargin={0}
            tickFormatter={(y) => getUnitBasedValue(y, unit, buildingArea)}
          >
            <Label
              position="top"
              offset={15}
              style={{
                textAnchor: "start",
                fontSize: "130%",
                fill: "black",
              }}
              angle={0}
              value={unit}
            />
          </YAxis>
          {resourcesFirst &&
            resourcesFirst?.map((resources, index) => (
              <Bar
                key={"firstCategory-" + index}
                dataKey={resources.dataKey}
                stackId={0}
                fill={resources.color}
              />
            ))}
          {resourcesSecond &&
            resourcesSecond?.map((resources, index) => (
              <Bar
                key={"secondCategory-" + index}
                dataKey={resources.dataKey}
                stackId={1}
                fill={resources.color}
              />
            ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default withDashboardChartsPrecalculator(DashboardBarChart);
