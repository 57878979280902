import { createSlice } from "@reduxjs/toolkit";
import i18next from "i18next";

const defaultUnitsState = { consumption: "kWh", price: "€", emission: "KgCO₂" };

const initialState = {
  configs: [],
  dataToDownload: {}
};

const energyPerformanceSliceNew = createSlice({
  name: "energyPerformanceNew",
  initialState,
  reducers: {
    setConfigs: (state, { payload }) => {
      state.configs = payload;
    },
    getConfig: (state, { payload }) => {
      return state.configs.find((config) => config.id === payload.id);
    },
    setDataToDownload: (state, { payload }) => {
      state.dataToDownload = { ...state.dataToDownload, [payload.id]: payload.data }
    }
  },
});

export const { getConfig, setConfigs, setDataToDownload } = energyPerformanceSliceNew.actions;
export default energyPerformanceSliceNew.reducer;
