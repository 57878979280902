import { isInteger } from "lodash";

export const valueOrAlternative = (value, alt = "---") => {
  return value ? value : alt;
};

export const replaceDecimalPointWithCommaOld = (number) => {
  if (!number) return null;
  const str = number.toString();
  return str.replace(".", ",");
};

export const replaceDecimalPointWithComma = (number) => {
  if (!number) return "";
  return number;
};

export const formatNumberToLocale = (number, locale, noOfDigigts = 2) => {
  if (number === null || number === undefined || typeof number !== "number")
    return number;
  const isIntegerNumber = isInteger(number)
  const floatConf = { minimumFractionDigits: noOfDigigts, maximumFractionDigits: noOfDigigts }
  return number?.toLocaleString(locale, !isIntegerNumber && floatConf);
};

export const addCommaToNumber = (number, noOfDigigts) => {
  return formatNumberToLocale(number, 'en-US', noOfDigigts);
};

export const formatNumberToGerman = (number, noOfDigigts) => {
  return formatNumberToLocale(number, 'de-DE', noOfDigigts);
}

export const formatNumberBasedOnUser = (number, noOfDigigts) => {
  const userLanguage = localStorage.getItem('language')
  if (userLanguage == 'de')
    return formatNumberToGerman(number, noOfDigigts)
  else return addCommaToNumber(number, noOfDigigts)
}

export const sentenceCase = (string) => {
  if (!string) return null;
  return string == null
    ? null
    : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return null;
  return string == null
    ? null
    : string.charAt(0).toUpperCase() + string.slice(1);
};
