import { Stack } from "@mui/material";
import React from "react";
import LegendBullet from "./LegendBullet";
import { GeneralChartLegend } from "./GeneralChartLegend";

export const HorizontalStackedBarChartLegend = ({
  config,
  legends,
  className,
}) => {
  const formattedLegends = legends?.map(legend => {
    return {
      label: config[legend.label]?.label,
      color: config[legend.label]?.color,
      value: parseFloat(
        (legend.percentage * 100).toFixed(1)
      )
    }
  })
  return <GeneralChartLegend className={className} legends={formattedLegends} />
};
