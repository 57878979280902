import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Portfolio: {
    allConfigs: {

    }
  }
};

const dynamicDashboardSlice = createSlice({
  name: "dynamicDashboard",
  initialState,
  reducers: {
    setSingleConfig: (state, { payload }) => {
      state[payload.appName].allConfigs[payload.id] = payload.config;
    },
  },
});

export const { setSingleConfig } = dynamicDashboardSlice.actions;
export default dynamicDashboardSlice.reducer;
