import { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";
import SearchIcon from "../Asset/Icons/search.svg";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

export function SearchMui(setSearchTerm, className) {
  return (
    <Paper
      component="form"
      className={className}
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <IconButton
        type="button"
        sx={{ p: "10px", "&:hover": { bgcolor: "white" } }}
        aria-label="search"
      >
        <img src={SearchIcon} alt="search icon" />
      </IconButton>
    </Paper>
  );
}

export const Search = ({ placeholder, value, width, onChange }) => {
  const [isEmpty, setIsEmpty] = useState(true);
  const borderColor = isEmpty ? "var(--clr-gray-400)" : "var(--clr-gray-900)";
  const textColor = isEmpty ? "var(--clr-gray-400)" : "var(--clr-gray-900)";
  const labelColor = "var(--clr-gray-600)";
  return (
    <span
      style={{
        display: "block",
        width: width,
      }}
    >
      <CssTextField2
        bordercolor={borderColor}
        textcolor={textColor}
        labelcolor={labelColor}
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img src={SearchIcon} alt="search icon" />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: {
            color: labelColor,
            fontFamily: "Nunito Sans",
            fontSize: "1.4rem",
            fontWeight: 600,
            lineHeight: "1.4rem",
            letterSpacing: "0.025rem",
          },
        }}
        id="filled-basic-password"
        label={placeholder}
        placeholder={placeholder}
        variant="filled"
        inputRef={value}
        fullWidth
        onChange={() => {
          if (value.current === "") setIsEmpty(true);
          else {
            setIsEmpty(value.current.value === "");
          }
          onChange();
        }}
      />
    </span>
  );
};

const CssTextField2 = styled(TextField)(
  ({ bordercolor, textcolor, labelcolor }) => ({
    "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,    input:-webkit-autofill:active": {
      transition: "background-color 5000s ease-in-out 0s",
    },
    "& .MuiInputBase-root.MuiFilledInput-root": {
      height: "4rem",
      backgroundColor: "var(--clr-white)",
      color: textcolor,
      fontFamily: "Nunito Sans",
      fontSize: "1.4rem",
      fontWeight: "400",
      lineHeight: "1.9rem",
      letterSpacing: "0rem",
      border: `1px solid ${bordercolor}`,
      borderRadius: "7px",
      "&:hover": {
        border: "1px solid var(--clr-secondary-blue-200)",
        boxShadow:
          "0px 0.2px 0.6px rgba(36, 89, 234, 0.041), 0px 0.5px 1.8px rgba(36, 89, 234, 0.059), 0px 1.2px 4.2px rgba(36, 89, 234, 0.077), 0px 4px 14px rgba(36, 89, 234, 0.11)",
        ":before": {
          border: "none",
        },
      },
      "&:before, &:after": {
        border: "none",
      },
      "&:after": {
        border: "none",
      },
      "&.Mui-focused": {
        border: "1px solid var(--clr-leaftech-blue-500)",
      },
      "&::placeholder": {
        color: "green",
      },
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused": {
      color: labelcolor,
      fontFamily: "Nunito Sans",
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.4rem",
      letterSpacing: "0.025rem",
    },
    "& .MuiInputBase-input.MuiFilledInput-input": {
      "&:before, &:after": {
        border: "none",
      },
      "&:focus": {
        // border: "3px solid var(--clr-secondary-blue-300)",
      },
    },
  })
);
