export default {
  chart: {
    energyTitle: "Energy rating by floor area",
    carbonTitle: "Certification status by floor area",
    platinum: "DGNB-building in use-Platinum",
    gold: "DGNB-building in use-Gold",
    silver: "DGNB-building in use-silver",
    bronze: "DGNB-building in use-Bronze",
    notCertified: "Not certified",
  },
  cards: {
    energyTitle: "EU EPC rating certificates",
    carbonButtonLong: "Download all certificates",
    carbonTitle: "Certificates",
    energyButtonLong: "Download all EU EPC ratings",
    buttonShort: "Download",
    noCertificate: "No additional certifications are available!",
  },
};
